import { ServerCloudFunctionResult } from '../../generated/graphql-types'

export enum ArchiveLocationResponseCodes {
  noRequiredFields,
  noFound,
  noPermission,
  futureBookings,
  updateError,
  success,
  inArchivedGroup,
}

export interface ArchiveLocationResult extends ServerCloudFunctionResult {
  code: ArchiveLocationResponseCodes
}
