import { gql } from 'graphql-request'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import {
  ServerService,
  ServerServiceEmployeeMutationResponse,
  ServerServiceLocationMutationResponse,
  ServerServicePermissionMutationResponse,
  ServerServiceProductMutationResponse,
} from '../../generated/graphql-types'
import {
  DEACTIVATED_SERVICE_GROUPS_QUERY_KEY,
  SERVICE_GROUPS_QUERY_KEY,
} from '../serviceGroup/queryKeys'
import { EMPLOYEES_QUERY_KEY } from '../employee/queryKeys'
import {
  ALL_SERVICES_QUERY_KEY,
  DEACTIVATED_SERVICES_QUERY_KEY,
  INTERBRANCH_SERVICES_QUERY_KEY,
  SERVICES_QUERY_KEY,
} from './queryKeys'
import { InterbranchServiceInsertInput, ServiceInsertInput, ServiceUpdateDto } from './logic'

export function useCreateService() {
  return useMutation(
    async (serviceInsertInput: ServiceInsertInput): Promise<{ insertService?: { id?: number } }> =>
      request(
        gql`
          mutation ($serviceInsertInput: service_insert_input!) {
            insertService(object: $serviceInsertInput) {
              id
            }
          }
        `,
        { serviceInsertInput },
      ),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([SERVICES_QUERY_KEY])

        // update employee columns in bookings calendar
        if (variables.serviceEmployees?.data.length) {
          queryClient.invalidateQueries([EMPLOYEES_QUERY_KEY])
        }
      },
      onError: reportGqlError,
    },
  )
}

export function useCreateInterbranchService() {
  return useMutation(
    async (
      serviceInsertInput: InterbranchServiceInsertInput,
    ): Promise<{ insertService?: { id?: number } }> =>
      request(
        gql`
          mutation ($serviceInsertInput: service_insert_input!) {
            insertService(object: $serviceInsertInput) {
              id
            }
          }
        `,
        { serviceInsertInput },
      ),
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([INTERBRANCH_SERVICES_QUERY_KEY])
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(ALL_SERVICES_QUERY_KEY),
        })

        // update employee columns in bookings calendar
        if (variables.serviceEmployees?.data.length) {
          queryClient.invalidateQueries([EMPLOYEES_QUERY_KEY])
        }
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateService() {
  return useMutation(
    async (
      dto: ServiceUpdateDto,
    ): Promise<{
      updateServiceById?: ServerService
      deleteServiceEmployees?: ServerServiceEmployeeMutationResponse
      insertServiceEmployees?: ServerServiceEmployeeMutationResponse
      deleteServiceLocations?: ServerServiceLocationMutationResponse
      insertServiceLocations?: ServerServiceLocationMutationResponse
      deleteServicePermissions?: ServerServicePermissionMutationResponse
      insertServicePermissions?: ServerServicePermissionMutationResponse
      deleteServiceProducts?: ServerServiceProductMutationResponse
      insertServiceProducts?: ServerServiceProductMutationResponse
    }> =>
      request(
        gql`
          mutation (
            $id: Int!
            $serviceSetInput: service_set_input!
            $serviceEmployeeInsertInputs: [serviceEmployee_insert_input!]!
            $serviceEmployeeIdsToDelete: [Int!]!
            $serviceLocationInsertInputs: [serviceLocation_insert_input!]!
            $locationIdsToDelete: [Int!]!
            $servicePermissionInsertInputs: [servicePermission_insert_input!]!
            $permissionEmployeeIdsToDelete: [Int!]!
            $serviceProductInsertInputs: [serviceProduct_insert_input!]!
            $consumableIdsToDelete: [Int!]!
          ) {
            updateServiceById(pk_columns: { id: $id }, _set: $serviceSetInput) {
              id
              description
              price
              costPrice
              name
              defaultDuration
              picture
              groupId
              availableToAllBranches
            }
            deleteServiceEmployees(where: { id: { _in: $serviceEmployeeIdsToDelete } }) {
              affected_rows
            }
            insertServiceEmployees(objects: $serviceEmployeeInsertInputs) {
              returning {
                employeeId
                employee {
                  firstName
                  lastName
                }
              }
            }
            deleteServiceLocations(
              where: { serviceId: { _eq: $id }, locationId: { _in: $locationIdsToDelete } }
            ) {
              affected_rows
            }
            insertServiceLocations(objects: $serviceLocationInsertInputs) {
              returning {
                serviceId
                locationId
              }
            }
            deleteServicePermissions(
              where: {
                serviceId: { _eq: $id }
                employeeId: { _in: $permissionEmployeeIdsToDelete }
              }
            ) {
              affected_rows
            }
            insertServicePermissions(objects: $servicePermissionInsertInputs) {
              returning {
                serviceId
              }
            }
            deleteServiceProducts(
              where: { serviceId: { _eq: $id }, productId: { _in: $consumableIdsToDelete } }
            ) {
              affected_rows
            }
            insertServiceProducts(objects: $serviceProductInsertInputs) {
              returning {
                serviceId
              }
            }
          }
        `,
        { ...dto },
      ),
    {
      onSuccess: data => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(SERVICES_QUERY_KEY),
        })
        queryClient.invalidateQueries([SERVICE_GROUPS_QUERY_KEY])
        if (data.updateServiceById?.availableToAllBranches) {
          queryClient.invalidateQueries({
            predicate: query => query.queryKey.includes(INTERBRANCH_SERVICES_QUERY_KEY),
          })
        }
      },
      onError: reportGqlError,
    },
  )
}

export function useResetServiceServiceGroup() {
  return useMutation(
    async (
      id: number,
    ): Promise<{
      updateServiceById: { id: number }
    }> =>
      request(
        gql`
          mutation ($id: Int!) {
            updateServiceById(pk_columns: { id: $id }, _set: { groupId: null }) {
              id
            }
          }
        `,
        { id },
      ),
    {
      onSuccess: () => queryClient.invalidateQueries([SERVICES_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useArchiveService() {
  return useMutation(
    async (dto: {
      id: number
      archive: boolean
      // eslint-disable-next-line camelcase
    }): Promise<{ updateServiceArchives: { affected_rows: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $archived: timestamptz) {
            updateServiceArchives(where: { id: { _eq: $id } }, _set: { archived: $archived }) {
              affected_rows
            }
          }
        `,
        { id: dto.id, archived: dto.archive ? new Date().toISOString() : null },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([DEACTIVATED_SERVICES_QUERY_KEY])
        queryClient.invalidateQueries([SERVICES_QUERY_KEY])
        queryClient.invalidateQueries([DEACTIVATED_SERVICE_GROUPS_QUERY_KEY])
        queryClient.invalidateQueries([SERVICE_GROUPS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
