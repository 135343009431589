import { RouteType, ChildRouteType } from 'routes/logic'
import { CreateBusinessPage } from 'pages/CreateBusinessPage'
import { MainInfoStep } from 'pages/CreateBusinessPage/QuickSteps/MainInfoStep'
import { ScheduleStep } from 'pages/CreateBusinessPage/ExtendedSteps/ScheduleStep'
import { EmployeesStep } from 'pages/CreateBusinessPage/ExtendedSteps/EmployeesStep'
import { CompletedStep } from 'pages/CreateBusinessPage/CompletedStep'
import { ServicesStep } from 'pages/CreateBusinessPage/ExtendedSteps/ServicesStep'
import { LocationsStep } from 'pages/CreateBusinessPage/ExtendedSteps/LocationsStep'
import { AccountsStep } from 'pages/CreateBusinessPage/ExtendedSteps/AccountsStep'
import { SubscriptionsStep } from 'pages/CreateBusinessPage/ExtendedSteps/SubscriptionsStep'
import { BusinessSettingsStep } from 'pages/CreateBusinessPage/ExtendedSteps/BusinessSettingsStep'
import { ModulesStep } from 'pages/CreateBusinessPage/QuickSteps/ModulesStep'

export const CREATE_BUSINESS_EMPLOYEES_PATH = '/create-business/employees'

export const createBusinessMainInfoStepsRoutes: ChildRouteType[] = [
  {
    name: 'createBusiness.basicInfo',
    path: '/create-business/main',
    isPrivate: false,
    parentRoute: { path: '/create-business', name: 'businessCreation' },
    element: MainInfoStep,
  },
  {
    name: 'menu.modules.title',
    path: '/create-business/modules',
    isPrivate: false,
    parentRoute: { path: '/create-business', name: 'businessCreation' },
    element: ModulesStep,
  },
]

export const createBusinessExtendedStepsRoutes: ChildRouteType[] = [
  {
    name: 'schedule.name',
    path: '/create-business/schedule',
    isPrivate: true,
    parentRoute: { path: '/create-business', name: 'businessCreation' },
    element: ScheduleStep,
  },
  {
    name: 'accounts',
    path: '/create-business/accounts',
    isPrivate: true,
    parentRoute: { path: '/create-business', name: 'businessCreation' },
    element: AccountsStep,
  },
  {
    name: 'employees.name',
    path: CREATE_BUSINESS_EMPLOYEES_PATH,
    isPrivate: true,
    parentRoute: { path: '/create-business', name: 'businessCreation' },
    element: EmployeesStep,
  },
  {
    name: 'locations.name',
    path: '/create-business/locations',
    isPrivate: true,
    parentRoute: { path: '/create-business', name: 'businessCreation' },
    element: LocationsStep,
  },
  {
    name: 'services',
    path: '/create-business/services',
    isPrivate: true,
    parentRoute: { path: '/create-business', name: 'businessCreation' },
    element: ServicesStep,
  },
  {
    name: 'subscriptions',
    path: '/create-business/subscriptions',
    isPrivate: true,
    parentRoute: { path: '/create-business', name: 'businessCreation' },
    element: SubscriptionsStep,
    modules: ['subscriptions'],
  },
  {
    name: 'businessSettings.name',
    path: '/create-business/businessSettings',
    isPrivate: true,
    parentRoute: { path: '/create-business', name: 'businessCreation' },
    element: BusinessSettingsStep,
  },
]

export const createBusinessFinishStepRoute: ChildRouteType = {
  name: 'createBusiness.finish',
  path: '/create-business/complete',
  isPrivate: true,
  parentRoute: { path: '/create-business', name: 'businessCreation' },
  element: CompletedStep,
}

export const createBusinessRoute: RouteType = {
  name: 'businessCreation',
  path: '/create-business',
  isPrivate: false,
  element: CreateBusinessPage,
  children: [
    ...createBusinessMainInfoStepsRoutes,
    ...createBusinessExtendedStepsRoutes,
    createBusinessFinishStepRoute,
  ],
}
