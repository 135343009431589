import { ServerStorageType, useFetchStorages, useGetBranchDefaultStorageId } from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { findById } from '@expane/logic/utils'
import { Button, Page, Paper, Spinner } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { usePrintWrapper } from 'logic/hooks/usePrintWrapper'
import { StorageLeftoversList } from 'pages/StoragePage/StorageLeftoversList'
import { useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { IoBagHandleOutline, IoPrintOutline } from 'react-icons/io5'
import { store } from 'store'
import { TreeMenu, TreeMenuItem } from 'ui/TreeMenu'
import { onChangeSingleTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { SaveButton } from 'widgets/Buttons'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'
import { StorageDialog } from 'widgets/StorageDialog'

export const StoragePage = () => {
  const { t } = useTranslation()

  const { openCreateDialog, openEditDialog, dialog } = useOpenDialog(StorageDialog)
  const [selectedStorage, setSelectedStorage] = useState<TreeMenuItem | undefined>({
    ...allStoragesItem,
    name: t('allStorages'),
  })

  const branchId = store.branch.branchId
  const { data: storages, isLoading: isStoragesLoading } = useFetchStorages(branchId)
  const { data: myPermission } = useFetchMyPermissions()
  const { data: defaultStorageId, isLoading: isLoadingDefaultStorageId } =
    useGetBranchDefaultStorageId(branchId)

  const isEditingAllowed = myPermission?.includes(permissions.storage.set)

  const { PrintWrapper, onPrint } = usePrintWrapper(
    selectedStorage ? `${t('leftovers')}: ${selectedStorage.name}` : t('leftovers'),
  )

  return (
    <Page>
      <div className="flex items-center justify-between">
        {isEditingAllowed && (
          <SaveButton className="mr-2 mb-2" onClick={() => openCreateDialog()} isCreate />
        )}
        <Button
          type="outline"
          className="w-40 mr-2 mb-2"
          onClick={onPrint}
          twoLines
          disabled={!selectedStorage}
          Icon={IoPrintOutline}
        >
          {t('print.leftovers')}
        </Button>
      </div>

      {isStoragesLoading || isLoadingDefaultStorageId ? (
        <Spinner expandCentered />
      ) : storages?.length === 0 ? (
        <div className="flex flex-col h-full">
          <EmptyPlaceholder Icon={IoBagHandleOutline} text={t('emptyPlaceholder.storage')} />
        </div>
      ) : (
        <Paper className="h-full flex overflow-hidden">
          <TreeMenu
            // в MultiPickMode можно выбрать папку
            type="MultiPickMode"
            className="w-1/4 py-3 px-2 border-r border-block-color h-full overflow-auto"
            items={transformStorageForTreeMenu(storages, t, defaultStorageId)}
            isSearch={false}
            selected={selectedStorage ? [selectedStorage] : undefined}
            onSelected={item => {
              // onChangeSingleTreeMenu потому что нужен только 1 выбранный айтем папка или склад
              setSelectedStorage(onChangeSingleTreeMenu(item, selectedStorage))
            }}
            onEditItem={item => {
              openEditDialog(item.id)
            }}
            openSwitch
            alwaysOpen
            showHighlight={false}
          />

          {selectedStorage && (
            <div className="p-3 w-3/4">
              <StorageLeftoversList
                storageId={
                  (selectedStorage && findById(selectedStorage.id, storages)?.id) ??
                  allStoragesItem.id
                }
                PrintWrapper={PrintWrapper}
              />
            </div>
          )}
        </Paper>
      )}

      {dialog}
    </Page>
  )
}

export const allStoragesItem: TreeMenuItem = {
  id: -1,
  name: 'allStorages',
  isFolder: true,
}

const transformStorageForTreeMenu = (
  storages: ServerStorageType[] | undefined,
  t: TFunction,
  defaultStorageId: number | undefined,
): TreeMenuItem[] => {
  const result: TreeMenuItem[] = []

  if (!storages) return result

  const allStorage = { ...allStoragesItem, name: t('allStorages') }

  const parentFolder: TreeMenuItem = {
    ...allStorage,
    nodes: storages.map(storage => ({
      ...storage,
      parentId: allStorage.id,
      ...(storage.id === defaultStorageId
        ? { tag: { name: t('default'), color: 'primary-300' } }
        : undefined),
    })),
  }

  result.push(parentFolder)

  return result
}
