import {
  ServerClientBriefWithTagType,
  ServerLeadType,
  ServerTransactionBriefWithClient,
  useFetchClients,
  useFetchCurrentBranchTimezone,
  useFetchLeads,
  useFetchTransactionsBriefWithClient,
  useFetchTransactionsBriefWithClientByDate,
} from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { getNewClients } from '@expane/logic/client'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { calcClientsDeposits } from '@expane/logic/finances/calculation'
import {
  Hint,
  PlaceholderString,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { getTransactionsByClients } from 'logic/clients'
import { ReportProps } from 'pages/ReportsPage'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

export const LeadReport: FC<PropsWithBranchId<ReportProps>> = ({
  startDate,
  endDate,
  branchId,
}) => {
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: leads, isLoading: isLoadingLeads } = useFetchLeads()
  const { data: clients, isLoading: isLoadingClients } = useFetchClients(timezone, branchId)

  const { data: transactions, isLoading } = useFetchTransactionsBriefWithClientByDate({
    startDate,
    endDate,
    timezone,
    branchId,
  })
  const { data: allTransactions, isLoading: isLoadingAllTransactions } =
    useFetchTransactionsBriefWithClient(timezone, branchId)

  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const clientsWithLead = clients ? clients.filter(client => client.leadId) : []

  const transactionsWithClientsWithLeadLifeTime = getTransactionsByClients(
    allTransactions ?? [],
    clientsWithLead,
  )

  const transactionsWithClientsWithLeadByDate = getTransactionsByClients(
    transactions ?? [],
    clientsWithLead,
  )

  const clientsDepositTotalValue = calcClientsDeposits(transactionsWithClientsWithLeadByDate)

  const { newClientsQuantity } = getNewClients(
    transactionsWithClientsWithLeadByDate,
    transactionsWithClientsWithLeadLifeTime,
  )

  if (clientsWithLead.length === 0) return <EmptyPlaceholder text={t('emptyPlaceholder.archive')} />

  return (
    <TableContainer>
      <TableHeader>
        <tr>
          <TableHeaderCell className="w-64">{t('lead')}</TableHeaderCell>
          <TableHeaderCell className="w-48">
            <div className="flex justify-end">
              {t('totalClients')} <Hint color="bright">{t('reports.totalClientsHint')}</Hint>
            </div>
          </TableHeaderCell>
          <TableHeaderCell className="text-right w-32">{t('clientsBlock.new')}</TableHeaderCell>
          <TableHeaderCell className="text-right">
            <div className="flex justify-end">
              {t('reports.addedToClientsAccounts')}{' '}
              <Hint color="bright">{t('reports.addedToClientsAccountsHint')}</Hint>
            </div>
          </TableHeaderCell>
        </tr>
      </TableHeader>

      <TableBody>
        {isLoadingLeads || isLoadingClients || isLoading || isLoadingAllTransactions ? (
          <>
            <LeadReportItem />
            <LeadReportItem />
            <LeadReportItem />
          </>
        ) : (
          <>
            {leads?.map(l => (
              <LeadReportItem
                key={l.id}
                clientsWithLead={clientsWithLead}
                lead={l}
                transactionsWithClientsByDate={transactions}
                transactionsWithClientsLifeTime={allTransactions}
                branchId={branchId}
              />
            ))}
          </>
        )}
      </TableBody>
      <TableFooter>
        <TableCell>{t('total')}</TableCell>
        <TableCell className="text-right">{clientsWithLead?.length}</TableCell>
        <TableCell className="text-right">{newClientsQuantity}</TableCell>

        <TableCell className="text-right">
          {convertNumberToMoney(clientsDepositTotalValue)}
        </TableCell>
      </TableFooter>
    </TableContainer>
  )
}

interface LeadReportItemProps {
  clientsWithLead?: ServerClientBriefWithTagType[]
  lead?: ServerLeadType
  transactionsWithClientsByDate?: ServerTransactionBriefWithClient[]
  transactionsWithClientsLifeTime?: ServerTransactionBriefWithClient[]
  branchId?: number | undefined
}

const LeadReportItem: FC<LeadReportItemProps> = ({
  clientsWithLead,
  lead,
  transactionsWithClientsByDate,
  transactionsWithClientsLifeTime,
  branchId,
}) => {
  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  //  сколько клиентов по источнику
  const clientsByLead = clientsWithLead?.filter(c => c.leadId === lead?.id) ?? []
  const clientsByLeadQuantity = clientsByLead.length

  //  находим транзакции тех клиентов которые уже хотя бы раз внесли депозит (все время)
  const getTransactionsWithClientsByLead = getTransactionsByClients(
    transactionsWithClientsLifeTime ?? [],
    clientsByLead,
  )

  // находим транзакции клиентов за выбранное время
  const transactionsWithClientsByLeadByPeriod = getTransactionsByClients(
    transactionsWithClientsByDate ?? [],
    clientsByLead,
  )

  //  на какую сумму было сделано депозитов за выбранное время
  const totalValue = transactionsWithClientsByLeadByPeriod
    ? calcClientsDeposits(transactionsWithClientsByLeadByPeriod)
    : 0

  const { newClientsQuantity } = getNewClients(
    transactionsWithClientsByLeadByPeriod,
    getTransactionsWithClientsByLead,
  )
  if (clientsByLead.length === 0 && lead) return null

  return (
    <TableRow>
      <TableCell>{lead ? lead.name : <PlaceholderString />}</TableCell>
      <TableCell>
        {lead ? (
          <div className="text-right">{clientsByLeadQuantity}</div>
        ) : (
          <PlaceholderString className="ml-auto" />
        )}
      </TableCell>
      <TableCell>
        {lead ? (
          <div className="text-right">{newClientsQuantity}</div>
        ) : (
          <PlaceholderString className="ml-auto" />
        )}
      </TableCell>
      <TableCell>
        {lead ? (
          <div className="text-right">{convertNumberToMoney(totalValue)}</div>
        ) : (
          <PlaceholderString className="ml-auto" />
        )}
      </TableCell>
    </TableRow>
  )
}
