import { InactiveReasonForService } from 'logic/bookingServiceLogic'
import { useState, FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { IoEllipsisHorizontal } from 'react-icons/io5'
import { TreeMenuItem } from 'ui/TreeMenu'

export const BookingInactiveReason: FC<{
  item: TreeMenuItem & {
    customInactiveReason?: InactiveReasonForService
  }
  onLocationClick: (id: number) => void
  onEmployeeClick: (id: number) => void
  // если после нажатия на причину айтем перестаёт быть disabled
  onFinalAction: () => void
}> = ({ item, onLocationClick, onEmployeeClick, onFinalAction }) => {
  const { t } = useTranslation()

  const [expandedLocations, setExpandedLocations] = useState(false)
  const [expandedEmployees, setExpandedEmployees] = useState(false)

  let locationInactiveReason: ReactElement | null = null
  const isLocationInvalid = item.customInactiveReason?.locations !== undefined

  let employeeInactiveReason: ReactElement | null = null
  const isEmployeeInvalid = item.customInactiveReason?.employees !== undefined

  if (item.customInactiveReason?.locations !== undefined) {
    const areLocationsFitting = item.customInactiveReason.locations.length < 4 || expandedLocations
    const locationsToDisplay = areLocationsFitting
      ? item.customInactiveReason.locations
      : item.customInactiveReason.locations.slice(0, 3)

    locationInactiveReason = (
      <div>
        {t('serviceInactiveReason.location')}

        <ul className="list-disc">
          {locationsToDisplay.map(location => {
            return (
              <li key={location.id}>
                <button
                  className={buttonClassName}
                  onClick={() => {
                    onLocationClick(location.id)
                    if (!isEmployeeInvalid && item.inactiveReason === undefined) onFinalAction()
                  }}
                  onMouseDown={e => e.nativeEvent.stopPropagation()} // prevent from "parent" popup to close on `useClickOutside`
                >
                  {location.name}
                </button>
              </li>
            )
          })}
        </ul>
        {!areLocationsFitting && (
          <button className={expandButtonClassName} onClick={() => setExpandedLocations(true)}>
            <IoEllipsisHorizontal />
          </button>
        )}
      </div>
    )
  }

  if (item.customInactiveReason?.employees !== undefined) {
    if (item.customInactiveReason.employees.length === 0) {
      employeeInactiveReason = <div>{t('serviceInactiveReason.noEmployee')}</div>
    } else {
      const areEmployeesFitting =
        item.customInactiveReason.employees.length < 4 || expandedEmployees
      const employeesToDisplay = areEmployeesFitting
        ? item.customInactiveReason.employees
        : item.customInactiveReason.employees.slice(0, 3)

      employeeInactiveReason = (
        <div>
          {t('serviceInactiveReason.employee')}
          <ul>
            {employeesToDisplay.map(employee => (
              <li key={employee.id}>
                <button
                  className={buttonClassName}
                  onClick={() => {
                    onEmployeeClick(employee.id)
                    if (!isLocationInvalid && item.inactiveReason === undefined) onFinalAction()
                  }}
                  onMouseDown={e => e.nativeEvent.stopPropagation()} // prevent from "parent" popup to close on `useClickOutside`
                >
                  {employee.name}
                </button>
              </li>
            ))}
          </ul>
          {!areEmployeesFitting && (
            <button className={expandButtonClassName} onClick={() => setExpandedEmployees(true)}>
              <IoEllipsisHorizontal />
            </button>
          )}
        </div>
      )
    }
  }

  return (
    <div>
      {locationInactiveReason}
      {employeeInactiveReason}
      {item.inactiveReason ? <div>{item.inactiveReason}</div> : null}
    </div>
  )
}

const buttonClassName = 'underline hover:text-primary-50'
const expandButtonClassName = 'hover:text-primary-50'
