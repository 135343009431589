import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LocationDialog } from 'widgets/LocationDialog'
import { BookingCalendarType } from '.'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { permissions } from '@expane/logic/permission'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'
import { IoPeopleOutline } from 'react-icons/io5'

const LocationsPlaceholder: FC = () => {
  const { t } = useTranslation()

  const { openCreateDialog: openCreateLocationDialog, dialog } = useOpenDialog(LocationDialog)
  return (
    <div className="h-full w-full flex-centered flex-col text-gray-300 text-xl">
      <h1>{t('createFirstLocation.1')}</h1>
      <h1>
        {t('createFirstLocation.2')}
        <button
          onClick={() => openCreateLocationDialog()}
          className="text-gray-400 font-medium hover:text-gray-600 underline"
        >
          {t('createFirstLocation.3')}
        </button>
      </h1>
      {dialog}
    </div>
  )
}

const EmployeePlaceholder: FC = () => {
  const { t } = useTranslation()
  return (
    <div className="h-full w-full flex-centered flex-col text-gray-300 text-xl">
      <h1>{t('assignServiceToEmployee.1')}</h1>
      <h1>
        {t('assignServiceToEmployee.2')}
        <Link to="/employees" className="text-gray-400 font-medium hover:text-gray-600 underline">
          {t('assignServiceToEmployee.3')}
        </Link>
      </h1>
    </div>
  )
}

export const NoDataPlaceholder: FC<{
  calendarType: BookingCalendarType
  thereAreLocations: boolean
  isLocationsLoading: boolean
  thereAreEmployees: boolean
  isEmployeesLoading: boolean
  myPermissions: string[] | undefined
}> = ({
  calendarType,
  thereAreEmployees,
  thereAreLocations,
  isEmployeesLoading,
  isLocationsLoading,
  myPermissions,
}) => {
  const { t } = useTranslation()

  const isCreatingLocationsAllowed = myPermissions?.includes(permissions.location.set)
  const isCreatingServicesAllowed = myPermissions?.includes(permissions.service.set)
  const isCreatingAllowed = isCreatingLocationsAllowed && isCreatingServicesAllowed

  const isLoading = isEmployeesLoading || isLocationsLoading
  const noData = !thereAreEmployees || !thereAreLocations

  if ((!isCreatingLocationsAllowed || !isCreatingServicesAllowed) && !isLoading && noData)
    return <EmptyPlaceholder Icon={IoPeopleOutline} text={t('emptyPlaceholder.archive')} />

  if (!thereAreLocations && !isLocationsLoading && isCreatingAllowed)
    return <LocationsPlaceholder />

  if (
    calendarType === 'employees' &&
    !thereAreEmployees &&
    !isEmployeesLoading &&
    isCreatingAllowed
  )
    return <EmployeePlaceholder />

  return null
}
