import { utcToZonedTime } from '@expane/date'
import { CardToParse, ClientGiftCardType, parseDatesInCardGqlResponse } from '../card/logic'
import {
  ServerClientBriefsAndCardsType,
  ServerClientBriefWithTagType,
  ServerClientByIdType,
  ServerClientPhoneView,
  ServerCloudFunctionResult,
  ServerLanguages,
  ServerMyClientType,
} from '../../generated/graphql-types'
import { Connections, NotificationSettings } from '../clientSettings'
import { CardType } from '../card'
import { SubscriptionType } from '../cardTemplate'

export interface ClientByIdType extends Omit<ServerClientByIdType, 'clientSettings'> {
  clientSettings: {
    preferredConnections: Connections
    notificationSettings: NotificationSettings
  }
}

export interface ClientBriefsAndCardsType extends ServerClientBriefsAndCardsType {
  cards: Array<CardType>
}

export interface ClientBriefWithTagType
  extends Omit<ServerClientBriefWithTagType, 'clientSetting'> {
  clientSettings: {
    preferredConnections: Connections
  }
}

export interface ClientByIdWithSubscriptions extends ClientByIdType {
  subscriptions: SubscriptionType[]
}

export interface MyClientType extends ServerMyClientType {
  subscriptions: SubscriptionType[]
  giftCards: ClientGiftCardType[]
  clientSettings: {
    lang: ServerLanguages | null
    preferredConnections: Connections
    notificationSettings: NotificationSettings
  }
}

export interface ClientPhones extends ServerClientPhoneView {
  id: number // id cannot be empty
}

export type ClientToParse = {
  createdAt?: string | Date | null
  birthDate?: string | Date | null
  cards?: CardToParse[] | null
  subscriptions?: CardToParse[] | null
  giftCards?: CardToParse[]
}
export const parseDatesInClientGqlResponse = <T extends ClientToParse>(
  client: T,
  timezone: string,
) => {
  if (client.createdAt) {
    client.createdAt = utcToZonedTime(client.createdAt, timezone)
  }
  if (client.birthDate) {
    client.birthDate = utcToZonedTime(client.birthDate, timezone)
  }

  if (client.cards) {
    client.cards = client.cards.map(card => parseDatesInCardGqlResponse(card, timezone))
  }
  if (client.giftCards) {
    client.cards = client.giftCards.map(card => parseDatesInCardGqlResponse(card, timezone))
  }
  if (client.subscriptions) {
    // subscriptions are just aliased cards
    client.subscriptions = client.subscriptions.map(subscription =>
      parseDatesInCardGqlResponse(subscription, timezone),
    )
  }

  return client
}

type ClientDocumentToParse = {
  createdAt?: string | Date | null
  startDate?: string | Date | null
  endDate?: string | Date | null
}
export const parseDatesInClientDocumentGqlResponse = <T extends ClientDocumentToParse>(
  clientDocument: T,
  timezone: string,
) => {
  if (clientDocument.createdAt) {
    clientDocument.createdAt = utcToZonedTime(clientDocument.createdAt, timezone)
  }
  if (clientDocument.startDate) {
    clientDocument.startDate = utcToZonedTime(clientDocument.startDate, timezone)
  }
  if (clientDocument.endDate) {
    clientDocument.endDate = utcToZonedTime(clientDocument.endDate, timezone)
  }

  return clientDocument
}

export enum CreateNewClientResponseCodes {
  insufficientData = 1,
  successful,
  apiError,
  badRequest,
  claimsError,
  notFound,
}

export interface CreateNewClientResult extends ServerCloudFunctionResult {
  code: CreateNewClientResponseCodes
}
