import { useMemo } from 'react'
import {
  ServerBillingInfoType,
  ServerPlanType,
  useFetchLastBusinessCurrentPlanMaxEmployees,
} from '@expane/data'
import { isFuture } from '@expane/date'
import { TFunction } from 'react-i18next'
import { calcPercentage } from '../../logic/utils'

export function isPlanError(error: unknown) {
  if (!(error instanceof Error)) return false

  return error.message.includes('[SQL-13]')
}

export function isRestrictionError(error: unknown) {
  if (!(error instanceof Error)) return false

  return error.message.includes('[SQL-15]') || error.message.includes('[SQL-14]')
}

export const checkIfIsBillingInfo = (billingInfo: ServerBillingInfoType | undefined | null) =>
  billingInfo !== undefined && billingInfo !== null

export const checkIfIsActiveBillingPlan = (billingInfo: ServerBillingInfoType | undefined | null) =>
  (checkIfIsBillingInfo(billingInfo) && billingInfo && isFuture(billingInfo.toDate)) ?? false

export const findCurrentBillingPlan = (
  plans: ServerPlanType[] | undefined,
  billingInfo: ServerBillingInfoType | undefined | null,
) => plans?.find(plan => plan.id === billingInfo?.planId)

export const MONTH_AMOUNT = 1
export const WARNING_PERCENTAGE = 75

export const generateBillingWarningMessage = ({
  plan,
  currentTurnover,
  employeeCount,
  isChangeBillingPlanAllowed,
  isEnableCheckBillingTurnover,
}: {
  plan: ServerPlanType
  currentTurnover: number
  employeeCount: number
  isChangeBillingPlanAllowed: boolean
  isEnableCheckBillingTurnover: boolean
}) => {
  if (!isChangeBillingPlanAllowed) return 'plan.warnings.permissions'

  if (isEnableCheckBillingTurnover)
    if (plan.maxTurnover && currentTurnover >= plan.maxTurnover) return 'plan.warnings.turnover'

  if (plan.maxEmployees && employeeCount > plan.maxEmployees) return 'plan.warnings.employees'

  return ''
}

export const generateBillingConfirmationDescription = (
  {
    currentTurnover,
    employeeCount,
    plan,
    isEnableCheckBillingTurnover,
  }: {
    employeeCount: number
    currentTurnover: number
    plan: ServerPlanType
    isEnableCheckBillingTurnover: boolean
  },
  t: TFunction,
) => {
  const messages: string[] = []

  const { isEmployeeWarning, turnoverPercentage, employeePercentage, isTurnoverWarning } =
    checkIfAreBillingWarnings({
      plan,
      currentTurnover,
      employeeCount,
      isEnableCheckBillingTurnover,
    })

  if (isTurnoverWarning)
    messages.push(
      t('planConfirmation.turnover', {
        turnover: currentTurnover,
        turnoverPercentage: turnoverPercentage.toFixed(2),
      }),
    )

  if (isEmployeeWarning)
    messages.push(
      t('planConfirmation.employee', {
        employeeCount,
        employeePercentage: employeePercentage.toFixed(2),
      }),
    )

  return messages.join('\n') + '\n' + t('planConfirmation.question')
}

export const checkIfAreBillingWarnings = ({
  plan,
  employeeCount,
  currentTurnover,
  isEnableCheckBillingTurnover,
}: {
  plan: ServerPlanType
  currentTurnover: number
  employeeCount: number
  isEnableCheckBillingTurnover: boolean
}) => {
  const turnoverPercentage = getTurnoverPercentageByBillingPlan(plan, currentTurnover)

  const isTurnoverWarning = isEnableCheckBillingTurnover
    ? turnoverPercentage >= WARNING_PERCENTAGE
    : false

  const employeePercentage = getEmployeesCountPercentageByBillingPlan(plan, employeeCount)
  const isEmployeeWarning = employeePercentage >= WARNING_PERCENTAGE

  return { isTurnoverWarning, turnoverPercentage, isEmployeeWarning, employeePercentage }
}

const getTurnoverPercentageByBillingPlan = (plan: ServerPlanType, currentTurnover: number) =>
  plan.maxTurnover ? calcPercentage(plan.maxTurnover, currentTurnover) : 0

const getEmployeesCountPercentageByBillingPlan = (plan: ServerPlanType, employeesCount: number) =>
  plan.maxEmployees ? calcPercentage(plan.maxEmployees, employeesCount) : 0

type UseAreManyEmployeesAllowed = (dto?: { enabled: boolean }) => {
  areManyEmployeesAllowed: boolean
  isLoading: boolean
}
export const useAreManyEmployeesAllowed: UseAreManyEmployeesAllowed = (dto = { enabled: true }) => {
  const { data: maxEmployees, isLoading } = useFetchLastBusinessCurrentPlanMaxEmployees(
    dto?.enabled,
  )

  const areManyEmployeesAllowed = useMemo(() => maxEmployees !== 1, [maxEmployees])

  return {
    areManyEmployeesAllowed,
    isLoading,
  }
}
