import {
  BILLING_INFO_TYPES,
  EmployeeByIdExtended,
  ServerBillingInfoType,
  ServerPlanType,
  useCreateBillingInfo,
  useFetchBusinessTurnover,
  useFetchEmployeesCount,
  useFetchLastBillingInfo,
} from '@expane/data'
import { addMonths, createCurrentDate, differenceInDays, isFuture } from '@expane/date'
import {
  checkIfAreBillingWarnings,
  generateBillingConfirmationDescription,
  generateBillingWarningMessage,
  MONTH_AMOUNT,
} from '@expane/logic/billing'
import { FEATURE_FLAGS, useFeatureFlags } from '@expane/logic/featureFlags'
import { permissions } from '@expane/logic/permission'
import { useShowConfirmationPopup, useShowWarningPopup } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyEmployee, useFetchMyPermissions } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import { useOpenPaymentMetadataDialog } from 'pages/SettingsPage/BillingSettings/PaymentMetadataDialog'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/sentry'
import { AUTO_WFP_LANGUAGE, mapI18NLanguageToWFP, runWayForPay } from 'services/wayforpay'
import { store } from 'store'
import { BillingPlanCard } from 'widgets/BillingPlanCard'

export const PlanCard: FC<{
  plan: ServerPlanType
  timezone: string
  isWayForPayError: boolean
}> = observer(({ plan, timezone, isWayForPayError }) => {
  const {
    i18n: { language },
  } = useTranslation()

  const branchId = store.branch.branchId

  const { t } = useTranslation()
  const [showSnackbar] = useSnackbar()

  const { getFeatureFlag } = useFeatureFlags()
  const isEnableCheckBillingTurnover = getFeatureFlag(FEATURE_FLAGS.enableCheckBillingTurnover)

  const { data: billingInfo } = useFetchLastBillingInfo(timezone)

  const selected =
    Boolean(billingInfo) && plan.id === billingInfo?.planId && isFuture(billingInfo.toDate)

  const { data: myEmployee } = useFetchMyEmployee(timezone, branchId)
  const { data: myPermissions } = useFetchMyPermissions()
  const { data: currentTurnover } = useFetchBusinessTurnover(isEnableCheckBillingTurnover)
  const { data: employeeCount } = useFetchEmployeesCount()

  const { mutateAsync: createBillingInfo } = useCreateBillingInfo()

  const { showConfirmation, confirmationModal } = useShowConfirmationPopup()

  const isChangeBillingPlanAllowed = Boolean(myPermissions?.includes(permissions.billingInfo.set))

  const { paymentMetadataDialog, showPaymentMetadataDialog } = useOpenPaymentMetadataDialog()

  const warningMessage = generateBillingWarningMessage({
    plan,
    currentTurnover: currentTurnover ?? 0,
    employeeCount: employeeCount ?? 0,
    isChangeBillingPlanAllowed,
    isEnableCheckBillingTurnover,
  })

  const { warningModal, showWarningPopup } = useShowWarningPopup(t('warning'), t(warningMessage))

  const onClickWithWarningMessage = (onClick: () => void) => () => {
    const isWarningMessage = Boolean(warningMessage)

    if (isWarningMessage) showWarningPopup()
    else onClick()
  }

  const onApprove = () => {
    showSnackbar(t('successBilling'), 'success', 8000)
  }

  const runWFPWidget = ({
    value,
    billingId,
    fromDate,
    myEmployee,
  }: {
    value: number
    billingId: string
    fromDate: Date
    myEmployee: EmployeeByIdExtended
  }) => {
    if (!myEmployee.email || !myEmployee.phone)
      showPaymentMetadataDialog({
        phone: myEmployee.phone,
        email: myEmployee.email,
        onConfirm: data => {
          runWayForPay(
            {
              productName: ['Expane plan ' + plan.name],
              productCount: ['1'],
              productPrice: [value.toString()],
              amount: value.toString(),
              orderReference: billingId.toString(),
              clientEmail: data.email,
              clientFirstName: myEmployee.firstName,
              clientLastName: myEmployee.lastName,
              clientPhone: data.phone.replace(/\+|\s+/g, ''),
              orderDate: fromDate.getTime().toString(),
              currency: 'UAH',
              language: mapI18NLanguageToWFP[language] ?? AUTO_WFP_LANGUAGE,
            },
            { onApprove },
          )
        },
      })
    else
      runWayForPay(
        {
          productName: ['Expane plan ' + plan.name],
          productCount: ['1'],
          productPrice: [value.toString()],
          amount: value.toString(),
          orderReference: billingId.toString(),
          clientEmail: myEmployee.email,
          clientFirstName: myEmployee.firstName,
          clientLastName: myEmployee.lastName,
          clientPhone: myEmployee.phone.replace(/\+|\s+/g, ''),
          orderDate: fromDate.getTime().toString(),
          currency: 'UAH',
          language: mapI18NLanguageToWFP[language] ?? AUTO_WFP_LANGUAGE,
        },
        { onApprove },
      )
  }

  const onClick = () => {
    if (isWayForPayError) {
      showSnackbar(t('paymentService.notAvailable'), 'error')
      return
    }

    const { isEmployeeWarning, isTurnoverWarning } = checkIfAreBillingWarnings({
      plan,
      currentTurnover: currentTurnover ?? 0,
      employeeCount: employeeCount ?? 0,
      isEnableCheckBillingTurnover,
    })

    if (isTurnoverWarning || isEmployeeWarning)
      showConfirmation({
        title: t('planConfirmation.title'),
        description: generateBillingConfirmationDescription(
          {
            currentTurnover: currentTurnover ?? 0,
            employeeCount: employeeCount ?? 0,
            plan,
            isEnableCheckBillingTurnover,
          },
          t,
        ),
        onConfirm: submitPaymentPlan,
      })
    else submitPaymentPlan()
  }

  const submitPaymentPlan = async () => {
    if (!myEmployee || !timezone) {
      showSnackbar(t('submitError'), 'error')
      return
    }

    const discount = calcDiscountBasedOnCurrentPlan(billingInfo, timezone)

    const fromDate = createCurrentDate(timezone)
    const toDate = addMonths(fromDate, MONTH_AMOUNT)
    const value = plan.monthlyCost * MONTH_AMOUNT
    const valueWithDiscount = value - discount
    try {
      const result = await createBillingInfo({
        fromDate,
        toDate,
        value: valueWithDiscount,
        planId: plan.id,
        type: BILLING_INFO_TYPES.deposit,
      })

      runWFPWidget({
        fromDate,
        billingId: result.insertBillingInfo.id,
        value: valueWithDiscount,
        myEmployee,
      })
    } catch (e) {
      reportError(e)
    }
  }

  const submitExtendPlan = async () => {
    if (isWayForPayError) {
      showSnackbar(t('paymentService.notAvailable'), 'error')
      return
    }
    if (!myEmployee || !timezone || !billingInfo) {
      showSnackbar(t('submitError'), 'error')
      return
    }

    const fromDate = billingInfo.toDate
    const toDate = addMonths(fromDate, MONTH_AMOUNT)
    const value = plan.monthlyCost * MONTH_AMOUNT

    try {
      const result = await createBillingInfo({
        fromDate,
        toDate,
        value,
        planId: plan.id,
        type: BILLING_INFO_TYPES.deposit,
      })

      runWFPWidget({ fromDate, billingId: result.insertBillingInfo.id, value, myEmployee })
    } catch (e) {
      reportError(e)
    }
  }

  return (
    <div>
      <BillingPlanCard
        plan={plan}
        selected={selected}
        onClick={onClickWithWarningMessage(onClick)}
        onExtend={onClickWithWarningMessage(submitExtendPlan)}
      />
      {confirmationModal}
      {warningModal}
      {paymentMetadataDialog}
    </div>
  )
})

const calcDiscountBasedOnCurrentPlan = (
  billingInfo: ServerBillingInfoType | null | undefined,
  timezone: string | undefined,
) => {
  if (!billingInfo) return 0

  const days = differenceInDays(billingInfo.toDate, billingInfo.fromDate)
  const daysLeft = differenceInDays(billingInfo.toDate, createCurrentDate(timezone))

  const pricePerDay = billingInfo.value / days

  return pricePerDay * daysLeft
}
