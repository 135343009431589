import { utcToZonedTime } from '@expane/date'
import { BookingToParse, parseDatesInBookingGqlResponse } from '../booking/logic'
import { ReviewType } from '../review/logic'

export enum NOTIFICATION_TYPE {
  toClient = 1, // оправленные уведомления с приложения
  waitingList = 2, // уведомления сотруднику/сотрудникам при доступном времени записи
  bookingCreatedByClient = 3, // уведомления сотрудникам, когда клиент создает запись в приложении
  toEmployee = 4,
  billingReminder = 5, // Уведомление сотрудникам о том, что скоро заканчивается подписка. В `message` указано кол-во дней
  review, // повідомлення групам співробітників у яких є доступ затверджувати відгуки про новий відгук
}
export enum NOTIFICATION_STATUS {
  pending, // Ждём, пока запустится функция отправки сообщений
  waiting, // Запрос сообщения отправлен, ждём статуса по токену сообщения
  sent, // Сообщение дошло до адресата
  read, // Адресат прочитал сообщение
  error = 10,
}

export enum NOTIFICATION_TYPE_VARIATION {
  bookingInsert,
  bookingUpdateTime,
  bookingCancel,
  bookingInPlaceStatus,
}

export type WaitingListNotificationType = {
  type: NOTIFICATION_TYPE.waitingList
  id: number
  message: string
  sentAt: Date | null
  status: number | null
  waitingList: {
    service: { name: string }
    client: { firstName: string; lastName: string | null }
  }
}
export type NotificationOfBookingCreatedByClientType = {
  type: NOTIFICATION_TYPE.bookingCreatedByClient
  id: number
  sentAt: Date | null
  status: number | null
  message: string
  booking: {
    id: number
    duration: number
    startDate: Date
    client: { firstName: string; lastName: string | null } | null
    employee: { firstName: string; lastName: string } | null
    bookingServices: Array<{ service: { name: string } }>
  } | null
}
export type ToEmployeeNotificationType = {
  type: NOTIFICATION_TYPE.toEmployee
  typeVariation: NOTIFICATION_TYPE_VARIATION | null
  id: number
  message: string
  sentAt: Date | null
  status: number | null
  bookingId: number | null
}
export type BillingReminderNotificationType = {
  type: NOTIFICATION_TYPE.billingReminder
  id: number
  message: string
  sentAt: Date | null
  status: number | null
}
export type ReviewNotificationType = {
  type: NOTIFICATION_TYPE.review
  id: number
  message: string
  sentAt: Date | null
  status: number | null
  review: ReviewType
}

export type NotificationUnionType =
  | WaitingListNotificationType
  | NotificationOfBookingCreatedByClientType
  | ToEmployeeNotificationType
  | BillingReminderNotificationType
  | ReviewNotificationType

export type InfiniteNotifications = {
  notifications: NotificationUnionType[]
  totalNotifications: number
  currentPage: number
}

type NotificationToParse = {
  sentAt?: string | Date | null
  booking?: BookingToParse | null
}
export const parseDatesInNotificationGqlResponse = <T extends NotificationToParse>(
  notification: T,
  timezone: string,
) => {
  if (notification.sentAt) {
    notification.sentAt = utcToZonedTime(notification.sentAt, timezone)
  }
  if (notification.booking) {
    notification.booking = parseDatesInBookingGqlResponse(notification.booking, timezone)
  }
  return notification
}
