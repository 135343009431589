import { BookingUnion } from '@expane/data'
import { createCurrentDate, getDate, getMonth, set } from '@expane/date'
import { permissions } from '@expane/logic/permission'
import { Dialog, Paper, Textarea } from '@expane/ui'
import { DateTimePicker } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { checkBookingIsTodayOrLater } from 'logic/calendar'
import { FC } from 'react'
import { Controller, useFieldArray, UseFormReturn, useFormState, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoAddCircleOutline } from 'react-icons/io5'
import { BookingMultiServicesClientBlock } from 'widgets/BookingMultiServicesDialog/ClientBlock'
import {
  BookingMultiServicesDialogFormValues,
  BookingMultiServicesInitialDto,
} from 'widgets/BookingMultiServicesDialog/index'
import { MultiServiceBlock } from 'widgets/BookingMultiServicesDialog/MultiServiceBlock'
import { QuickFixMultiServices } from 'widgets/BookingMultiServicesDialog/QuickFixMultiServices'
import { QuickFixMultiServicesStatus } from 'widgets/BookingMultiServicesDialog/QuickFixMultiServicesStatus'
import { getIsBookingDone } from '@expane/logic/booking'

interface BookingMultiServicesBodyProps {
  form: UseFormReturn<BookingMultiServicesDialogFormValues>
  initialData: BookingMultiServicesInitialDto
  closeDialog: () => void
  initialBookings: Array<BookingUnion>
  timezone: string
}

export const BookingMultiServicesBody: FC<BookingMultiServicesBodyProps> = ({
  form,
  initialData,
  closeDialog,
  initialBookings,
  timezone,
}) => {
  const { t } = useTranslation()

  const { data: myPermissions } = useFetchMyPermissions()
  const canEditPast = myPermissions?.includes(permissions.booking.editPast) ?? false

  const { control, setValue } = form

  const { isDirty } = useFormState({ control })
  const { fields, remove, prepend } = useFieldArray({ control, name: 'multiServicesDto' })

  const watchedMultiServicesDto = useWatch({
    control,
    name: `multiServicesDto`,
  })

  // если хотя бы один из букингов done, то мы не можем менять общую информацию (коммент, клиент)
  const isSomeBookingDone = initialBookings?.some(booking => getIsBookingDone(booking))

  return (
    <Dialog.Body className="w-288 h-120 flex">
      <div className="flex w-full">
        <div className="w-1/3 pr-2">
          <BookingMultiServicesClientBlock
            control={control}
            isSomeBookingDone={isSomeBookingDone}
          />

          <Controller
            control={control}
            name="note"
            render={({ field: { value, onChange } }) => (
              <Textarea
                placeholder={t('placeholders.bookingComment')}
                label={t('note')}
                value={value}
                onChange={onChange}
                rows={1}
                containerClassName="mt-2"
                disabled={isSomeBookingDone}
              />
            )}
          />
        </div>

        <div className="w-2/3 flex flex-col">
          {!initialData.isCreate && (
            <QuickFixMultiServicesStatus
              bookings={initialBookings}
              isDirty={isDirty}
              closeDialog={closeDialog}
            />
          )}

          <div className="flex">
            <Controller
              control={control}
              name="date"
              rules={{
                validate: startDate =>
                  canEditPast || checkBookingIsTodayOrLater(startDate, timezone),
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <DateTimePicker
                  timezone={timezone}
                  label={t('dateTitle')}
                  value={value}
                  onChange={date => {
                    // необходимо во всех timePicker изменить день/месяц
                    const changedDate = set(date, {
                      month: getMonth(date),
                      date: getDate(date),
                    })

                    const newMultiServicesDto = watchedMultiServicesDto.map(multiService => ({
                      ...multiService,
                      startDate: changedDate,
                    }))

                    setValue('multiServicesDto', newMultiServicesDto, { shouldDirty: true })
                    onChange(date)
                  }}
                  className="w-1/3 pr-2"
                  type="date"
                  showQuickButtons
                  errorMessage={{
                    isShown: Boolean(error),
                    text: t('bookingValidation.notAllowedCreatePast'),
                  }}
                />
              )}
            />

            <QuickFixMultiServices form={form} />
          </div>

          <button
            onClick={() =>
              prepend({
                startDate: initialData.startDate ?? createCurrentDate(timezone),
                services: [],
                bookingId: undefined,
                consumables: [],
                locationId: undefined,
                employeeId: null,
                duration: '5',
              })
            }
            className="ml-auto text-gray-500 hover:text-primary-400 mt-2 flex items-center"
          >
            <IoAddCircleOutline size="1.3rem" className="mr-1" />
            <p>{t('multiService')}</p>
          </button>

          <Paper className="overflow-auto flex-1 p-2 mt-2">
            {fields.map((multiService, multiServicesDtoIndex) => (
              <MultiServiceBlock
                timezone={timezone}
                key={multiService.id}
                multiServicesDtoIndex={multiServicesDtoIndex}
                form={form}
                initialData={initialData}
                remove={remove}
                bookingsCount={fields.length}
                closeDialog={closeDialog}
                initialBookings={initialBookings}
              />
            ))}
          </Paper>
        </div>
      </div>
    </Dialog.Body>
  )
}
