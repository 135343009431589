import { checkBusinessIsNotPaid } from '@expane/logic/business'
import { permissions } from '@expane/logic/permission'
import { Button, Page, Paper } from '@expane/ui'
import { useFetchCurrentBusiness } from 'gql/business'
import { useFetchMyPermissions } from 'gql/employee'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { ProductQuickInfo } from 'pages/ProductsPage/ProductQuickInfo'
import { ProductsTreeMenu } from 'pages/ProductsPage/ProductsTreeMenu'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAddCircle, IoCubeOutline } from 'react-icons/io5'
import { useSnackbar } from '@expane/widgets'
import { TreeMenuItem } from 'ui/TreeMenu'
import { CreateCategoryButton } from 'widgets/Buttons'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'
import { ArchiveListNavigationButton, ListNavigationButton } from 'widgets/NavigationButtons'
import { useOpenProductDialog } from 'widgets/ProductDialog'
import { ProductGroupDialog } from 'widgets/ProductGroupDialog'

interface ProductPageDataProps {
  items: TreeMenuItem[]
  archive?: boolean
}

export const ProductsPageData: FC<ProductPageDataProps> = ({ items, archive = false }) => {
  const { t } = useTranslation()
  const { data: myPermission } = useFetchMyPermissions()
  const { data: currentBusiness } = useFetchCurrentBusiness()
  const [openSnackbar] = useSnackbar()

  const {
    dialog: productDialog,
    openCreateDialog: openCreateProductDialog,
    openEditDialog: openProductEditDialog,
  } = useOpenProductDialog()

  const {
    dialog: productGroupDialog,
    openCreateDialog: openCreateProductGroupDialog,
    openEditDialog: openEditProductGroupDialog,
  } = useOpenDialog(ProductGroupDialog)

  const [selectedItem, setSelectedItem] = useState<TreeMenuItem | undefined>()

  const isProductEditingAllowed = myPermission?.includes(permissions.product.set)
  const isProductArchivingAllowed = myPermission?.includes(permissions.product.archive)

  const handleProductDialog = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else openCreateProductDialog()
  }
  const handleProductGroupDialog = () => {
    if (checkBusinessIsNotPaid(currentBusiness)) openSnackbar(t('planInfo.noPlan'), 'error')
    else openCreateProductGroupDialog()
  }

  return (
    <Page>
      {
        <div className="mb-2 flex">
          {isProductEditingAllowed && !archive && (
            <>
              <Button onClick={handleProductDialog} className="mr-2" Icon={IoAddCircle}>
                {t('product.name')}
              </Button>
              <CreateCategoryButton onClick={handleProductGroupDialog} />
            </>
          )}

          {isProductArchivingAllowed && (
            <>
              {archive ? (
                <ListNavigationButton title={t('products')} />
              ) : (
                <ArchiveListNavigationButton className="ml-auto" />
              )}
            </>
          )}
        </div>
      }

      {items.length === 0 ? (
        <EmptyPlaceholder
          Icon={IoCubeOutline}
          text={archive ? t('emptyPlaceholder.archive') : t('emptyPlaceholder.product')}
        />
      ) : (
        <Paper className="h-full overflow-auto flex">
          <ProductsTreeMenu
            items={items}
            onChange={setSelectedItem}
            selectedItem={selectedItem}
            editProductGroup={openEditProductGroupDialog}
            editProduct={openProductEditDialog}
          />
          {selectedItem && (
            <ProductQuickInfo
              key={selectedItem.id}
              onClick={() => openProductEditDialog(selectedItem.id)}
              productId={selectedItem.id}
            />
          )}
        </Paper>
      )}

      {productDialog}
      {productGroupDialog}
    </Page>
  )
}
