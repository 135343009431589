import { MOVEMENT_TYPES, ServerMovementType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import {
  getFinancialInfoAboutMovement,
  getMovementFulfilledStatus,
  getMovementTypesName,
} from '@expane/logic/movement'
import { Table } from '@expane/ui'
import { ColumnDef, OnChangeFn, RowSelectionState } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { SelectMovementsFormType } from 'pages/MovementsPage'
import { FC, useMemo } from 'react'
import { Control, UseFormGetValues, UseFormSetValue, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoFileTrayStackedOutline } from 'react-icons/io5'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'
import { Checkbox } from '@expane/ui'

interface MovementsListProps {
  movements: Array<ServerMovementType>
  onRowClick: (movement: ServerMovementType) => void
  isLoading: boolean
  control: Control<SelectMovementsFormType>
  setValue: UseFormSetValue<SelectMovementsFormType>
  getValues: UseFormGetValues<SelectMovementsFormType>
  disabled?: boolean
}

export const MovementsList: FC<PropsWithBranchId<MovementsListProps>> = ({
  movements,
  onRowClick,
  isLoading,
  control,
  setValue,
  getValues,
  branchId,
  disabled = false,
}) => {
  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()
  const convertToMoney = useConvertNumberToMoney(branchId)
  const selected = useWatch({ control, name: 'selected' }) ?? {}
  const setSelected: OnChangeFn<RowSelectionState> = newSelected => {
    if (typeof newSelected === 'function') setValue('selected', newSelected(getValues('selected')))
    else setValue('selected', newSelected)
  }

  const columns = useMemo<ColumnDef<ServerMovementType>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
            disabled={disabled}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            onClick={e => e.stopPropagation()}
            checked={row.getIsSelected()}
            disabled={disabled || row.original.type !== MOVEMENT_TYPES.arrival.id}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
        size: 40,
      },
      {
        id: 'type',
        accessorFn: movement => getMovementTypesName(movement.type, t),
        header: t('type'),
        cell: data => data.getValue(),
        size: 220,
      },
      {
        accessorKey: 'number',
        header: t('number'),
        cell: data => data.getValue(),
        size: 100,
      },
      {
        accessorKey: 'createdAt',
        header: t('dateTitle'),
        cell: data => dateFormatting('date', data.getValue<Date>()),
        size: 100,
      },
      {
        id: 'time',
        accessorFn: movement => dateFormatting('time', movement.createdAt),
        header: t('timeTitle'),
        cell: data => data.getValue(),
        size: 60,
      },
      {
        id: 'source',
        accessorFn: movement =>
          movement?.type === MOVEMENT_TYPES.arrival.id
            ? movement?.supplier?.name
            : movement?.fromStorage?.name ?? '-',
        header: t('fromSource'),
        cell: data => data.getValue(),
      },
      {
        id: 'destination',
        accessorFn: movement =>
          movement?.type === MOVEMENT_TYPES.return.id
            ? movement.supplier?.name
            : movement?.toStorage?.name ?? '-',
        header: t('to'),
        cell: data => data.getValue(),
      },
      {
        id: 'amount',
        accessorFn: movement => getFinancialInfoAboutMovement(movement).overallPrice,
        header: () => <span className="w-full text-right">{t('amount')}</span>,
        cell: data => <div className="text-right">{convertToMoney(data.getValue<number>())}</div>,
      },
      {
        id: 'status',
        accessorFn: movement => getMovementFulfilledStatus(movement, t),
        header: t('status'),
        cell: data => data.getValue(),
      },
    ],
    [convertToMoney, dateFormatting, disabled, t],
  )

  if (movements.length === 0 && !isLoading)
    return <EmptyPlaceholder Icon={IoFileTrayStackedOutline} text={t('noInvoices')} />

  return (
    <Table
      columns={columns}
      data={movements}
      isLoading={isLoading}
      rowSelection={selected}
      onRowSelectionChange={setSelected}
      onRowClick={onRowClick}
    />
  )
}
