import { BookingUnion } from '@expane/data'
import { transformPersonName } from '@expane/logic/utils'
import { Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCalendarOutline, IoCashOutline } from 'react-icons/io5'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'
import { BookingServiceCellContent } from 'widgets/BookingServiceListCell'

interface BookingsListProps {
  bookings: BookingUnion[]
  isLoading: boolean
  onClick?: (id: number) => void
}

export const BookingsList: FC<BookingsListProps> = ({ bookings, isLoading, onClick }) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatting()

  const columns = useMemo<ColumnDef<BookingUnion>[]>(
    () => [
      {
        accessorKey: 'startDate',
        header: t('dateTitle'),
        cell: data => formatDate('shortDateWithoutYear', data.getValue<Date>()),
        size: 100,
      },
      {
        id: 'client',
        accessorFn: booking =>
          booking.isGroupBooking
            ? t('groupBooking.name')
            : booking.client && transformPersonName(booking.client),
        header: t('client.name'),
        cell: data => data.getValue(),
        size: 200,
      },
      {
        accessorKey: 'service.name',
        header: t('service.name'),
        cell: data => {
          const booking = data.row.original

          return (
            <BookingServiceCellContent booking={booking} disabled={Boolean(booking.canceledDate)} />
          )
        },
        size: 200,
      },
      {
        accessorKey: 'location.name',
        header: t('location.name'),
        cell: data => data.getValue(),
        size: 200,
      },
      {
        id: 'employee',
        accessorFn: booking => (booking.employee ? transformPersonName(booking.employee) : '-'),
        header: t('employee.name'),
        cell: data => data.getValue(),
        size: 200,
      },
      {
        id: 'author',
        accessorFn: booking =>
          booking.author ? transformPersonName(booking.author) : t('client.name'),
        header: t('created'),
        cell: data => data.getValue(),
        size: 200,
      },
      {
        accessorKey: 'canceledDate',
        header: t('cancellation'),
        cell: data => {
          const canceledDate = data.getValue<Date | null>()
          return canceledDate ? formatDate('shortDateWithoutYear', canceledDate) : '-'
        },
        size: 100,
      },
      {
        accessorKey: 'canceledReason',
        header: t('cause'),
        cell: data => data.getValue() ?? '-',
        size: 150,
      },
      {
        accessorKey: 'isPaid',
        header: '',
        cell: data =>
          data.getValue<boolean>() ? (
            <IoCashOutline size="1.2rem" className="text-primary-500" />
          ) : (
            '-'
          ),
        size: 0,
      },
    ],
    [formatDate, t],
  )
  if (bookings.length === 0 && !isLoading)
    return <EmptyPlaceholder Icon={IoCalendarOutline} text={t('noBookings')} />

  return (
    <Table
      columns={columns}
      data={bookings}
      isLoading={isLoading}
      onRowClick={onClick ? booking => onClick(booking.id) : undefined}
      customRowClassName={booking => (booking.canceledDate ? 'text-gray-400' : undefined)}
    />
  )
}
