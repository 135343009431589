import { FC, PropsWithChildren } from 'react'
import { Hint, Paper } from '@expane/ui'

interface BlockWrapperProps {
  className?: string
  label: string
  hint?: string
}

export const BlockWrapper: FC<PropsWithChildren<BlockWrapperProps>> = ({
  className,
  children,
  label,
  hint,
}) => {
  return (
    <>
      <div className="flex items-center max-w-fit gap-0.5">
        <p className="font-medium text-secondary-color">{label}</p>
        {hint && <Hint>{hint}</Hint>}
      </div>

      <Paper className={`p-4 mb-4 ${className || ''}`}>{children}</Paper>
    </>
  )
}
