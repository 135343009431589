import { ServerTransactionByEmployeeIdType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import {
  countEmployeeHistoryTransactionsTotalSum,
  EmployeeHistoryTabItemDto,
  getEmployeeTransactionsToShowInHistory,
  transformEmployeeTransactionsForHistory,
} from '@expane/logic/employee'
import { Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'

import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkOutline, IoListOutline } from 'react-icons/io5'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

interface HistoryTabProps {
  employeeTransactions: ServerTransactionByEmployeeIdType[] | undefined
}

export const HistoryTab: FC<PropsWithBranchId<HistoryTabProps>> = ({
  employeeTransactions,
  branchId,
}) => {
  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()
  const convertToMoney = useConvertNumberToMoneyCode({ branchId })

  const filteredEmployeeTransactions = useMemo(() => {
    const filtered = getEmployeeTransactionsToShowInHistory(employeeTransactions)

    return transformEmployeeTransactionsForHistory(filtered ?? [])
  }, [employeeTransactions])

  const columns = useMemo<ColumnDef<EmployeeHistoryTabItemDto>[]>(
    () => [
      {
        accessorKey: 'createdAt',
        header: t('dateTitle'),
        cell: data => dateFormatting('historyDate', data.getValue<Date>()),
        size: 180,
      },
      {
        accessorKey: 'name',
        header: t('title'),
        cell: data => data.getValue(),
        size: 440,
      },
      {
        accessorKey: 'isServicePaidByCard',
        header: t('subscription.name'),
        cell: data =>
          data.getValue<boolean>() ? (
            <IoCheckmarkOutline size="1.2rem" className="text-primary-500" />
          ) : (
            <p>-</p>
          ),
        size: 160,
      },
      {
        accessorKey: 'value',
        header: () => <span className="w-full text-right">{t('amount')}</span>,
        cell: data => <div className="text-right">{convertToMoney(data.getValue<number>())}</div>,
        size: 160,
      },
    ],
    [convertToMoney, dateFormatting, t],
  )

  const totalSum = countEmployeeHistoryTransactionsTotalSum(filteredEmployeeTransactions)

  return (
    <>
      {filteredEmployeeTransactions.length !== 0 ? (
        <>
          <div className="flex mb-3">
            <p className={labelStyle}>
              {t('totalServices')}:
              <span className={textStyle}>{filteredEmployeeTransactions.length}</span>
            </p>

            <p className={labelStyle}>
              {t('forAmount')}:<span className={textStyle}>{convertToMoney(totalSum)}</span>
            </p>
          </div>

          <Table
            containerClassName="max-h-104"
            columns={columns}
            data={filteredEmployeeTransactions}
          />
        </>
      ) : (
        <EmptyPlaceholder text={t('noBookings')} Icon={IoListOutline} />
      )}
    </>
  )
}

const labelStyle = 'mr-3 text-gray-400 text-sm'
const textStyle = 'font-medium ml-1 text-gray-600 dark:text-gray-300'
