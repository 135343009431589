import { queryClient } from '../../api'
import { endOfDay, formatISO, startOfDay, utcToZonedTime } from '@expane/date'
import { BOOKINGS_BY_IDS_QUERY_KEY, BOOKINGS_QUERY_KEY } from './queryKeys'
import { EMPLOYEES_QUERY_KEY } from '../employee/queryKeys'
import { LOCATIONS_QUERY_KEY } from '../location/queryKeys'
import { CLIENTS_QUERY_KEY } from '../client/queryKeys'
import { SERVICES_QUERY_KEY } from '../service/queryKeys'
import { TRANSACTIONS_QUERY_KEY } from '../transaction/queryKeys'
import { UNITED_BOOKING_QUERY_KEY } from '../unitedBooking/queryKeys'
import { EmployeeToParse, parseDatesInEmployeeGqlResponse } from '../employee/logic'
import { parseDatesInTransactionGqlResponse, TransactionToParse } from '../transaction/logic'
import { ClientToParse, parseDatesInClientGqlResponse } from '../client/logic'
import { ServerBookingAsClientType, ServerCloudFunctionResult } from '../../generated/graphql-types'
import { InfiniteBookingsAsClient } from './infiniteQueries'
import { InfiniteData } from '@tanstack/react-query'

// Sync with BookingWarningsTypes in apps\firebase\functions\src\functions\validateBookingFunction
export const BookingWarningsTypes = {
  employeesNotWorking: 1,
  employeesAreBusy: 2,
  locationsAreFull: 3,
  locationsAreFullForEmployees: 4,
  clientHasBookings: 5,
  businessIsClosed: 6,
  consumables: 7,
} as const

// eslint-disable-next-line
export type WarningType = typeof BookingWarningsTypes[keyof typeof BookingWarningsTypes]

export const bookingByIdQueryKeys = [
  BOOKINGS_QUERY_KEY,
  EMPLOYEES_QUERY_KEY,
  LOCATIONS_QUERY_KEY,
  CLIENTS_QUERY_KEY,
  SERVICES_QUERY_KEY,
  TRANSACTIONS_QUERY_KEY,
]

const invalidateBookingById = (id: number) => {
  queryClient.invalidateQueries({
    predicate: query =>
      query.queryKey.includes(BOOKINGS_BY_IDS_QUERY_KEY) && query.queryKey.includes(id),
  })
  queryClient.invalidateQueries([...bookingByIdQueryKeys, id])
}

const invalidateBookingsByDay = (date: Date, branchId: number) => {
  const startDate = formatISO(startOfDay(date))
  const endDate = formatISO(endOfDay(date))
  const queryKey = [...bookingByIdQueryKeys, { branchId, startDate, endDate }]
  queryClient.invalidateQueries(queryKey)
}
const invalidateBookingsByRange = (dates: [Date, Date], branchId: number) => {
  const startDate = formatISO(startOfDay(dates[0]))
  const endDate = formatISO(endOfDay(dates[1]))
  const queryKey = [...bookingByIdQueryKeys, { branchId, startDate, endDate }]
  queryClient.invalidateQueries(queryKey)
}

export const invalidateBookings = ({
  date,
  oldDate,
  id,
  ids,
  branchId,
  dateRange,
}: {
  id?: number
  date?: Date
  oldDate?: Date
  ids?: Array<number>
  branchId: number
  dateRange?: [Date, Date]
}) => {
  if (id) invalidateBookingById(id)

  if (ids) {
    for (const bookingId of ids) {
      invalidateBookingById(bookingId)
    }
  }

  if (date) invalidateBookingsByDay(date, branchId)
  if (oldDate) invalidateBookingsByDay(oldDate, branchId)
  if (dateRange) invalidateBookingsByRange(dateRange, branchId)

  queryClient.invalidateQueries({
    predicate: query => query.queryKey.includes(UNITED_BOOKING_QUERY_KEY),
  })
}

export type BookingToParse = {
  startDate?: Date | string | null
  canceledDate?: Date | string | null
  createdAt?: Date | string | null
  client?: ClientToParse | null
  bookingClients?: { client: ClientToParse }[] | null
  employee?: EmployeeToParse | null
  transactions?: TransactionToParse[] | null
}
export const parseDatesInBookingGqlResponse = <T extends BookingToParse>(
  booking: T,
  timezone: string,
) => {
  if (booking.startDate) {
    booking.startDate = utcToZonedTime(booking.startDate, timezone)
  }
  if (booking.canceledDate) {
    booking.canceledDate = utcToZonedTime(booking.canceledDate, timezone)
  }
  if (booking.createdAt) {
    booking.createdAt = utcToZonedTime(booking.createdAt, timezone)
  }

  if (booking.client) {
    booking.client = parseDatesInClientGqlResponse(booking.client, timezone)
  }
  if (booking.bookingClients) {
    booking.bookingClients = booking.bookingClients.map(bookingClient => ({
      ...bookingClient,
      ...(bookingClient.client
        ? { client: parseDatesInClientGqlResponse(bookingClient.client, timezone) }
        : undefined),
    }))
  }

  if (booking.employee) {
    booking.employee = parseDatesInEmployeeGqlResponse(booking.employee, timezone)
  }
  if (booking.transactions) {
    booking.transactions = booking.transactions.map(transaction =>
      parseDatesInTransactionGqlResponse(transaction, timezone),
    )
  }

  return booking
}

export enum CreateBookingAnonymouslyResponseCodes {
  insufficientPermissions = 1,
  insufficientData,
  incorrectData,
  groupServiceError,
  clientAlreadyExistsInGroupService,
  successful,
  apiError,
}

export interface CreateBookingAnonymouslyResult extends ServerCloudFunctionResult {
  code: CreateBookingAnonymouslyResponseCodes
}

export const flatInfiniteBookings = (
  data: InfiniteData<InfiniteBookingsAsClient> | undefined,
): ServerBookingAsClientType[] => data?.pages?.flatMap(({ bookings }) => bookings) ?? []
