import { PLACEHOLDERS, validateEmail } from '@expane/logic/form'
import { checkValidPhone } from '@expane/logic/phone'
import { Button, CancelButton, Dialog, Input, Modal, usePopupOpenState } from '@expane/ui'
import { showPhoneErrorMessage } from 'logic/form'
import { FC, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import { IoCheckmarkDoneSharp } from 'react-icons/io5'
import { PhoneInput } from 'ui/PhoneInput'

interface PaymentMetadataDialogFormData {
  phone: string
  email: string
}

interface PaymentMetadataDialogProps {
  email: string | undefined
  phone: string | undefined
  onConfirmFunction: (data: PaymentMetadataDialogFormData) => void
  closePopup: () => void
}

export const useOpenPaymentMetadataDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const onConfirmFunction = useRef<(data: PaymentMetadataDialogFormData) => void>()
  const emailRef = useRef<string>()
  const phoneRef = useRef<string>()

  const showPaymentMetadataDialog = ({
    onConfirm,
    email,
    phone,
  }: {
    onConfirm: (data: PaymentMetadataDialogFormData) => void
    email?: string | null
    phone?: string | null
  }) => {
    emailRef.current = email ?? ''
    phoneRef.current = phone ?? ''
    onConfirmFunction.current = onConfirm

    openPopup()
  }

  const paymentMetadataDialog =
    isOpen && onConfirmFunction.current ? (
      <PaymentMetadataDialog
        phone={phoneRef.current}
        email={emailRef.current}
        closePopup={closePopup}
        onConfirmFunction={onConfirmFunction.current}
      />
    ) : null

  return {
    paymentMetadataDialog,
    showPaymentMetadataDialog,
  }
}

const PaymentMetadataDialog: FC<PaymentMetadataDialogProps> = ({
  onConfirmFunction,
  closePopup,
  email,
  phone,
}) => {
  const { t } = useTranslation()

  const { handleSubmit, control } = useForm<PaymentMetadataDialogFormData>({
    defaultValues: { phone, email },
  })

  const confirmAction: SubmitHandler<PaymentMetadataDialogFormData> = data => {
    onConfirmFunction(data)

    closePopup()
  }

  return (
    <Modal close={closePopup} confirm={handleSubmit(confirmAction)}>
      <Dialog>
        <Dialog.Title>{t('paymentMetadata.title')}</Dialog.Title>

        <Dialog.Body className="w-128">
          <p className="text-main-color mb-1">{t('paymentMetadata.description')}</p>

          <Controller
            name="phone"
            control={control}
            rules={{ required: true, validate: checkValidPhone }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <PhoneInput
                label={t('phone')}
                required
                placeholder={PLACEHOLDERS.phone}
                errorMessage={{
                  isShown: Boolean(error),
                  text: showPhoneErrorMessage(error?.type ?? '', t),
                }}
                value={value}
                onChange={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            rules={{
              required: true,
              validate: {
                validateEmail,
              },
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label={t('email')}
                placeholder={t('placeholders.email')}
                required
                errorMessage={{
                  isShown: Boolean(error),
                  text: generateErrorMessage(error?.type, t),
                }}
                value={value}
                onChange={onChange}
              />
            )}
          />

          <p className="text-secondary-color text-sm">{t('paymentMetadata.autoFillNotice')}</p>
        </Dialog.Body>
        <Dialog.Footer>
          <Button Icon={IoCheckmarkDoneSharp} onClick={handleSubmit(confirmAction)}>
            {t('submit')}
          </Button>
          <CancelButton onClick={closePopup} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

// TODO: Знайти всі схожі та винести в ложік
const generateErrorMessage = (type: string | undefined, t: TFunction): string | undefined => {
  if (type === 'required') return t('formError.required')
  if (type === 'validateEmail') return t('authForm.wrongEmail')
}
