import {
  useFetchCurrentBranchTimezone,
  useFetchExtendedEmployees,
  useFetchLocations,
} from '@expane/data'
import { SelectDropdown } from '@expane/ui'
import { transformPersonsForSelect } from 'logic/utils'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm, UseFormReturn, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { BookingMultiServicesDialogFormValues } from 'widgets/BookingMultiServicesDialog/index'
import { ApplyButton } from 'widgets/Buttons'

interface QuickFixMultiServices {
  form: UseFormReturn<BookingMultiServicesDialogFormValues>
}

export const QuickFixMultiServices: FC<QuickFixMultiServices> = ({ form }) => {
  const { setValue, resetField, control } = form

  const { control: quickFixControl, handleSubmit: quickFixHandleSubmit } =
    useForm<QuickFixMultiServicesForm>()

  const { t } = useTranslation()

  const branchId = store.branch.branchId
  const { data: locations } = useFetchLocations(branchId)
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: employees } = useFetchExtendedEmployees(timezone, branchId)

  const employeesForSelect = employees ? transformPersonsForSelect(employees) : []

  const watchedMultiServices = useWatch({ control, name: `multiServicesDto` })

  const handleApply: SubmitHandler<QuickFixMultiServicesForm> = ({ locationId, employeeId }) => {
    const multiServices = watchedMultiServices.map(item => {
      return {
        ...item,
        locationId: locationId ?? item.locationId,
        employeeId: employeeId ?? item.employeeId,
      }
    })

    resetField('multiServicesDto')
    setValue('multiServicesDto', multiServices, { shouldDirty: true })
  }

  return (
    <>
      <Controller
        control={quickFixControl}
        name="locationId"
        render={({ field: { value, onChange } }) => (
          <SelectDropdown
            label={t('location.name')}
            value={value}
            items={locations}
            placeholder={t('placeholders.defaultSelect')}
            noDataMessage={t('noLocation')}
            onSelectChange={onChange}
            className="w-1/3 pr-2"
          />
        )}
      />

      <Controller
        control={quickFixControl}
        name="employeeId"
        render={({ field: { value, onChange } }) => (
          <SelectDropdown
            label={t('employee.name')}
            value={value}
            items={employeesForSelect}
            placeholder={t('placeholders.defaultSelect')}
            className="w-1/3 pr-2"
            noDataMessage={t('noSuchEmployee')}
            onSelectChange={onChange}
            popupHeight="small"
            isClearable
          />
        )}
      />

      <ApplyButton
        type="outline"
        onClick={quickFixHandleSubmit(handleApply)}
        className="self-center"
      />
    </>
  )
}

interface QuickFixMultiServicesForm {
  locationId: number | undefined
  employeeId: number | undefined
}
