import React, { FC } from 'react'
import { IMAGE_SIZE, LandingSettingsBlockProps } from '../logic'
import { BlockWrapper } from './BlockWrapper'
import { Input, Textarea } from '@expane/ui'
import { EditablePhoto } from 'widgets/EditablePhoto'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'

export const MainBanner: FC<LandingSettingsBlockProps> = ({ control, disabled }) => {
  const { t } = useTranslation()

  return (
    <BlockWrapper
      label={t('businessLandingSettings.mainBanner')}
      className="flex gap-4 items-center"
    >
      <div className="grow">
        <Controller
          name="bannerTitle"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              label={t('businessLandingSettings.bannerTitle')}
              required
              value={value}
              onChange={onChange}
              disabled={disabled}
              errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
            />
          )}
        />

        <Controller
          name="bannerDescription"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Textarea
              placeholder=""
              required
              label={t('businessLandingSettings.bannerDescription')}
              value={value}
              onChange={onChange}
              disabled={disabled}
              errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
            />
          )}
        />
      </div>

      <Controller
        name="bannerImage"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <EditablePhoto
            defaultPhoto={value}
            onChange={onChange}
            size="xl"
            cropShape="rect"
            disabled={disabled}
            errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
            croppedSize={IMAGE_SIZE}
          />
        )}
      />
    </BlockWrapper>
  )
}
