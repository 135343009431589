import { PromoCodeWidgetProps, usePromoCodeApplication } from '@expane/logic/billing/promoCode'
import { SpinnerSmall, stopEnterPropagation } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoSaveOutline } from 'react-icons/io5'

export const PromoCode: FC<PromoCodeWidgetProps> = ({ onCheck, watchedPromoCode }) => {
  const { t } = useTranslation()

  const [openSnackbar] = useSnackbar()

  const { control, formState, onSubmit } = usePromoCodeApplication({
    watchedPromoCode,
    onCheck,
    onSuccessMessage: message => openSnackbar(message, 'success'),
    onErrorMessage: message => openSnackbar(message, 'error'),
    t,
  })

  let containerClassName = 'flex items-center border-2 rounded-lg p-1 '
  containerClassName += watchedPromoCode
    ? 'border-primary-400 bg-accent'
    : 'border-gray-100 dark:border-gray-500'

  let buttonStyles =
    'h-9.5 px-4 w-40 rounded-r-md border-2 font-medium focus:outline-none ring-btn transition-all inline-flex items-center justify-center shadow-sm border-transparent text-btn-primary '
  buttonStyles +=
    !formState.isDirty || formState.isSubmitting
      ? 'cursor-default bg-btn-disabled-primary'
      : 'bg-btn-primary focus:ring-primary-400'

  return (
    <div className={containerClassName}>
      <Controller
        name={'promoCode'}
        control={control}
        render={({ field, fieldState: { error } }) => {
          let inputStyles =
            'block grow border-2 rounded-l-lg px-3 text-sm focus:ring-0 transition-all  text-main-color placeholder-color h-9.5 bg-input '
          inputStyles += Boolean(error) ? 'border-input-error-color' : 'border-input-color'

          return (
            <input
              placeholder={t('promoCodePlaceholder')}
              className={inputStyles}
              autoComplete="off"
              {...field}
            />
          )
        }}
      />

      <button
        className={buttonStyles}
        disabled={!formState.isDirty || formState.isSubmitting}
        onClick={onSubmit}
        onKeyDown={stopEnterPropagation}
      >
        <IoSaveOutline size="1.5rem" className="shrink-0 mr-1" />
        {t('save')}
        {formState.isSubmitting && <SpinnerSmall className="ml-1" />}
      </button>
    </div>
  )
}
