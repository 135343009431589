import { enUS, format as formatDate, isDate, ru, uk } from '@expane/date'
import jointEnFile from '@expane/i18n/translations/en.json'
import jointRuFile from '@expane/i18n/translations/ru.json'
import jointUkFile from '@expane/i18n/translations/uk.json'
import { safeWebLocalStorage } from '@expane/logic/safeWebLocalStorage'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import enFile from './en.json'
import ruFile from './ru.json'
import ukFile from './uk.json'

export const DEFAULT_LANGUAGE = 'uk'

export const LANGUAGE_LOCAL_STORAGE_KEY = 'language'
export const getPreferredUserLanguage = (): string => {
  const language = safeWebLocalStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY)

  if (!language) {
    const allowedLanguages = ['en', 'uk', 'ru']
    const navigatorLanguage = window.navigator.language.slice(0, 2)

    if (allowedLanguages.includes(navigatorLanguage)) {
      safeWebLocalStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, navigatorLanguage)
      return navigatorLanguage
    }

    // Ukrainian as default
    safeWebLocalStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, DEFAULT_LANGUAGE)
    return DEFAULT_LANGUAGE
  }

  return language
}

const localLanguage = getPreferredUserLanguage()

const locales = { en: enUS, uk, ru }

i18next.use(initReactI18next).init({
  resources: {
    uk: { translation: { ...ukFile, ...jointUkFile } },
    ru: { translation: { ...ruFile, ...jointRuFile } },
    en: { translation: { ...enFile, ...jointEnFile } },
  },
  lng: localLanguage,
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      if (isDate(value)) {
        const locale = locales[lng ?? DEFAULT_LANGUAGE]
        return formatDate(value, format ?? '', { locale })
      }
      return value
    },
  },
  fallbackLng: ['en', 'uk', 'ru'],
})

export default i18next
