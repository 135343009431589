import { queryClient, request, useMutation, useQuery, reportError, reportGqlError } from '../../api'
import {
  ServerServiceGroupInsertInput,
  ServerServiceGroupSetInput,
  ServerServiceGroupType,
} from '../../generated/graphql-types'
import { gql } from 'graphql-request'
import { serviceGroupFragment } from './serviceGroup.fragments'
import { DEACTIVATED_SERVICE_GROUPS_QUERY_KEY, SERVICE_GROUPS_QUERY_KEY } from './queryKeys'

export function useFetchServiceGroups(branchId: number | undefined | null) {
  return useQuery(
    [SERVICE_GROUPS_QUERY_KEY, { branchId }],
    async (): Promise<ServerServiceGroupType[]> => {
      const result = await request(
        gql`
          ${serviceGroupFragment}
          query ($branchId: Int!) {
            serviceGroups(
              order_by: { createdAt: desc }
              where: { branchId: { _eq: $branchId }, archived: { _is_null: true } }
            ) {
              ...serviceGroupType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.serviceGroups)) {
        return result.serviceGroups
      } else {
        reportError(new Error('serviceGroups is not an array'), 'warning', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchServiceGroups',
      onError: reportGqlError,
      onSuccess: serviceGroups =>
        serviceGroups.forEach(serviceGroup =>
          queryClient.setQueryData([SERVICE_GROUPS_QUERY_KEY, serviceGroup.id], serviceGroup),
        ),
    },
  )
}

export function useFetchDeactivatedServiceGroups(branchId: number | undefined) {
  return useQuery(
    [DEACTIVATED_SERVICE_GROUPS_QUERY_KEY, { branchId }],
    async (): Promise<ServerServiceGroupType[]> => {
      const result = await request(
        gql`
          ${serviceGroupFragment}
          query ($branchId: Int!) {
            serviceGroups(
              order_by: { createdAt: desc }
              where: { branchId: { _eq: $branchId }, archived: { _is_null: false } }
            ) {
              ...serviceGroupType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.serviceGroups)) {
        return result.serviceGroups
      } else {
        reportError(new Error('deactivatedServiceGroups is not an array'), 'warning', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId),
      queryName: 'useFetchDeactivatedServiceGroups',
      onError: reportGqlError,
      onSuccess: serviceGroups =>
        serviceGroups.forEach(serviceGroup =>
          queryClient.setQueryData(
            [DEACTIVATED_SERVICE_GROUPS_QUERY_KEY, serviceGroup.id],
            serviceGroup,
          ),
        ),
    },
  )
}

export function useFetchServiceGroupById(id: number | undefined) {
  return useQuery<ServerServiceGroupType | undefined>(
    [SERVICE_GROUPS_QUERY_KEY, DEACTIVATED_SERVICE_GROUPS_QUERY_KEY, id],
    async () => {
      const result = await request(
        gql`
          ${serviceGroupFragment}
          query ($id: Int!) {
            serviceGroupById(id: $id) {
              ...serviceGroupType
            }
          }
        `,
        { id },
      )

      if (result?.serviceGroupById) {
        return result.serviceGroupById
      }

      reportError(new Error('serviceGroupById does not exist'), 'warning', { id, result })
      return undefined
    },
    { queryName: 'useFetchServiceGroupById', enabled: Boolean(id), onError: reportGqlError },
  )
}

type ServiceGroupInsertInput = ServerServiceGroupInsertInput &
  Required<Pick<ServerServiceGroupInsertInput, 'branchId' | 'name'>>

export function useCreateServiceGroup() {
  return useMutation(
    async (
      serviceGroupInsertInput: ServiceGroupInsertInput &
        Required<Pick<ServerServiceGroupInsertInput, 'branchId' | 'name'>>,
    ): Promise<{ insertServiceGroup?: { id: number; name: string } }> => {
      return request(
        gql`
          mutation ($serviceGroupInsertInput: serviceGroup_insert_input!) {
            insertServiceGroup(object: $serviceGroupInsertInput) {
              id
              name
            }
          }
        `,
        { serviceGroupInsertInput },
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([SERVICE_GROUPS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useUpdateServiceGroup() {
  return useMutation(
    async (dto: {
      id: number
      serviceGroupSetInput: ServerServiceGroupSetInput
    }): Promise<{ updateServiceGroupById?: { id?: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $serviceGroupSetInput: serviceGroup_set_input!) {
            updateServiceGroupById(pk_columns: { id: $id }, _set: $serviceGroupSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => queryClient.invalidateQueries([SERVICE_GROUPS_QUERY_KEY]),
      onError: reportGqlError,
    },
  )
}

export function useArchiveServiceGroup() {
  return useMutation(
    async (dto: {
      id: number
      archived: boolean
      // eslint-disable-next-line camelcase
    }): Promise<{ updateServiceGroupArchives: { affected_rows: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $archived: timestamptz) {
            updateServiceGroupArchives(where: { id: { _eq: $id } }, _set: { archived: $archived }) {
              affected_rows
            }
          }
        `,
        { id: dto.id, archived: dto.archived ? new Date().toISOString() : null },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([DEACTIVATED_SERVICE_GROUPS_QUERY_KEY])
        queryClient.invalidateQueries([SERVICE_GROUPS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
