import { ClientBriefWithTagType } from '@expane/data'
import { ClientWithPhones } from '@expane/logic/client'
import { transformPersonName } from '@expane/logic/utils'
import { Table, Tag } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { config } from 'config'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC, PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoPeopleOutline, IoSendSharp } from 'react-icons/io5'
import { Avatar } from 'ui/Avatar'
import { Phone } from 'ui/Phone'
import { ClientDialog } from 'widgets/ClientDialog'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'
import { useOpenSendMessageDialog } from 'widgets/SendMessageDialog'

export const ClientsPageList: FC<{
  clientsForPage: ClientWithPhones<ClientBriefWithTagType>[]
  isLoading: boolean
  isSendMessageAllowed: boolean
  isViewPhonesAllowed: boolean
  PrintWrapper: FC<
    PropsWithChildren<{ className?: string; onBeforePrint?: () => void; onAfterPrint?: () => void }>
  >
}> = ({ clientsForPage, isLoading, PrintWrapper, isSendMessageAllowed, isViewPhonesAllowed }) => {
  const { dialog: SendMessageDialog, openDialog } = useOpenSendMessageDialog()
  const { dialog: EditClientDialog, openEditDialog } = useOpenDialog(ClientDialog)

  const { t } = useTranslation()

  const columns = useMemo(() => {
    const columns: ColumnDef<ClientWithPhones<ClientBriefWithTagType>>[] = [
      {
        id: 'fullName',
        accessorFn: client => transformPersonName(client),
        header: t('client.name'),
        cell: data => (
          <div className="flex items-center">
            <Avatar
              url={
                data.row.original.photo?.length
                  ? data.row.original.photo
                  : config.PHOTO_PLACEHOLDER_URL
              }
              name={data.getValue<string>()}
              className="mr-2"
            />
            <p>{data.getValue<string>()}</p>
            {data.row.original.clientTags.map(({ tag }) => (
              <Tag key={tag.id} name={tag.name} color={tag.color} className="ml-1" />
            ))}
          </div>
        ),
        size: 400,
      },
    ]
    if (isViewPhonesAllowed)
      columns.push({
        accessorKey: 'phone',
        header: t('phone'),
        cell: data => {
          const clientPreferredConnections = data.row.original.clientSettings.preferredConnections

          const showSendMessageButton = clientPreferredConnections
            ? Object.values(clientPreferredConnections).some(connection => connection) &&
              isSendMessageAllowed
            : false
          return (
            <div className="flex">
              {showSendMessageButton && (
                <button
                  className="flex-centered text-icon-color mr-1 hover:text-primary-700 w-5 h-5 rounded-full bg-icon"
                  onClick={e => {
                    e.stopPropagation()
                    openDialog(data.row.original.id)
                  }}
                >
                  <IoSendSharp className="ml-0.5" size="0.75rem" />
                </button>
              )}
              <Phone number={data.getValue<string>()} />
            </div>
          )
        },
        size: 300,
      })

    return columns
  }, [isSendMessageAllowed, isViewPhonesAllowed, openDialog, t])

  if (clientsForPage.length === 0 && !isLoading)
    return <EmptyPlaceholder Icon={IoPeopleOutline} text={t('emptyPlaceholder.archive')} />

  return (
    <>
      <PrintWrapper className="print:w-256 print:h-max h-full">
        <Table
          containerClassName="max-h-full"
          columns={columns}
          data={clientsForPage}
          isLoading={isLoading}
          onRowClick={client => openEditDialog(client.id)}
        />
      </PrintWrapper>
      {SendMessageDialog}
      {EditClientDialog}
    </>
  )
}
