import { NotificationUnionType } from '@expane/data'
import { Spinner } from '@expane/ui'
import { useWindowSize } from 'logic/hooks/useWindowSize'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'
import { SwitchTypes } from 'widgets/NotificationDialog/index'
import { NotificationItem } from 'widgets/NotificationDialog/Item'

interface NotificationListProps {
  notifications: NotificationUnionType[]
  openBookingDialog?: (number) => void
  currentSwitchTab: SwitchTypes
  setNotifications: Dispatch<SetStateAction<number[]>>
  selectedNotifications: number[]
  setObservableRef: Dispatch<SetStateAction<HTMLDivElement | null>>
  isFetchingNextPage: boolean
  timezone: string
}

export const NotificationList: FC<NotificationListProps> = ({
  notifications,
  currentSwitchTab,
  setNotifications,
  selectedNotifications,
  setObservableRef,
  isFetchingNextPage,
  openBookingDialog,
  timezone,
}) => {
  const { t } = useTranslation()

  const { height } = useWindowSize()

  const [ulElement, setUlElement] = useState<HTMLUListElement | null>()
  const listHeight = height - (currentSwitchTab === 'notDone' ? NOT_DONE_OFFSET : OFFSET)
  const style = {
    ...((ulElement?.offsetHeight ?? 0) > listHeight ? { height: listHeight + 'px' } : undefined),
  }

  return (
    <div className="overflow-y-auto" style={style}>
      {notifications.length === 0 ? (
        <EmptyPlaceholder text={t('noNotifications')} size="small" />
      ) : (
        <>
          <ul ref={setUlElement}>
            {notifications.map(item => (
              <NotificationItem
                key={item.id}
                timezone={timezone}
                item={item}
                setNotifications={setNotifications}
                selectedNotifications={selectedNotifications}
                openBookingDialog={openBookingDialog}
              />
            ))}
          </ul>

          <div className="w-full h-0.5" ref={setObservableRef} />

          {isFetchingNextPage && (
            <div className="p-2 flex justify-center">
              <Spinner />
            </div>
          )}
        </>
      )}
    </div>
  )
}

// висота топбара + заголовок блока сообщений + падинги
const NOT_DONE_OFFSET = 200
const OFFSET = 150
