import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import {
  convertTransactionsToCardReportItem,
  getGiftCardReportItems,
  getNotRefundedReportItems,
} from '@expane/logic/reports/cards'
import {
  calcReportItemsCreditTotalPrice,
  calcReportItemsCreditTotalQuantity,
  calcReportItemsTotalPrice,
  groupReportItemsById,
} from '@expane/logic/reports/logic'
import {
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderCell,
} from '@expane/ui'
import { translateTableTitles } from 'logic/utils'
import { ReportProps } from 'pages/ReportsPage'
import {
  CardReportItem,
  cardReportItemsTitles,
} from 'pages/ReportsPage/CardsReports/CardReportItem'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

export const GiftCardsReport: FC<PropsWithBranchId<ReportProps>> = ({ transactions, branchId }) => {
  const { t } = useTranslation()

  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const reportItems = convertTransactionsToCardReportItem(transactions)
  const giftCardReportItems = getGiftCardReportItems(getNotRefundedReportItems(reportItems))

  const groupedGiftCards = groupReportItemsById(giftCardReportItems)

  const creditTotalQuantity = calcReportItemsCreditTotalQuantity(giftCardReportItems)
  const creditTotalPrice = calcReportItemsCreditTotalPrice(giftCardReportItems)
  const totalPrice = calcReportItemsTotalPrice(giftCardReportItems)

  const giftCardsQuantity = giftCardReportItems.length
  const giftCardsToDisplay = Object.keys(groupedGiftCards)

  if (giftCardsToDisplay.length === 0)
    return <EmptyPlaceholder text={t('emptyPlaceholder.archive')} />

  return (
    <TableContainer>
      <TableHeader>
        <tr>
          {translateTableTitles(cardReportItemsTitles, t).map(({ title, isAlignRight }) => {
            const className = isAlignRight ? 'text-right' : undefined
            return (
              <TableHeaderCell key={title} className={className}>
                {title}
              </TableHeaderCell>
            )
          })}
        </tr>
      </TableHeader>
      <TableBody>
        {giftCardsToDisplay.map(groupId => (
          <CardReportItem
            key={groupId}
            cardReportItems={groupedGiftCards[groupId]}
            name={giftCardReportItems.find(item => item.id === Number(groupId))?.name ?? ''}
            branchId={branchId}
          />
        ))}
      </TableBody>
      <TableFooter>
        <TableCell>{t('total')}</TableCell>
        <TableCell className="text-right">{giftCardsQuantity}</TableCell>
        <TableCell className="text-right">{creditTotalQuantity}</TableCell>
        <TableCell className="text-right">{convertNumberToMoney(totalPrice)}</TableCell>
        <TableCell className="text-right">{convertNumberToMoney(creditTotalPrice)}</TableCell>
      </TableFooter>
    </TableContainer>
  )
}
