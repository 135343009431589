import { stopEnterPropagation } from '@expane/ui'
import { FC } from 'react'
import { IoAddCircleOutline } from 'react-icons/io5'

type SettingTitleProps = {
  title: string
  description: string
  onPlusClick?: () => void
}

export const SettingTitle: FC<SettingTitleProps> = ({ title, description, onPlusClick }) => {
  return (
    <div className="mb-4 pb-4 border-b border-gray-200 dark:border-gray-600 max-w-full">
      <div className="flex items-center gap-1">
        <h1 className="text-lg text-gray-500 dark:text-gray-300 font-medium">{title}</h1>
        {onPlusClick && (
          <button
            onKeyDown={stopEnterPropagation}
            onClick={onPlusClick}
            className="text-primary-500 hover:text-primary-700 hover:dark:text-primary-600 rounded-full focus:ring-1 ring-primary-500"
          >
            <IoAddCircleOutline size="1.6rem" />
          </button>
        )}
      </div>

      <h2 className="text-gray-400">{description}</h2>
    </div>
  )
}
