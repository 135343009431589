import { gql } from 'graphql-request'
import { DEFAULT_TIMEZONE, utcToZonedTime } from '@expane/date'
import { queryClient, reportError, reportGqlError, request, useQuery } from '../../api'
import { ServerClientBriefType } from '../../generated/graphql-types'
import {
  CLIENT_BALANCE_QUERY_KEY,
  clientByIdQueryKeys,
  CLIENTS_AMOUNT_QUERY_KEY,
  CLIENTS_BRIEFS_QUERY_KEY,
  CLIENTS_PHONE_QUERY_KEY,
  CLIENTS_QUERY_KEY,
  DEACTIVATED_CLIENTS_QUERY_KEY,
  LAST_VISIT_QUERY_KEY,
  MY_CLIENT_QUERY_KEY,
} from './queryKeys'
import { CARDS_QUERY_KEY, SUBSCRIPTIONS_QUERY_KEY } from '../card/queryKeys'
import {
  clientBriefFragment,
  clientBriefsAndCardsFragment,
  clientBriefWithTagFragment,
  clientByIdFragment,
  clientWithSubscriptionsFragment,
  myClientFragment,
} from './client.fragments'
import { TAGS_QUERY_KEY } from '../tag/queryKeys'
import {
  ClientBriefsAndCardsType,
  ClientBriefWithTagType,
  ClientByIdType,
  ClientByIdWithSubscriptions,
  ClientPhones,
  MyClientType,
  parseDatesInClientGqlResponse,
} from './logic'
import { TRANSACTIONS_QUERY_KEY } from '../transaction/queryKeys'

// Fetch only client that have visited the branch
export function useFetchClients(timezone: string | undefined, branchId: number | undefined) {
  return useQuery(
    [CLIENTS_QUERY_KEY, TAGS_QUERY_KEY, { branchId, timezone }],
    async (): Promise<ClientBriefWithTagType[]> => {
      const result = await request(
        gql`
          ${clientBriefWithTagFragment}
          query ($branchId: Int!) {
            clients(
              order_by: { createdAt: desc }
              where: {
                _or: [
                  { transactions: { branchId: { _eq: $branchId } } }
                  { bookings: { branchId: { _eq: $branchId } } }
                  { groupBookingClients: { booking: { branchId: { _eq: $branchId } } } }
                  { initialBranchId: { _eq: $branchId } }
                ]
                _not: { archivedInBranches: { branchId: { _eq: $branchId } } }
              }
            ) {
              ...clientBriefWithTagType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.clients)) {
        return result.clients.map(client =>
          parseDatesInClientGqlResponse(client, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('clients is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchClients',
      onSuccess: clients =>
        queryClient.setQueryData([CLIENTS_AMOUNT_QUERY_KEY], () => clients.length),
      onError: reportGqlError,
      enabled: Boolean(timezone),
    },
  )
}

export function useFetchDeactivatedClients(branchId: number | undefined) {
  return useQuery(
    [DEACTIVATED_CLIENTS_QUERY_KEY, { branchId }],
    async (): Promise<ClientBriefWithTagType[]> => {
      const result = await request(
        gql`
          ${clientBriefWithTagFragment}
          query ($branchId: Int!) {
            clients(
              order_by: { createdAt: desc }
              where: { archivedInBranches: { branchId: { _eq: $branchId } } }
            ) {
              ...clientBriefWithTagType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.clients)) {
        return result.clients
      }
      reportError(new Error('clients is not an array'), 'warning', { result })
      return []
    },
    {
      queryName: 'useFetchDeactivatedClients',
      onError: reportGqlError,
      enabled: Boolean(branchId),
    },
  )
}

// Fetch only client that have visited the branch
// Pass id of client that can't be in list (doesn't have bookings/transactions yet)
/**
 * @param branchId - id of branch to fetch clients
 * @param clientId - id or ids of client that can't be in list yet
 */
export function useFetchClientsBriefs(
  branchId: number | undefined,
  timezone: string | undefined,
  clientId?: number | number[] | null,
) {
  return useQuery(
    [CLIENTS_QUERY_KEY, CLIENTS_BRIEFS_QUERY_KEY, { branchId, clientId, timezone }],
    async (): Promise<ServerClientBriefType[]> => {
      let clientIds: number[] | undefined = undefined
      if (clientId) {
        clientIds = Array.isArray(clientId) ? clientId : [clientId]
      }

      const result = await request(
        gql`
          ${clientBriefFragment}
          query ($branchId: Int!, $clientIds: [Int!] = [0]) {
            clients(
              order_by: { createdAt: desc }
              where: {
                _or: [
                  { transactions: { branchId: { _eq: $branchId } } }
                  { bookings: { branchId: { _eq: $branchId } } }
                  { groupBookingClients: { booking: { branchId: { _eq: $branchId } } } }
                  { initialBranchId: { _eq: $branchId } }
                  { id: { _in: $clientIds } }
                ]
                _not: { archivedInBranches: { branchId: { _eq: $branchId } } }
              }
            ) {
              ...clientBriefType
            }
          }
        `,
        { branchId, clientIds },
      )

      if (Array.isArray(result?.clients)) {
        return result.clients.map(client =>
          parseDatesInClientGqlResponse(client, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('clients is not an array'), 'warning', { result })
        return []
      }
    },
    {
      enabled: Boolean(branchId) && Boolean(timezone),
      queryName: 'useFetchClientsBriefs',
      onError: reportGqlError,
    },
  )
}

export function useFetchClientsWithFullNameFuzzSearch(
  prompt: string,
  branchId: number | undefined,
  timezone: string | undefined,
) {
  return useQuery(
    [CLIENTS_QUERY_KEY, CLIENTS_BRIEFS_QUERY_KEY, { prompt, branchId, timezone }],
    async (): Promise<ServerClientBriefType[]> => {
      try {
        const result = await request(
          gql`
            ${clientBriefFragment}
            query ($prompt: String!, $branchId: Int!) {
              clients(
                limit: 6
                where: {
                  _or: [
                    { firstName: { _ilike: $prompt } }
                    { lastName: { _ilike: $prompt } }
                    { phone: { _ilike: $prompt } }
                  ]
                  _not: { archivedInBranches: { branchId: { _eq: $branchId } } }
                }
              ) {
                ...clientBriefType
              }
            }
          `,
          {
            prompt: `%${prompt}%`,
            branchId,
          },
        )

        return result.clients.map(client =>
          parseDatesInClientGqlResponse(client, timezone ?? DEFAULT_TIMEZONE),
        )
      } catch (error) {
        reportError(error, 'error', { prompt })
        return []
      }
    },
    {
      queryName: 'useFetchClientsWithFullNameFuzzSearch',
      onSuccess: clients =>
        queryClient.setQueryData([CLIENTS_AMOUNT_QUERY_KEY], () => clients.length),
      onError: reportGqlError,
      enabled: Boolean(prompt) && Boolean(branchId) && Boolean(timezone),
    },
  )
}

export function useFetchClientsBriefsAndCards(
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery(
    [CLIENTS_QUERY_KEY, CLIENTS_BRIEFS_QUERY_KEY, CARDS_QUERY_KEY, { branchId, timezone }],
    async (): Promise<ClientBriefsAndCardsType[]> => {
      const result = await request(
        gql`
          ${clientBriefsAndCardsFragment}
          query ($branchId: Int!) {
            clients(
              order_by: { createdAt: desc }
              where: {
                _or: [
                  { transactions: { branchId: { _eq: $branchId } } }
                  { bookings: { branchId: { _eq: $branchId } } }
                  { groupBookingClients: { booking: { branchId: { _eq: $branchId } } } }
                  { initialBranchId: { _eq: $branchId } }
                ]
                _not: { archivedInBranches: { branchId: { _eq: $branchId } } }
              }
            ) {
              ...clientBriefsAndCardsType
            }
          }
        `,
        { branchId },
      )

      if (Array.isArray(result?.clients)) {
        return result.clients.map(client =>
          parseDatesInClientGqlResponse(client, timezone ?? DEFAULT_TIMEZONE),
        )
      } else {
        reportError(new Error('clientsBriefsAndCards is not an array'), 'warning', { result })
        return []
      }
    },
    {
      queryName: 'useFetchClientsBriefsAndCards',
      onError: reportGqlError,
      onSuccess: clients =>
        clients.forEach(client =>
          queryClient.setQueryData(
            [CLIENTS_QUERY_KEY, CLIENTS_BRIEFS_QUERY_KEY, CARDS_QUERY_KEY, client.id],
            client,
          ),
        ),
      enabled: Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchMyClientById(
  clientId: number | null,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<MyClientType | undefined>(
    [MY_CLIENT_QUERY_KEY, clientId, { branchId, clientId, timezone }],
    async () => {
      let result

      try {
        result = await request(
          gql`
            ${myClientFragment}
            query ($clientId: Int!, $branchId: Int!) {
              clientById(id: $clientId) {
                ...myClientType
              }
            }
          `,
          { clientId, branchId },
        )
      } catch (e) {
        reportGqlError(e)
      }

      if (result?.clientById) {
        return parseDatesInClientGqlResponse(result.clientById, timezone ?? DEFAULT_TIMEZONE)
      }

      reportError(new Error('Error while trying to get client by id'), 'error', {
        clientId,
        result,
      })
    },
    {
      queryName: 'useFetchMyClient',
      onError: reportGqlError,
      enabled: Boolean(clientId) && Boolean(timezone) && Boolean(branchId),
    },
  )
}
export function useFetchClientById(id: number | undefined | null, timezone: string | undefined) {
  return useQuery(
    clientByIdQueryKeys(id),
    async (): Promise<ClientByIdType | undefined> => {
      if (id === 0 || id === undefined) return undefined
      const dto = { id }
      const result = await request(
        gql`
          ${clientByIdFragment}
          query ($id: Int!) {
            clientById(id: $id) {
              ...clientByIdType
            }
          }
        `,
        dto,
      )

      if (result?.clientById) {
        return parseDatesInClientGqlResponse(result.clientById, timezone ?? DEFAULT_TIMEZONE)
      } else {
        reportError(new Error('clientById does not exist'), 'error', { dto, result })
        return undefined
      }
    },
    {
      queryName: 'useFetchClientById',
      onError: reportGqlError,
      enabled: Boolean(id) && Boolean(timezone),
    },
  )
}

export function useFetchClientByIdWithSubscriptions(
  clientId: number | undefined,
  timezone: string | undefined,
  branchId: number | undefined,
) {
  return useQuery<ClientByIdWithSubscriptions | undefined>(
    [CLIENTS_QUERY_KEY, SUBSCRIPTIONS_QUERY_KEY, { clientId, branchId, timezone }],
    async () => {
      let result

      try {
        result = await request(
          gql`
            ${clientWithSubscriptionsFragment}
            query ($clientId: Int!, $branchId: Int!) {
              clientById(id: $clientId) {
                ...clientWithSubscriptionsType
              }
            }
          `,
          { clientId, branchId },
        )
      } catch (e) {
        reportGqlError(e)
      }

      if (result?.clientById) {
        return parseDatesInClientGqlResponse(result.clientById, timezone ?? DEFAULT_TIMEZONE)
      }

      reportError(new Error('Error while trying to get client by id'), 'error', {
        clientId,
        result,
      })
    },
    {
      queryName: 'useFetchClientByIdWithSubscriptions',
      onError: reportGqlError,
      enabled: Boolean(clientId) && Boolean(timezone) && Boolean(branchId),
    },
  )
}

export function useFetchClientsPhones() {
  return useQuery<ClientPhones[]>(
    [CLIENTS_QUERY_KEY, CLIENTS_PHONE_QUERY_KEY],
    async () => {
      let result

      try {
        result = await request(
          gql`
            query {
              clientPhoneView {
                id
                phone
                altPhone
              }
            }
          `,
        )
      } catch (e) {
        reportGqlError(e)
      }

      if (Array.isArray(result?.clientPhoneView)) {
        return result.clientPhoneView
      }
    },
    {
      queryName: 'useFetchClientsPhones',
      onError: reportGqlError,
      onSuccess: data => {
        for (const clientPhones of data) {
          queryClient.setQueryData(
            [CLIENTS_QUERY_KEY, CLIENTS_PHONE_QUERY_KEY, { clientId: clientPhones.id }],
            { ...clientPhones },
          )
        }
      },
    },
  )
}

export function useFetchClientsPhonesByClientId(clientId?: number) {
  return useQuery<ClientPhones>(
    [CLIENTS_QUERY_KEY, CLIENTS_PHONE_QUERY_KEY, { clientId }],
    async () => {
      let result
      try {
        result = await request(
          gql`
            query ($clientId: Int!) {
              clientPhoneView(where: { id: { _eq: $clientId } }) {
                id
                phone
                altPhone
              }
            }
          `,
          { clientId },
        )
      } catch (e) {
        reportGqlError(e)
      }

      if (
        Array.isArray(result?.clientPhoneView) &&
        // If there is no required access, returns an empty array
        result.clientPhoneView.length
      ) {
        return result.clientPhoneView[0]
      } else {
        // Because error - Query data cannot be undefined
        return { id: clientId, phone: null, altPhone: null }
      }
    },
    {
      queryName: 'useFetchClientsPhonesByClientId',
      onError: reportGqlError,
      enabled: Boolean(clientId),
    },
  )
}

export function useFetchLastVisitOfClient(
  clientId: number | undefined | null,
  timezone: string | undefined,
) {
  return useQuery<Date | null | undefined>(
    [CLIENTS_QUERY_KEY, LAST_VISIT_QUERY_KEY, clientId],
    async () => {
      const result = await request(
        gql`
          query ($clientId: Int!) {
            bookings(
              where: { clientId: { _eq: $clientId }, transactions: { id: { _is_null: false } } }
              order_by: { startDate: desc }
              limit: 1
            ) {
              startDate
            }
          }
        `,
        { clientId },
      )
      if (result?.bookings) {
        return result.bookings[0]?.startDate
          ? utcToZonedTime(result.bookings[0]?.startDate, timezone ?? DEFAULT_TIMEZONE)
          : null
      }

      reportError(new Error('Error while trying to get last visit'), 'error', {
        clientId,
        result,
      })
      return undefined
    },
    {
      queryName: 'useFetchLastVisitOfClient',
      enabled: Boolean(clientId) && Boolean(timezone),
      onError: reportGqlError,
    },
  )
}

export function useFetchClientBalance(
  clientId: number | undefined | null,
  branchId: number | undefined,
) {
  return useQuery<number>(
    [TRANSACTIONS_QUERY_KEY, CLIENT_BALANCE_QUERY_KEY, { clientId, branchId }],
    async () => {
      const result = await request(
        gql`
          query ($clientId: Int, $branchId: Int!) {
            clientsBalance(where: { clientId: { _eq: $clientId }, branchId: { _eq: $branchId } }) {
              balance
            }
          }
        `,
        { clientId, branchId },
      )

      if (Array.isArray(result?.clientsBalance)) {
        return result.clientsBalance?.[0]?.balance ?? 0
      } else {
        reportError(new Error('clientsBalance is not an array'), 'warning', { clientId, result })
        return 0
      }
    },
    {
      queryName: 'useFetchClientBalance',
      enabled: Boolean(clientId) && Boolean(branchId),
      onError: reportGqlError,
    },
  )
}
