import { ServerCardTemplateType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCardOutline } from 'react-icons/io5'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

interface CardsListProps {
  onRowClick: (id: number) => void
  cards: ServerCardTemplateType[] | undefined
  isLoading: boolean
  archive?: boolean
}

export const CardsList: FC<PropsWithBranchId<CardsListProps>> = memo(
  ({ onRowClick, cards, isLoading, archive, branchId }) => {
    const { t } = useTranslation()
    const convertNumberToMoney = useConvertNumberToMoney(branchId)
    const columns = useMemo<ColumnDef<ServerCardTemplateType>[]>(
      () => [
        {
          accessorKey: 'name',
          header: t('name'),
          cell: data => data.getValue(),
          size: 400,
        },
        {
          accessorKey: 'cardPeriod',
          header: t('validityDays'),
          cell: data => data.getValue(),
        },
        {
          accessorKey: 'price',
          header: t('giftCard.amount'),
          cell: data => convertNumberToMoney(data.getValue<number>()),
        },
      ],
      [convertNumberToMoney, t],
    )

    if (cards?.length === 0 && !isLoading)
      return (
        <EmptyPlaceholder
          Icon={IoCardOutline}
          text={archive ? t('emptyPlaceholder.archive') : t('emptyPlaceholder.card')}
        />
      )

    return (
      <Table
        columns={columns}
        data={cards}
        isLoading={isLoading}
        onRowClick={card => onRowClick(card.id)}
      />
    )
  },
)
