import {
  CheckPromoCodeResponseCodes,
  GcfPromoCode,
  PromoCodeTermType,
  useCheckPromoCode,
} from '@expane/data'
import { addDays, addMonths, createCurrentDate } from '@expane/date'
import { SubmitHandler, useForm } from 'react-hook-form'
import { TFunction } from 'react-i18next'

export interface PromoCodeWidgetProps {
  onCheck: (promoCode: GcfPromoCode | undefined) => void
  watchedPromoCode: GcfPromoCode | undefined
}

interface PromoCodeFormValues {
  promoCode: string
}

export const usePromoCodeApplication = ({
  watchedPromoCode,
  t,
  onCheck,
  onSuccessMessage,
  onErrorMessage,
}: PromoCodeWidgetProps & {
  t: TFunction
  onSuccessMessage: (message: string) => void
  onErrorMessage: (message: string) => void
}) => {
  const { mutateAsync: checkPromoCode } = useCheckPromoCode()

  const { control, handleSubmit, formState, setError } = useForm<PromoCodeFormValues>({
    defaultValues: { promoCode: watchedPromoCode?.id ?? '' },
  })

  const submitSavePromoCode: SubmitHandler<PromoCodeFormValues> = async ({ promoCode }) => {
    const result = await checkPromoCode(promoCode)

    if (!result.checkPromoCode) {
      onErrorMessage(t('promoCodeApplication.error'))
      setError('promoCode', {})
      return
    }

    if (result.checkPromoCode.code === CheckPromoCodeResponseCodes.noFound) {
      onErrorMessage(t('promoCodeApplication.noFound'))
      setError('promoCode', {})
      onCheck(undefined)
    }

    if (result.checkPromoCode.code === CheckPromoCodeResponseCodes.expired) {
      onErrorMessage(t('promoCodeApplication.expired'))
      setError('promoCode', {})
      onCheck(undefined)
    }

    if (result.checkPromoCode.code === CheckPromoCodeResponseCodes.success) {
      onSuccessMessage(t('promoCodeApplication.success'))
      onCheck(result.checkPromoCode.promoCode ?? undefined)
    }
  }

  return {
    onSubmit: handleSubmit(submitSavePromoCode),
    control,
    formState,
  }
}

export const getTestPeriodEndDate = (
  timezone: string | undefined,
  promoCode: GcfPromoCode | undefined,
) => {
  if (!promoCode) {
    // за замовчування 2 місяці
    return addMonths(createCurrentDate(timezone), 2)
  }

  if (promoCode.termType === PromoCodeTermType.days)
    return addDays(createCurrentDate(timezone), promoCode.term)
  else return addMonths(createCurrentDate(timezone), promoCode.term)
}
