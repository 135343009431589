import { gql } from 'graphql-request'

export const businessSettingsBriefFragment = gql`
  fragment businessSettingsBriefType on businessSettings {
    hasSms
    allowableTimeForEditingBookingStartDate
    allowableBookingUpdateTime
    allowedToCreateBookingsByClientWithoutFunds
    allowedToCreateBookingsByClientWithFunds
    allowedToCreateBookingsByClientWithSubscription
  }
`

export const businessSettingsFragment = gql`
  ${businessSettingsBriefFragment}
  fragment businessSettingsType on businessSettings {
    id
    messageTemplate
    smsToken
    binotelIntegration
    hideClientsDeposit
    allowNewClientWidget
    telegramIntegration
    ...businessSettingsBriefType
  }
`
