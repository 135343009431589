import { BusinessSettingsType } from '@expane/data'
import { expaneTelegramBotLink } from '@expane/logic/links'
import { Button, Checkbox, Input } from '@expane/ui'
import { config } from 'config'
import {
  NotificationsBlockProps,
  NotificationSettingsBlock,
} from 'pages/SettingsPage/NotificationsSettings/index'
import { useOpenTelegramInstructionDialog } from 'pages/SettingsPage/NotificationsSettings/TelegramInstructionDialog'
import { FC } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoQrCodeOutline } from 'react-icons/io5'
import { URLContainer } from 'ui/URLContainer'
import { TelegramIcon } from 'ui/Icons'
import { useOpenQrCodeDialog } from 'widgets/QrCodeDialog'
import { PLACEHOLDERS } from '@expane/logic/form'

export const TelegramIntegrationSettings: FC<
  NotificationsBlockProps & { businessSettings: BusinessSettingsType }
> = ({ control, businessSettings }) => {
  const { t } = useTranslation()

  const { dialog: telegramInstructionDialog, openDialog: openTelegramInstructionDialog } =
    useOpenTelegramInstructionDialog()
  const { dialog: qrCodeDialog, openDialog: openQrCodeDialog } = useOpenQrCodeDialog()

  const watchedBotName = useWatch({ control, name: 'telegramIntegration.name' })
  const watchedBotToken = useWatch({ control, name: 'telegramIntegration.token' })
  const watchedIsUseDefault = useWatch({ control, name: 'telegramIntegration.isUseDefault' })

  const isRequired = !watchedIsUseDefault

  const url = watchedIsUseDefault
    ? expaneTelegramBotLink(config.ENV)
    : Boolean(watchedBotToken) && Boolean(watchedBotName)
    ? `https://t.me/${businessSettings.telegramIntegration.name}`
    : null

  return (
    <NotificationSettingsBlock
      title={t('integration.telegram')}
      icon={<TelegramIcon size={'1.3rem'} />}
    >
      <Controller
        name="telegramIntegration.isUseDefault"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Checkbox
            containerClassName="my-2"
            label={t('useDefaultBot')}
            checked={value}
            onChange={onChange}
          />
        )}
      />

      {!watchedIsUseDefault && (
        <>
          <Controller
            name="telegramIntegration.name"
            control={control}
            rules={{ required: isRequired }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                containerClassName="grow"
                value={value}
                onChange={onChange}
                required={isRequired}
                placeholder={PLACEHOLDERS.bot}
                label={t('name')}
                errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
              />
            )}
          />

          <div className="flex items-end gap-2">
            <Controller
              name="telegramIntegration.token"
              control={control}
              rules={{ required: isRequired }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <Input
                  containerClassName="grow"
                  value={value}
                  onChange={onChange}
                  required={isRequired}
                  placeholder={PLACEHOLDERS.token}
                  label={t('token')}
                  errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
                />
              )}
            />

            <Button type="outline" onClick={openTelegramInstructionDialog} className="mb-4">
              {t('instruction')}
            </Button>
          </div>
        </>
      )}

      {url && (
        <>
          <div className="flex items-center gap-1 text-secondary-color">
            <p>{t('link')}: </p>
            <URLContainer url={url} />
            <Button
              className="ml-auto"
              type="outline"
              onClick={() => openQrCodeDialog({ text: url, title: `Telegram ${t('QRcode')}` })}
              Icon={IoQrCodeOutline}
            >
              {t('QRcode')}
            </Button>
          </div>
        </>
      )}

      {telegramInstructionDialog}
      {qrCodeDialog}
    </NotificationSettingsBlock>
  )
}
