import { ServerBillingInfoInsertInput } from '../../generated/graphql-types'
import { queryClient, request, reportGqlError, useMutation } from '../../api'
import { gql } from 'graphql-request'
import { BILLING_INFO_QUERY_KEY } from './queryKeys'

export function useCreateBillingInfo() {
  return useMutation(
    (billingInfo: ServerBillingInfoInsertInput) => {
      return request<{ insertBillingInfo: { id: string } }>(
        gql`
          mutation ($billingInfo: billingInfo_insert_input!) {
            insertBillingInfo(object: $billingInfo) {
              id
            }
          }
        `,
        { billingInfo },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => queryClient.invalidateQueries([BILLING_INFO_QUERY_KEY]),
    },
  )
}
