import {
  useFetchBusinessTurnover,
  useFetchCurrentBranchTimezone,
  useFetchEmployeesCount,
  useFetchLastBillingInfo,
} from '@expane/data'
import { useFetchPlans } from '@expane/data/src/gql/plan'
import { FEATURE_FLAGS, useFeatureFlags } from '@expane/logic/featureFlags'
import { Spinner } from '@expane/ui'
import { useFetchMyPermissions } from 'gql/employee'
import { SettingsWrapper } from 'pages/SettingsPage/index'
import { FC } from 'react'
import { useWayForPayScript } from 'services/wayforpay'
import { store } from 'store'
import { CurrentPlanInfo } from './CurrentPlanInfo'
import { CurrentRestrictions } from './CurrentRestrictions'
import { PlanCard } from './PlanCard'

export const BillingSettings: FC = () => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const { getFeatureFlag } = useFeatureFlags()
  const isEnableCheckBillingTurnover = getFeatureFlag(FEATURE_FLAGS.enableCheckBillingTurnover)

  const { data: plans, isLoading: isPlansLoading } = useFetchPlans()

  const { isLoading: isBillingInfoLoading } = useFetchLastBillingInfo(timezone)
  const { isLoading: isTurnoverLoading } = useFetchBusinessTurnover(isEnableCheckBillingTurnover)
  const { isLoading: isEmployeeCountLoading } = useFetchEmployeesCount()
  const { isLoading: isLoadingMyPermissions } = useFetchMyPermissions()
  const [isLoadingWayForPayScript, isWayForPayError] = useWayForPayScript()

  const isLoading =
    isPlansLoading ||
    isBillingInfoLoading ||
    (isEnableCheckBillingTurnover && isTurnoverLoading) ||
    isEmployeeCountLoading ||
    isLoadingWayForPayScript ||
    isLoadingMyPermissions ||
    !timezone

  if (isLoading) return <Spinner expandCentered />

  return (
    <SettingsWrapper>
      <CurrentPlanInfo />
      <CurrentRestrictions />

      <div className="grid grid-cols-3 gap-4 max-w-fit">
        {plans?.map(plan => (
          <PlanCard
            key={plan.id}
            plan={plan}
            timezone={timezone}
            isWayForPayError={isWayForPayError}
          />
        ))}
      </div>
    </SettingsWrapper>
  )
}
