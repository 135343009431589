import { getTestPeriodEndDate } from '@expane/logic/billing/promoCode'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCalendarOutline } from 'react-icons/io5'
import { GcfPromoCode, ServerPlanType, useFetchCurrentBranchTimezone } from '@expane/data'
import { store } from 'store'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { useConvertNumberToMoneyCodeManually } from '@expane/logic/currency'
import { ChooseButton } from '@expane/ui'

interface BillingInfoProps {
  plan: ServerPlanType | undefined
  onClick: () => void
  watchedPromoCode?: GcfPromoCode
}

export const BillingInfo: FC<BillingInfoProps> = ({ plan, onClick, watchedPromoCode }) => {
  const { t } = useTranslation()
  const formatDate = useDateFormatting()
  const convertNumberToMoneyCodeManually = useConvertNumberToMoneyCodeManually()

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)

  const toDate = getTestPeriodEndDate(timezone, watchedPromoCode)

  const to = formatDate('date', toDate)

  return (
    <div className="flex items-center border-2 rounded-lg p-1 mb-w border-primary-400 bg-accent mb-4">
      <IoCalendarOutline size="2.5rem" className="mr-2 text-billing-main-color" />

      <div>
        <p className={'font-medium text-sm text-billing-main-color'}>
          {t('planInfo.selected')}
          {plan ? ` ${t('plan.' + plan?.name)}` : null}
        </p>
        <p className="text-billing-secondary-color text-sm">
          {t('planInfo.freeUntil', {
            to,
            price: convertNumberToMoneyCodeManually(plan?.monthlyCost ?? 0, {
              currency: 'UAH',
            }),
          })}
        </p>
      </div>

      <ChooseButton className="ml-auto w-40" onClick={onClick} />
    </div>
  )
}
