import React, { FC } from 'react'
import { BlockWrapper } from './BlockWrapper'
import { LandingSettingsBlockProps } from '../logic'
import { Input, InputLabel, ThemePicker } from '@expane/ui'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const OtherSettings: FC<LandingSettingsBlockProps> = ({ control, disabled }) => {
  const { t } = useTranslation()

  return (
    <BlockWrapper label={t('businessLandingSettings.otherSettings')}>
      <div className="flex items-center gap-4 mb-4">
        <Controller
          name="theme"
          control={control}
          render={({ field: { value, onChange } }) => (
            <ThemePicker value={value} onChange={onChange} disabled={disabled} />
          )}
        />

        <InputLabel label={t('businessLandingSettings.chooseTheme')} />
      </div>

      <div className="flex items-center gap-4">
        <Controller
          name="allowableTimeForEditingBookingStartDate"
          control={control}
          rules={{ required: true, validate: value => value >= 0 }}
          render={({ field: { value, onChange } }) => (
            <Input
              onChange={onChange}
              type="number"
              min={0}
              value={value.toString()}
              disabled={disabled}
              containerClassName="w-32"
            />
          )}
        />

        <InputLabel label={t('businessLandingSettings.timeForEditingBookingStartDate')} />
      </div>
    </BlockWrapper>
  )
}
