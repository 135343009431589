import {
  ArchiveLocationGroupResponseCodes,
  ServerArchiveTypes,
  ServerLocationGroupType,
  useArchiveLocationGroup,
  useCreateLocationGroup,
  useFetchLocationGroupById,
  useUpdateLocationGroup,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { CloseButton, Dialog, Input, Modal, Textarea } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { ArchiveButton, RestoreButton, SaveButton } from 'widgets/Buttons'

export const LocationGroupDialog: FC<DialogProps> = ({ id, closeDialog, isCreate, onCreate }) => {
  const { data: locationGroupById } = useFetchLocationGroupById(id)
  const { data: myPermissions } = useFetchMyPermissions()

  const isEditingAllowed = myPermissions?.includes(permissions.location.set) ?? false

  if (!isCreate && !locationGroupById) return null

  return (
    <LocationGroupDialogLogic
      isCreate={isCreate}
      locationGroupById={locationGroupById}
      closeDialog={closeDialog}
      onCreate={onCreate}
      isEditingAllowed={isEditingAllowed}
    />
  )
}

const LocationGroupDialogLogic: FC<LocationGroupDialogLogicProps> = ({
  isCreate,
  locationGroupById,
  closeDialog,
  isEditingAllowed,
}) => {
  const branchId = store.branch.branchId

  const { t } = useTranslation()

  const { formState, control, handleSubmit } = useForm<LocationGroupsFormValues>({
    defaultValues: {
      name: locationGroupById?.name ?? '',
      description: locationGroupById?.description ?? '',
    },
  })

  const { data: myPermissions } = useFetchMyPermissions()

  const { mutateAsync: createMutation } = useCreateLocationGroup()
  const { mutateAsync: updateMutation } = useUpdateLocationGroup()
  const { mutateAsync: archiveLocationGroup, isLoading: isLoadingOnArchive } =
    useArchiveLocationGroup()

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const [openSnackbar] = useSnackbar()

  const mutateLocationGroup: SubmitHandler<LocationGroupsFormValues> = async data => {
    const { name, description } = data

    if (isCreate) {
      if (!branchId) {
        openSnackbar(t('submitError'), 'error')

        closeDialog()
        return
      }
      const result = await createMutation({ name, description, branchId })

      if (result?.insertLocationGroup?.id) {
        openSnackbar(t('locationGroup.createdSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    } else if (locationGroupById) {
      const result = await updateMutation({
        id: locationGroupById.id,
        locationGroupSetInput: { name, description },
      })

      if (result?.updateLocationGroupById?.id)
        openSnackbar(t('locationGroup.updatedSuccessfully'), 'success')
      else openSnackbar(t('submitError'), 'error')
    }

    closeDialog()
  }

  const handleOnArchive = async () => {
    if (!locationGroupById) return

    try {
      const checkingResult = await archiveLocationGroup({
        locationGroupId: locationGroupById?.id,
        archiveType: ServerArchiveTypes.Check,
      })

      const code = checkingResult.archiveLocationGroup.code

      if (code === ArchiveLocationGroupResponseCodes.noPermission) {
        openSnackbar(t('locationGroupArchive.noPermission'), 'error')
        return
      }

      if (code === ArchiveLocationGroupResponseCodes.nonEmpty) {
        openSnackbar(t('locationGroupArchive.nonEmpty', { name: locationGroupById.name }), 'error')
        return
      }

      if (code === ArchiveLocationGroupResponseCodes.success) {
        const result = await archiveLocationGroup({
          locationGroupId: locationGroupById?.id,
          archiveType: ServerArchiveTypes.Archive,
        })

        if (result.archiveLocationGroup.code === ArchiveLocationGroupResponseCodes.success) {
          openSnackbar(
            t('locationGroupArchive.archivingSuccessful', { name: locationGroupById?.name }),
            'success',
          )
          closeDialog()
        } else openSnackbar(t('submitError'), 'error')

        return
      }

      // Всі інші коди то помилки
      openSnackbar(t('submitError'), 'error')
    } catch (e) {
      openSnackbar(t('submitError'), 'error')
    }
  }

  const handleOnRestore = async () => {
    if (!locationGroupById) return

    try {
      const checkingResult = await archiveLocationGroup({
        locationGroupId: locationGroupById?.id,
        archiveType: ServerArchiveTypes.Check,
      })

      const code = checkingResult.archiveLocationGroup.code

      if (code === ArchiveLocationGroupResponseCodes.noPermission) {
        openSnackbar(t('locationArchive.noPermission'), 'error')
        return
      }

      if (code === ArchiveLocationGroupResponseCodes.success) {
        try {
          const result = await archiveLocationGroup({
            locationGroupId: locationGroupById?.id,
            archiveType: ServerArchiveTypes.Restore,
          })

          if (result.archiveLocationGroup.code === ArchiveLocationGroupResponseCodes.success) {
            openSnackbar(
              t('locationArchive.restorationSuccessful', { name: locationGroupById?.name }),
              'success',
            )
            closeDialog()
          } else openSnackbar(t('submitError'), 'error')
        } catch {
          openSnackbar(t('submitError'), 'error')
        }
        return
      }

      // Всі інші коди то помилки
      openSnackbar(t('submitError'), 'error')
    } catch (e) {
      openSnackbar(t('submitError'), 'error')
    }
  }

  const isArchivingAllowed = Boolean(myPermissions?.includes(permissions.location.archive))

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (!formState.isSubmitting && formState.isDirty) handleSubmit(mutateLocationGroup)()
        }}
      >
        <Dialog>
          <Dialog.Title>{t('locationGroup.name')}</Dialog.Title>

          <Dialog.Body>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('title')}
                  placeholder={t('placeholders.locationGroup')}
                  required
                  errorMessage={{
                    isShown: Boolean(formState.errors.name),
                    text: t('formError.required'),
                  }}
                  value={value}
                  onChange={onChange}
                  disabled={!isEditingAllowed}
                  autoFocus
                />
              )}
            />

            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  label={t('description')}
                  placeholder={t('placeholders.locationGroupDescription')}
                  value={value}
                  onChange={onChange}
                  disabled={!isEditingAllowed}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            {isEditingAllowed && (
              <SaveButton
                onClick={handleSubmit(mutateLocationGroup)}
                disabled={formState.isSubmitting || !formState.isDirty}
                spinner={formState.isSubmitting}
                isCreate={isCreate}
              />
            )}

            <CloseButton onClick={closePopups} />

            {isArchivingAllowed &&
              !isCreate &&
              (locationGroupById?.archived ? (
                <RestoreButton
                  className="mr-auto"
                  onClick={handleOnRestore}
                  disabled={isLoadingOnArchive}
                  spinner={isLoadingOnArchive}
                />
              ) : (
                <ArchiveButton
                  className="mr-auto"
                  onClick={handleOnArchive}
                  disabled={isLoadingOnArchive}
                  spinner={isLoadingOnArchive}
                />
              ))}
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {confirmPopup}
    </>
  )
}

interface LocationGroupDialogLogicProps extends Omit<DialogProps, 'id'> {
  locationGroupById: ServerLocationGroupType | undefined
  isEditingAllowed: boolean
}

type LocationGroupsFormValues = {
  name: string
  description: string
}
