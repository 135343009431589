import { ScheduleDto, useFetchBranches } from '@expane/data'
import { TableBody, TableContainer, TableHeader, TableHeaderCell } from '@expane/ui'
import { SchedulePageListItem } from 'pages/SchedulesPage/ScheduleTemplateSettings/ListItem'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoTodayOutline } from 'react-icons/io5'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

export const SchedulesPageList: FC<{
  onRowClick: (id: number) => void
  schedules: ScheduleDto[] | undefined
  isLoading: boolean
  archive?: boolean
}> = memo(({ onRowClick, schedules, isLoading, archive }) => {
  const { t } = useTranslation()

  const { data: branches, isLoading: isLoadingBranch } = useFetchBranches()
  const branchSchedule = branches?.[0].schedule

  const actualIsLoading = isLoading || isLoadingBranch

  if (schedules?.length === 0 && !isLoading)
    return (
      <EmptyPlaceholder
        Icon={IoTodayOutline}
        text={archive ? t('emptyPlaceholder.archive') : t('emptyPlaceholder.schedule')}
      />
    )

  return (
    <TableContainer className="max-w-6xl">
      <TableHeader>
        <tr>
          <TableHeaderCell borderless className="w-2/6">
            {t('title')}
          </TableHeaderCell>
          <TableHeaderCell borderless>{t('schedule.name')}</TableHeaderCell>
        </tr>
      </TableHeader>
      <TableBody>
        {!actualIsLoading &&
          schedules?.map(schedule => (
            <SchedulePageListItem
              key={schedule.id}
              item={schedule}
              onClick={onRowClick}
              branchScheduleId={branchSchedule?.id}
            />
          ))}
        {actualIsLoading && (
          <>
            <SchedulePageListItem branchScheduleId={branchSchedule?.id} />
            <SchedulePageListItem branchScheduleId={branchSchedule?.id} />
            <SchedulePageListItem branchScheduleId={branchSchedule?.id} />
          </>
        )}
      </TableBody>
    </TableContainer>
  )
})
