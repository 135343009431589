import {
  GroupBooking,
  ServerClientBriefForCalendarType,
  useFetchBookingById,
  useFetchCurrentBranchTimezone,
} from '@expane/data'
import { checkIfClientHasPaid } from '@expane/logic/booking'
import { transformPersonName } from '@expane/logic/utils'
import {
  CloseButton,
  Dialog,
  Modal,
  PlaceholderString,
  Spinner,
  TableBody,
  TableCell,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAlertCircleOutline, IoCheckmarkOutline } from 'react-icons/io5'
import { store } from 'store'
import { useBookingPaymentDialog } from 'widgets/BookingPaymentDialog'
import { PayButton } from 'widgets/Buttons'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

export const GroupBookingPaymentDialog: FC<DialogProps> = ({ closeDialog, id }) => {
  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: groupBookingById, isLoading: isGroupBookingLoading } = useFetchBookingById(
    id,
    timezone,
    branchId,
  )

  const { t } = useTranslation()

  const groupBookingClients = (groupBookingById as GroupBooking)?.bookingClients.map(
    gBC => gBC.client,
  )

  const { openPaymentDialog, paymentDialog } = useBookingPaymentDialog()

  if (!groupBookingById && !isGroupBookingLoading) return null

  return (
    <>
      <Modal close={closeDialog}>
        <Dialog>
          <Dialog.Title>{t('groupBooking.payments')}</Dialog.Title>
          <Dialog.Body className="h-86">
            {isGroupBookingLoading ? (
              <Spinner expandCentered />
            ) : (
              <>
                {groupBookingClients.length === 0 ? (
                  <EmptyPlaceholder
                    Icon={IoAlertCircleOutline}
                    text={t('emptyPlaceholder.archive')}
                  />
                ) : (
                  <TableContainer>
                    <TableHeader>
                      <tr>
                        <TableHeaderCell className="w-86">{t('client.name')}</TableHeaderCell>
                        <TableHeaderCell className="w-38 text-center">
                          {t('payment.name')}
                        </TableHeaderCell>
                      </tr>
                    </TableHeader>
                    <TableBody>
                      {groupBookingClients.map(client => (
                        <ClientsListItem
                          key={client.id}
                          item={client}
                          isPaid={checkIfClientHasPaid(groupBookingById as GroupBooking, client.id)}
                          onClick={clientId => {
                            if (id)
                              openPaymentDialog({
                                bookingsIds: [id],
                                clientId,
                              })
                          }}
                        />
                      ))}
                    </TableBody>
                  </TableContainer>
                )}
              </>
            )}
          </Dialog.Body>
          <Dialog.Footer>
            <CloseButton onClick={closeDialog} />
          </Dialog.Footer>
        </Dialog>
      </Modal>
      {paymentDialog}
    </>
  )
}

interface Props {
  item: ServerClientBriefForCalendarType
  isPaid: boolean
  onClick: (id: number) => void
}

const ClientsListItem: FC<Props> = ({ item, onClick, isPaid }) => {
  return (
    <TableRow>
      <TableCell>{item ? transformPersonName(item) : <PlaceholderString />}</TableCell>

      <TableCell className="text-right">
        {isPaid ? (
          <IoCheckmarkOutline size="1.2rem" className="text-primary-500 ml-auto" />
        ) : (
          <PayButton
            onClick={() => {
              onClick(item.id)
            }}
            size="small"
            type="outline"
          />
        )}
      </TableCell>
    </TableRow>
  )
}
