/*
  Types for popup login. Only for WEB
 */
export enum LoginWithPopupType {
  google,
  apple,
}

/*
  Responses from popup login. Only for WEB
 */
export enum LoginWithPopupResponse {
  success,
  failed,
  closedByUser,
  canceledByUser,
}

export const PRODUCTION_TEST_USER_EMAILS = ['tustt988@gmail.com', 'support@ibusiness.com']
export const PRODUCTION_TEST_USER_PASSWORD = 'RaWHtNKHRFufK24'
