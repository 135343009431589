import { Control } from 'react-hook-form'
import { Theme } from '@expane/logic/theme'
import { TFunction } from 'i18next'

export const IMAGE_SIZE = 500

export interface BusinessLandingSettingsFormData {
  bannerTitle: string | undefined
  bannerDescription: string | undefined
  bannerImage: string | undefined
  enabled: boolean
  images: { url: string }[]
  path: string | undefined
  allowableTimeForEditingBookingStartDate: number
  facebook: string | undefined
  instagram: string | undefined
  telegram: string | undefined
  viber: string | undefined
  theme: Theme
}

export interface LandingSettingsBlockProps {
  control: Control<BusinessLandingSettingsFormData>
  disabled?: boolean
}

export const getPathInputError = (type: string | undefined, t: TFunction) => {
  if (!type) return ''

  return type === 'availability'
    ? t('businessLandingSettings.pathError')
    : t('businessLandingSettings.symbolsError')
}
