import {
  MOVEMENT_TYPES,
  ServerAccountType,
  ServerMovementType,
  TRANSACTION_TYPES,
  TransactionFromAccount,
  useCreateTransactionsFromAccount,
  useFetchAccounts,
} from '@expane/data'
import { getCurrentAccount } from '@expane/logic/accounts'
import { isPlanError, isRestrictionError } from '@expane/logic/billing'
import { useConvertNumberToMoneyCode } from '@expane/logic/currency'
import { checkWithdrawPossibility } from '@expane/logic/form'
import { getFinancialInfoAboutMovement, getMovementTypesName } from '@expane/logic/movement'
import { findById } from '@expane/logic/utils'
import { CloseButton, Dialog, Modal, SelectDropdown, Table, usePopupOpenState } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { observer } from 'mobx-react-lite'
import { filterMovements, statusItems } from 'pages/MovementsPage/logic'
import { FC, useMemo, useRef } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoListOutline } from 'react-icons/io5'
import { reportError } from 'services/sentry'
import { store } from 'store'
import { PayButton } from 'widgets/Buttons'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'
import { MovementInfoDialog } from 'widgets/MovementInfoDialog'
import { useAccountsWithoutCheckbox } from '@expane/logic/payment/checkbox'

interface PaymentMovementsDialogProps {
  movements: ServerMovementType[]
  closeDialog: () => void
}

const PaymentMovementsDialog: FC<PaymentMovementsDialogProps> = observer(
  ({ closeDialog, movements }) => {
    const branchId = store.branch.branchId

    const { t } = useTranslation()
    const convertToMoney = useConvertNumberToMoneyCode({ branchId })
    const formatDate = useDateFormatting()

    const { control, handleSubmit, formState } = useForm<{ accountId: number }>()

    const { dialog: movementMovementInfoDialog, openEditDialog: openMovementInfoDialog } =
      useOpenDialog(MovementInfoDialog)

    const { data: accounts } = useFetchAccounts(branchId)
    const { accountsWithoutCheckbox } = useAccountsWithoutCheckbox(accounts ?? [])

    const { mutateAsync: createTransactionsFromAccount } = useCreateTransactionsFromAccount()

    const [openSnackBar] = useSnackbar()

    const notPaidMovements = filterMovements({
      movements,
      statusId: statusItems.notPaid.id,
    })
    const columns = useMemo<ColumnDef<ServerMovementType>[]>(
      () => [
        {
          accessorKey: 'type',
          header: t('type'),
          cell: data => getMovementTypesName(data.getValue<number>(), t),
        },
        {
          accessorKey: 'number',
          header: t('number'),
        },
        {
          accessorKey: 'createdAt',
          header: t('dateTitle'),
          cell: data => formatDate('shortDate', data.getValue<Date>()),
        },
        {
          id: 'time',
          accessorKey: 'createdAt',
          header: t('timeTitle'),
          cell: data => formatDate('time', data.getValue<Date>()),
        },
        {
          id: 'fromSource',
          accessorFn: movement =>
            movement?.type === MOVEMENT_TYPES.arrival.id
              ? movement?.supplier?.name
              : movement?.fromStorage?.name,
          header: t('fromSource'),
        },
        {
          id: 'to',
          accessorFn: movement =>
            movement?.type === MOVEMENT_TYPES.return.id
              ? movement.supplier?.name
              : movement?.toStorage?.name ?? '-',
          header: t('to'),
        },
        {
          id: 'toPay',
          accessorFn: movement => {
            const { overallPrice, totalPaidSum } = getFinancialInfoAboutMovement(movement)
            return overallPrice - (totalPaidSum ?? 0)
          },
          header: () => <span className="w-full text-right">{t('toPay')}</span>,
          cell: data => <div className="text-right">{convertToMoney(data.getValue<number>())}</div>,
        },
      ],
      [convertToMoney, formatDate, t],
    )

    const sumForPayment = notPaidMovements ? getTotalSumForPayment(notPaidMovements) : undefined

    const onConfirm: SubmitHandler<{ accountId: number }> = async ({ accountId }) => {
      if (!branchId) {
        openSnackBar(t('submitError'), 'error')

        return
      }

      const account = findById(accountId, accounts)

      try {
        if (account) {
          const transactionsFromAccount = getTransactionFromAccount(
            notPaidMovements,
            account,
            branchId,
          )
          await createTransactionsFromAccount({ transactionsFromAccount })

          openSnackBar(t('payment.successful'), 'success', 3000)
          closeDialog()
        }
      } catch (error) {
        if (isRestrictionError(error)) openSnackBar(t('planRestriction'), 'error')
        else if (isPlanError(error)) openSnackBar(t('planInfo.noPlan'), 'error')
        else {
          openSnackBar(t('submitError'), 'error')
          reportError(error, 'error', {
            notPaidMovements,
            account,
          })
        }
      }
    }

    return (
      <Modal
        close={closeDialog}
        confirm={() => {
          if (!(Boolean(!sumForPayment) || formState.isSubmitting || !formState.isDirty))
            handleSubmit(onConfirm)()
        }}
      >
        <Dialog>
          <Dialog.Title>{t('payment.invoices')}</Dialog.Title>
          <Dialog.Body className="w-240">
            <div className="flex flex-col">
              <div className="flex-1 min-h-60">
                {notPaidMovements?.length === 0 ? (
                  <EmptyPlaceholder Icon={IoListOutline} text={t('noUnpaidInvoices')} />
                ) : (
                  <Table
                    containerClassName={notPaidMovements.length > 7 ? 'h-60' : ''}
                    columns={columns}
                    data={notPaidMovements}
                    onRowClick={movement => openMovementInfoDialog(movement.id)}
                  />
                )}
                {sumForPayment ? (
                  <div className="flex items-center justify-end mt-2">
                    <div className="mr-2 text-gray-500">{t('totalPayable')}:</div>
                    <div className="text-main-color">{convertToMoney(sumForPayment)}</div>
                  </div>
                ) : null}
              </div>

              {sumForPayment ? (
                <Controller
                  control={control}
                  name="accountId"
                  rules={{
                    required: true,
                    validate: {
                      checkWithdrawPossibility: value =>
                        checkWithdrawPossibility({
                          currentAccount: getCurrentAccount(accounts, value),
                          value: sumForPayment,
                        }),
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <SelectDropdown
                      className="w-68 ml-auto"
                      label={t('account.name')}
                      required
                      items={accountsWithoutCheckbox}
                      onSelectChange={onChange}
                      value={value}
                      errorMessage={{
                        isShown: Boolean(error),
                        text:
                          error?.type === 'required'
                            ? t('formError.required')
                            : t('formError.insufficientFunds'),
                      }}
                    />
                  )}
                />
              ) : null}
            </div>
          </Dialog.Body>

          <Dialog.Footer>
            <CloseButton onClick={closeDialog} />
            <PayButton
              disabled={Boolean(!sumForPayment) || formState.isSubmitting || !formState.isDirty}
              onClick={handleSubmit(onConfirm)}
              spinner={formState.isSubmitting}
            />
          </Dialog.Footer>
        </Dialog>
        {movementMovementInfoDialog}
      </Modal>
    )
  },
)

export const usePaymentMovementsDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const dialogMovements = useRef<ServerMovementType[]>([])
  const onCloseDialog = useRef<() => void>()

  const openPaymentMovementsDialog = (
    props: Omit<PaymentMovementsDialogProps, 'closeDialog'> & { onClose?: () => void },
  ) => {
    dialogMovements.current = props.movements
    onCloseDialog.current = props.onClose
    openPopup()
  }

  const closeDialog = () => {
    closePopup()
    if (onCloseDialog.current) onCloseDialog.current()
  }

  const paymentMovementsDialog = isOpen ? (
    <PaymentMovementsDialog closeDialog={closeDialog} movements={dialogMovements.current} />
  ) : null

  return {
    openPaymentMovementsDialog,
    paymentMovementsDialog,
  }
}

const getTotalSumForPayment = (movements: ServerMovementType[]) => {
  const result = movements.reduce((sum, movement) => {
    const { overallPrice, isPaid, totalPaidSum } = getFinancialInfoAboutMovement(movement)
    if (isPaid) return sum
    const priceToPay = overallPrice - (totalPaidSum ?? 0)
    return sum + priceToPay
  }, 0)

  return result
}

const getTransactionFromAccount = (
  movements: ServerMovementType[],
  account: ServerAccountType,
  branchId: number,
) => {
  const result: TransactionFromAccount[] = []

  movements.forEach(movement => {
    const { productsWithDiscountPrice, transportCosts, isPaid, totalPaidSum, paidSumTransport } =
      getFinancialInfoAboutMovement(movement)

    const unpaidTransportCost = paidSumTransport
      ? transportCosts - paidSumTransport
      : transportCosts
    const amountForProducts =
      productsWithDiscountPrice - ((totalPaidSum ?? 0) - (paidSumTransport ?? 0))

    if (!isPaid) {
      if (amountForProducts > 0) {
        result.push({
          fromAccountId: account.id,
          amount: amountForProducts,
          type: TRANSACTION_TYPES.productsPurchase.id,
          movementId: movement.id,
          branchId,
        })
      }

      if (unpaidTransportCost > 0) {
        result.push({
          fromAccountId: account.id,
          amount: unpaidTransportCost,
          type: TRANSACTION_TYPES.transportCosts.id,
          movementId: movement.id,
          branchId,
        })
      }
    }
  })

  return result
}
