import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { convertTransactionMovementsQuantity } from '@expane/logic/movement'
import { getUnitsName } from '@expane/logic/product'
import {
  calcReportItemsCreditTotalPrice,
  calcReportItemsCreditTotalQuantity,
  calcReportItemsTotalPrice,
  groupReportItemsById,
} from '@expane/logic/reports/logic'
import {
  calcProductReportItemTotalQuantity,
  convertTransactionsToProductReportItem,
  getProductReportForSaleItems,
  ProductReportItemType,
} from '@expane/logic/reports/products'
import {
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { ReportProps } from 'pages/ReportsPage/index'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

export const ProductsReport: FC<PropsWithBranchId<ReportProps>> = ({ transactions, branchId }) => {
  const { t } = useTranslation()

  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const convertedTransactions = convertTransactionMovementsQuantity(transactions)
  const productReportItems = convertTransactionsToProductReportItem(convertedTransactions)
  // в отчете должны быть только товары для продажи(исключаем расходники)
  const productReportForSaleItems = getProductReportForSaleItems(productReportItems)

  const groupedProducts = groupReportItemsById(productReportForSaleItems)

  const creditTotalPrice = calcReportItemsCreditTotalPrice(productReportForSaleItems)
  const totalPrice = calcReportItemsTotalPrice(productReportForSaleItems)

  const productsToDisplay = Object.keys(groupedProducts)

  if (productsToDisplay.length === 0)
    return <EmptyPlaceholder text={t('emptyPlaceholder.archive')} />

  return (
    <TableContainer>
      <TableHeader>
        <tr>
          <TableHeaderCell>{t('name')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('qty')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('unit.name')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('reports.inLoan')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('amount')}</TableHeaderCell>
          <TableHeaderCell className="text-right">{t('reports.inLoanAmount')}</TableHeaderCell>
        </tr>
      </TableHeader>
      <TableBody>
        {productsToDisplay.map(groupId => {
          const product = productReportItems.find(item => item.id === Number(groupId))

          if (product)
            return (
              <ProductsReportItem
                key={groupId}
                productReportItems={groupedProducts[groupId]}
                name={product.name}
                unit={product.unit}
                branchId={branchId}
              />
            )
        })}
      </TableBody>
      <TableFooter>
        <TableCell>{t('total')}</TableCell>
        <TableCell colSpan={3} />
        <TableCell className="text-right">{convertNumberToMoney(totalPrice)}</TableCell>
        <TableCell className="text-right">{convertNumberToMoney(creditTotalPrice)}</TableCell>
      </TableFooter>
    </TableContainer>
  )
}

interface ProductsReportItemProps {
  name: string
  unit: number
  productReportItems: ProductReportItemType[]
}

const ProductsReportItem: FC<PropsWithBranchId<ProductsReportItemProps>> = ({
  name,
  unit,
  productReportItems,
  branchId,
}) => {
  const { t } = useTranslation()

  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const productsQuantity = calcProductReportItemTotalQuantity(productReportItems)
  const creditTotalQuantity = calcReportItemsCreditTotalQuantity(productReportItems)
  const creditTotalPrice = calcReportItemsCreditTotalPrice(productReportItems)
  const totalPrice = calcReportItemsTotalPrice(productReportItems)

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell className="text-right">{productsQuantity}</TableCell>
      <TableCell className="text-right">{getUnitsName(unit, t)}</TableCell>
      <TableCell className="text-right">{creditTotalQuantity}</TableCell>
      <TableCell className="text-right">{convertNumberToMoney(totalPrice)}</TableCell>
      <TableCell className="text-right">{convertNumberToMoney(creditTotalPrice)}</TableCell>
    </TableRow>
  )
}
