import { ServerTransactionType, useFetchBusinessSettings } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { getTransactionsListToDisplay } from '@expane/logic/finances/transactionsToDisplayFilters'
import {
  addClientBalanceToTransactions,
  getPaymentAndRefundTransactionAdditionalInfo,
  TransactionWiThClientBalance,
  transformTransactionType,
} from '@expane/logic/transaction'
import { transformPersonName } from '@expane/logic/utils'
import { Hint, Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCashOutline } from 'react-icons/io5'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

interface TransactionsHistoryProps {
  history: ServerTransactionType[]
}

export const TransactionsHistory: FC<PropsWithBranchId<TransactionsHistoryProps>> = ({
  history,
  branchId,
}) => {
  const { data: businessSettings } = useFetchBusinessSettings()

  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()
  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const transactionsWithClientBalanceDesc = addClientBalanceToTransactions(history)

  const transactionsToDisplayDesc = getTransactionsListToDisplay({
    transactions: transactionsWithClientBalanceDesc,
    hideClientsDeposit: businessSettings?.hideClientsDeposit,
    page: 'client',
  })

  const columns = useMemo<ColumnDef<TransactionWiThClientBalance>[]>(
    () => [
      {
        accessorKey: 'date',
        header: t('dateTitle'),
        cell: data => dateFormatting('shortDateTime', data.getValue<Date>()),
        size: 120,
      },
      {
        accessorKey: 'type',
        header: t('type'),
        cell: data => t(transformTransactionType(data.getValue<number>())),
        size: 160,
      },
      {
        id: 'additionalInfo',
        accessorFn: transaction => getPaymentAndRefundTransactionAdditionalInfo(transaction, t),
        header: t('additionalInfo.short'),
        cell: data => data.getValue(),
        size: 200,
      },
      {
        id: 'employeeName',
        accessorFn: transaction =>
          transaction.employee ? transformPersonName(transaction.employee) : '-',
        header: t('employee.name'),
        cell: data => data.getValue(),
        size: 200,
      },
      {
        accessorKey: 'amount',
        header: () => <span className="w-full text-right">{t('amount')}</span>,
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
        ),
      },
      {
        accessorKey: 'clientBalance',
        header: () => (
          <>
            <span className="w-full text-right mr-1">{t('account.name')}</span>
            <Hint>{t('onAccountAfterTransaction')}</Hint>
          </>
        ),
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())} </div>
        ),
      },
    ],
    [convertNumberToMoney, dateFormatting, t],
  )

  return transactionsToDisplayDesc.length > 0 ? (
    <Table columns={columns} data={transactionsToDisplayDesc} />
  ) : (
    <EmptyPlaceholder Icon={IoCashOutline} text={t('noTransactions')} />
  )
}
