import { useEffect } from 'react'
import { useWebPersistedState } from '../useWebPersistedState'
import { ReportErrorFunc } from '../utils'

const COLOR_THEME_KEY = 'theme/color'
const THEME_MODE_KEY = 'theme/mode'

// Add or remove only together with index.css
export type Theme = 'primary' | 'sky' | 'indigo' | 'stone' | 'pink'
export type ThemeMode = 'dark' | 'light'

const setColorTheme = (theme: Theme) => {
  document.body.dataset.theme = theme
}
const getColorTheme = () => {
  // body always has data-theme attribute
  return document.body.dataset.theme as Theme
}

const setDarkModeTheme = () => {
  document.body.classList.add('dark')
}
const setLightModeTheme = () => {
  document.body.classList.remove('dark')
}
const getBrowserThemeMode = (): ThemeMode => {
  return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
}

export const useUpdateColorThemeOnLoadApp = (reportError: ReportErrorFunc) => {
  const { storage: colorTheme, updateStorage: updateColorTheme } = useWebPersistedState<Theme>(
    COLOR_THEME_KEY,
    reportError,
  )
  const { storage: lsThemeMode, updateStorage: updateThemeMode } = useWebPersistedState<ThemeMode>(
    THEME_MODE_KEY,
    reportError,
  )

  useEffect(() => {
    // If no color theme then updated localStorage (by default body has "primary" theme)
    if (colorTheme) setColorTheme(colorTheme)
    else updateColorTheme(getColorTheme())

    const themeMode: ThemeMode = lsThemeMode || getBrowserThemeMode()

    if (themeMode === 'dark') setDarkModeTheme()
    else setLightModeTheme()
    updateThemeMode(themeMode)
  }, [colorTheme, lsThemeMode, updateColorTheme, updateThemeMode])
}

export const useColorTheme = (reportError: ReportErrorFunc) => {
  const { storage, updateStorage } = useWebPersistedState<Theme>(
    COLOR_THEME_KEY,
    reportError,
    getColorTheme(),
  )

  const updateTheme = (theme: Theme) => {
    updateStorage(theme)
    setColorTheme(theme)
  }

  return { themeColor: storage, updateTheme }
}

export const useThemeMode = (reportError: ReportErrorFunc) => {
  const { storage, updateStorage } = useWebPersistedState<ThemeMode>(
    THEME_MODE_KEY,
    reportError,
    getBrowserThemeMode(),
  )

  const updateThemeMode = (themeMode: ThemeMode) => {
    if (themeMode === 'dark') setDarkModeTheme()
    else setLightModeTheme()

    updateStorage(themeMode)
  }

  return { themeMode: storage, updateThemeMode }
}

const DEFAULT_THEME: Theme = 'primary'

export const COLOR_THEMES: Theme[] = [DEFAULT_THEME, 'sky', 'indigo', 'stone', 'pink']
