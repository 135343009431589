import { ServerBusinessWithOwnerIdType } from '@expane/data'
import { generateLinkForBookingWidget } from '@expane/logic/business'
import { Input, InputLabel, Paper } from '@expane/ui'
import { config } from 'config'
import { BusinessSettingsForm } from 'pages/SettingsPage/BusinessSettings/index'
import { FC, useEffect } from 'react'
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { URLContainer } from 'ui/URLContainer'
import { Checkbox } from '@expane/ui'

interface ClientPermissionUseMobAppForBookingProps {
  myBusiness: ServerBusinessWithOwnerIdType
  control: Control<BusinessSettingsForm>
  isClientPermissionUseMobAppForBooking: boolean
  setValue: UseFormSetValue<BusinessSettingsForm>
}

export const ClientPermissionUseMobAppForBooking: FC<ClientPermissionUseMobAppForBookingProps> = ({
  myBusiness,
  control,
  isClientPermissionUseMobAppForBooking,
  setValue,
}) => {
  const { t } = useTranslation()

  const watchedWidgetPermission = useWatch({ control, name: 'allowNewClientWidget' })

  const watchedMobileAppPermission = useWatch({
    control,
    name: 'clientPermissionUseMobAppForBooking',
  })

  const watchedAllowedToCreateBookingsByClientWithoutFunds = useWatch({
    control,
    name: 'allowedToCreateBookingsByClientWithoutFunds',
  })

  const watchedAllowedToCreateBookingsByClientWithSubscription = useWatch({
    control,
    name: 'allowedToCreateBookingsByClientWithSubscription',
  })

  const watchedAllowedToCreateBookingsByClientWithFunds = useWatch({
    control,
    name: 'allowedToCreateBookingsByClientWithFunds',
  })

  const allPermissionsLevelsAreForBidden =
    !watchedAllowedToCreateBookingsByClientWithFunds &&
    !watchedAllowedToCreateBookingsByClientWithSubscription &&
    !watchedAllowedToCreateBookingsByClientWithoutFunds

  useEffect(() => {
    // если убраны все уровни доступа, то клиентам запрещено манипулировать с записями в моб приложении
    if (allPermissionsLevelsAreForBidden) setValue('clientPermissionUseMobAppForBooking', false)
  }, [
    allPermissionsLevelsAreForBidden,
    setValue,
    watchedAllowedToCreateBookingsByClientWithFunds,
    watchedAllowedToCreateBookingsByClientWithSubscription,
    watchedAllowedToCreateBookingsByClientWithoutFunds,
  ])

  return (
    <>
      <InputLabel label={t('businessSettings.clientInteractionWithBookings')} />
      <Paper>
        <div className="flex bg-primary-50 dark:bg-gray-600 py-2">
          <div className="flex-centered w-1/2">
            <Controller
              control={control}
              name="allowNewClientWidget"
              render={({ field: { value, onChange } }) => (
                <div className="flex">
                  <Checkbox
                    containerClassName="mr-2"
                    label={t('allowClientWidget')}
                    checked={value}
                    onChange={onChange}
                  />
                </div>
              )}
            />
          </div>
          <div className="flex-centered w-1/2">
            <Controller
              name="clientPermissionUseMobAppForBooking"
              control={control}
              defaultValue={isClientPermissionUseMobAppForBooking}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  containerClassName="mr-3"
                  checked={value}
                  label={t('mobileApp')}
                  onChange={event => {
                    const isChecked = event.target.checked

                    // если мы запретили клиентам манипулировать с записями в моб приложении
                    // то все уровни доступа не доступны
                    if (!isChecked) {
                      setValue('allowedToCreateBookingsByClientWithoutFunds', false)
                      setValue('allowedToCreateBookingsByClientWithFunds', false)
                      setValue('allowedToCreateBookingsByClientWithSubscription', false)
                    }

                    // если мы разрешили клиентам манипулировать с записями в моб приложении
                    // то автоматом уставливаем минимум один из уровней доступа
                    if (isChecked && allPermissionsLevelsAreForBidden)
                      setValue('allowedToCreateBookingsByClientWithSubscription', true)

                    onChange(isChecked)
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="flex divide-x-2 divide-primary-50 dark:divide-gray-600 border-b-2 border-primary-50 dark:border-gray-600">
          <div className="flex-centered w-1/2">
            {watchedWidgetPermission && (
              <URLContainer
                url={generateLinkForBookingWidget(myBusiness.id, config.ENV === 'development')}
              />
            )}
          </div>
          <div className="flex-centered flex-col w-1/2 py-2">
            <Controller
              name="allowedToCreateBookingsByClientWithSubscription"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  label={t('businessSettings.permissionLevels.withSubscription')}
                  hint={t('businessSettings.permissionLevels.withSubscriptionHint')}
                  checked={value}
                  containerClassName="w-64"
                  onChange={onChange}
                  disabled={!watchedMobileAppPermission}
                />
              )}
            />
            <Controller
              name="allowedToCreateBookingsByClientWithoutFunds"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Checkbox
                  label={t('businessSettings.permissionLevels.withoutFunds')}
                  hint={t('businessSettings.permissionLevels.withoutFundsHint')}
                  checked={value}
                  containerClassName="w-64"
                  onChange={e => {
                    const isChecked = e.target.checked

                    // если выбран уровень доступа "всегда",
                    // то это означает что остальные уровни доступа автоматом включены
                    if (isChecked && !watchedAllowedToCreateBookingsByClientWithSubscription) {
                      setValue('allowedToCreateBookingsByClientWithSubscription', true)
                      onChange(isChecked)
                    } else {
                      onChange(isChecked)
                    }
                  }}
                  disabled={!watchedMobileAppPermission}
                />
              )}
            />
          </div>
        </div>
        <div className="p-2 flex-centered flex-col">
          <InputLabel label={t('businessSettings.bookingUpdateTime')} />

          <Controller
            name="allowableBookingUpdateTime"
            control={control}
            rules={{ required: true, validate: value => value >= 0 }}
            render={({ field: { value, onChange } }) => (
              <Input
                onChange={onChange}
                type="number"
                min={0}
                value={value.toString()}
                disabled={!watchedMobileAppPermission && !watchedWidgetPermission}
                height="small"
                containerClassName="w-32"
              />
            )}
          />
          <InputLabel label={t('businessSettings.timeForEditingBookingStartDate')} />

          <Controller
            name="allowableTimeForEditingBookingStartDate"
            control={control}
            rules={{ required: true, validate: value => value >= 0 }}
            render={({ field: { value, onChange } }) => (
              <Input
                onChange={onChange}
                type="number"
                min={0}
                value={value.toString()}
                disabled={!watchedMobileAppPermission && !watchedWidgetPermission}
                height="small"
                containerClassName="w-32"
              />
            )}
          />
        </div>
      </Paper>
    </>
  )
}
