import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { getTransactionsWithOtherBusinessExpenses } from '@expane/logic/finances/filters'
import { getAdditionalInfoByTransactionType } from '@expane/logic/transaction'
import { calcValues } from '@expane/logic/utils'
import {
  PlaceholderString,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { ReportProps } from 'pages/ReportsPage'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

export const OutgoingReport: FC<PropsWithBranchId<ReportProps>> = ({ transactions, branchId }) => {
  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const dateFormatting = useDateFormatting()

  const otherBusinessExpensesTransactions = getTransactionsWithOtherBusinessExpenses(transactions)
  if (!otherBusinessExpensesTransactions?.length)
    return <EmptyPlaceholder text={t('emptyPlaceholder.archive')} />

  const total = otherBusinessExpensesTransactions
    ? calcValues(otherBusinessExpensesTransactions)
    : 0

  return (
    <>
      <TableContainer>
        <TableHeader>
          <tr>
            <TableHeaderCell>{t('dateTitle')}</TableHeaderCell>
            <TableHeaderCell className="w-64">{t('name')}</TableHeaderCell>
            <TableHeaderCell className="text-right">{t('amount')}</TableHeaderCell>
          </tr>
        </TableHeader>

        <TableBody>
          {otherBusinessExpensesTransactions.map(expense => (
            <TableRow key={expense.id}>
              <TableCell>
                {expense ? (
                  dateFormatting('shortDateTime', expense.date)
                ) : (
                  <PlaceholderString className="ml-auto" />
                )}
              </TableCell>

              <TableCell>
                {expense ? (
                  expense.expensesReason ? (
                    expense.expensesReason.name
                  ) : (
                    getAdditionalInfoByTransactionType(expense, t)
                  )
                ) : (
                  <PlaceholderString />
                )}
              </TableCell>

              <TableCell className="text-right">
                {expense ? (
                  convertNumberToMoney(expense.amount)
                ) : (
                  <PlaceholderString className="ml-auto" />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableCell>{t('total')}</TableCell>
          <TableCell />
          <TableCell className="text-right">{convertNumberToMoney(total)}</TableCell>
        </TableFooter>
      </TableContainer>
    </>
  )
}
