import {
  Maybe,
  ServerCheckPromoCodeResult,
  ServerGcfPromoCode,
} from '../../generated/graphql-types'

export const PROMO_CODE_TERM_TYPES = [
  {
    id: 1,
    name: 'dayTitle',
  },
  {
    id: 2,
    name: 'month',
  },
]

export enum CheckPromoCodeResponseCodes {
  noRequiredFields,
  noFound,
  expired,
  success,
}

export enum PromoCodeTermType {
  days = 1,
  months,
}

export interface GcfPromoCode extends ServerGcfPromoCode {
  termType: PromoCodeTermType
}

export interface CheckPromoCodeResult extends ServerCheckPromoCodeResult {
  code: CheckPromoCodeResponseCodes
  promoCode: Maybe<GcfPromoCode>
}
