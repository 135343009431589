import { ServerExtendedServiceType } from '@expane/data'

import { Hint, TableBody, TableContainer, TableHeader, TableHeaderCell } from '@expane/ui'
import { FC } from 'react'
import { Control, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoListOutline } from 'react-icons/io5'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'
import { SalarySettingFormValues } from '../logic'
import { RateFromServicesItem } from './RateFromServicesItem'

interface RateFromServicesProps {
  services: ServerExtendedServiceType[]
  control: Control<SalarySettingFormValues>
  disabled?: boolean
}

export const RateFromServices: FC<RateFromServicesProps> = ({ services, control, disabled }) => {
  const { t } = useTranslation()

  const { fields: salarySettingServices } = useFieldArray({
    control,
    name: 'salarySettingServices',
  })

  return (
    <div className="pl-2 h-80">
      {salarySettingServices.length === 0 ? (
        <EmptyPlaceholder text={t('servicesAreNotProvided')} Icon={IoListOutline} />
      ) : (
        <TableContainer className="max-h-80">
          <TableHeader>
            <tr>
              {TITLES.map(({ title, hint, width }, index) => (
                <TableHeaderCell
                  // + index, так как есть title - пустая строка
                  key={title + index}
                  className={width}
                >
                  {!Boolean(title) && hint ? <Hint>{t(hint)}</Hint> : null}
                  {t(title)}
                  {Boolean(title) && hint ? <Hint>{t(hint)}</Hint> : null}
                </TableHeaderCell>
              ))}
            </tr>
          </TableHeader>

          <TableBody>
            {salarySettingServices.map((service, index) => (
              <RateFromServicesItem
                key={service.id}
                services={services}
                control={control}
                index={index}
                disabled={disabled}
              />
            ))}
          </TableBody>
        </TableContainer>
      )}
    </div>
  )
}

const TITLES: { title: string; width: string; hint?: string }[] = [
  { title: 'service.name', width: 'w-52' },
  { title: 'salary.rateType', width: ' w-64' },
  { title: 'value', width: ' w-28' },
  {
    title: '',
    width: 'w-40',
    hint: 'salarySetting.dependsOnRealizedValueHint',
  },
  {
    title: 'salary.reserveRateType',
    width: 'w-64',
    hint: 'salarySetting.reserveRateTypeHint',
  },
  { title: 'reserveValue', width: 'w-28' },
]
