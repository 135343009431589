import {
  BusinessSettingsType,
  ServerBusinessWithOwnerIdType,
  useFetchBusinessSettings,
  useFetchMyBusinesses,
  useUpdateBusinessById,
  useUpdateBusinessSettingsById,
} from '@expane/data'
import { isClientPermissionUseMobAppForBooking } from '@expane/logic/business'
import { useUpdateFile } from '@expane/logic/file'
import { Checkbox, Paper, Spinner } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { observer } from 'mobx-react-lite'
import { ProceedToNextStep } from 'pages/CreateBusinessPage'
import { PageDataType, stepStyle } from 'pages/CreateBusinessPage/logic'
import { ClientPermissionUseMobAppForBooking } from 'pages/SettingsPage/BusinessSettings/ClientPermissionUseMobAppForBooking'
import { MainBusinessInfo } from 'pages/SettingsPage/BusinessSettings/MainBusinessInfo'
import { SettingsWrapper } from 'pages/SettingsPage/index'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/sentry'
import { store } from 'store'
import { ApplyButton } from 'widgets/Buttons'

export interface BusinessSettingsForm {
  name: string
  photo?: string
  hideClientsDeposit: boolean
  timeZone: number
  clientPermissionUseMobAppForBooking: boolean
  allowableBookingUpdateTime: number
  allowableTimeForEditingBookingStartDate: number
  allowedToCreateBookingsByClientWithoutFunds: boolean
  allowedToCreateBookingsByClientWithSubscription: boolean
  allowedToCreateBookingsByClientWithFunds: boolean
  allowNewClientWidget: boolean
}

export const BusinessSettings: FC = observer(() => {
  const currentBusinessId = store.me.businessId

  const { data: businesses, isLoading: areBusinessesLoading } = useFetchMyBusinesses(
    store.me.isAuthorised,
  )

  const { data: businessSettings, isLoading: areBusinessSettingsLoading } =
    useFetchBusinessSettings()

  const myBusiness = businesses?.find(business => business.id === currentBusinessId)

  if (areBusinessSettingsLoading || areBusinessesLoading) return <Spinner expandCentered />
  if (!myBusiness || !businessSettings) return null

  return <BusinessSettingsLogic myBusiness={myBusiness} businessSettings={businessSettings} />
})

export const BusinessSettingsLogic: FC<{
  myBusiness: ServerBusinessWithOwnerIdType
  businessSettings: BusinessSettingsType
  type?: PageDataType
  onApply?: () => void
}> = ({ myBusiness, businessSettings, type = 'page', onApply }) => {
  const { t } = useTranslation()

  const [openSnackBar] = useSnackbar()

  const { mutateAsync: updateBusinessById } = useUpdateBusinessById()
  const { mutateAsync: updateBusinessSettings } = useUpdateBusinessSettingsById()
  const updatePhoto = useUpdateFile()

  const {
    formState: { dirtyFields, isDirty, isSubmitting },
    control,
    handleSubmit,
    setValue,
  } = useForm<BusinessSettingsForm>({
    defaultValues: {
      name: myBusiness.name,
      photo: myBusiness?.photo ?? '',
      hideClientsDeposit: businessSettings.hideClientsDeposit ?? false,
      clientPermissionUseMobAppForBooking: isClientPermissionUseMobAppForBooking(businessSettings),
      allowableBookingUpdateTime: businessSettings.allowableBookingUpdateTime,
      allowableTimeForEditingBookingStartDate:
        businessSettings.allowableTimeForEditingBookingStartDate,
      allowedToCreateBookingsByClientWithoutFunds:
        businessSettings.allowedToCreateBookingsByClientWithoutFunds,
      allowedToCreateBookingsByClientWithSubscription:
        businessSettings.allowedToCreateBookingsByClientWithSubscription,
      allowedToCreateBookingsByClientWithFunds:
        businessSettings.allowedToCreateBookingsByClientWithFunds,
      allowNewClientWidget: businessSettings.allowNewClientWidget,
    },
  })

  const handleUpdatingBusiness: SubmitHandler<BusinessSettingsForm> = async data => {
    const { name, photo } = data

    try {
      if (type === 'page') {
        const photoUrl = await updatePhoto({
          prevFile: myBusiness?.photo ?? undefined,
          file: photo,
        })

        await updateBusinessById({
          id: myBusiness.id,
          businessSetInput: {
            name,
            photo: photoUrl ?? null,
          },
        })
      }

      // TODO: улучшить эту проверку
      if (
        dirtyFields.hideClientsDeposit ||
        dirtyFields.clientPermissionUseMobAppForBooking ||
        dirtyFields.allowableBookingUpdateTime ||
        dirtyFields.allowableTimeForEditingBookingStartDate ||
        dirtyFields.allowedToCreateBookingsByClientWithoutFunds ||
        dirtyFields.allowedToCreateBookingsByClientWithFunds ||
        dirtyFields.allowedToCreateBookingsByClientWithSubscription ||
        dirtyFields.allowNewClientWidget
      ) {
        await updateBusinessSettings({
          id: businessSettings.id,
          businessSettingsSetInput: {
            hideClientsDeposit: data.hideClientsDeposit,
            allowableBookingUpdateTime: data.allowableBookingUpdateTime,
            allowableTimeForEditingBookingStartDate: data.allowableTimeForEditingBookingStartDate,
            allowedToCreateBookingsByClientWithoutFunds:
              data.allowedToCreateBookingsByClientWithoutFunds,
            allowedToCreateBookingsByClientWithFunds: data.allowedToCreateBookingsByClientWithFunds,
            allowedToCreateBookingsByClientWithSubscription:
              data.allowedToCreateBookingsByClientWithSubscription,
            allowNewClientWidget: data.allowNewClientWidget,
          },
        })
      }

      openSnackBar(t('changesSaved'), 'success', 3000)
      onApply?.()
    } catch (e) {
      reportError(e as Error)
      openSnackBar(t('submitError'), 'error', 3000)
    }
  }

  const content = (
    <div className={'flex items-end'}>
      <Paper
        className={`${stepStyle} flex flex-col ${type === 'createBusiness' ? 'w-160' : 'w-full'}`}
      >
        {type === 'page' && (
          <MainBusinessInfo control={control} defaultPhoto={myBusiness?.photo ?? undefined} />
        )}

        <Controller
          name="hideClientsDeposit"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              containerClassName="mt-3"
              label={t('hideClientsDeposit')}
              checked={value}
              onChange={onChange}
            />
          )}
        />

        <ClientPermissionUseMobAppForBooking
          myBusiness={myBusiness}
          control={control}
          isClientPermissionUseMobAppForBooking={isClientPermissionUseMobAppForBooking(
            businessSettings,
          )}
          setValue={setValue}
        />

        {type === 'page' && (
          <ApplyButton
            className="w-40 mt-2"
            disabled={!isDirty || isSubmitting}
            spinner={isSubmitting}
            onClick={handleSubmit(handleUpdatingBusiness)}
          />
        )}
      </Paper>

      {type === 'createBusiness' && (
        <ProceedToNextStep className="mt-2" onClick={handleSubmit(handleUpdatingBusiness)} />
      )}
    </div>
  )

  return type === 'createBusiness' ? content : <SettingsWrapper>{content}</SettingsWrapper>
}
