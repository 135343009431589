import {
  ServerLocationWithServiceIdsType,
  ServerTransactionBriefWithBookingLocationType,
  useFetchCurrentBranchTimezone,
  useFetchLocations,
  useFetchTransactionsWithBookingLocations,
} from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { calcValues } from '@expane/logic/utils'
import {
  PlaceholderString,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@expane/ui'
import { calcBookingsPayments } from 'logic/finances/calculation'
import {
  getTransactionsWithBookingsWithoutRefunds,
  ServerTransactionWithBookings,
} from 'logic/finances/filters'
import { ReportProps } from 'pages/ReportsPage'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

export interface VisitsReportsProps extends ReportProps {
  startDate: Date
  endDate: Date
}

export const VisitsReports: FC<PropsWithBranchId<VisitsReportsProps>> = ({
  startDate,
  endDate,
  isLoading,
  branchId,
}) => {
  const convertNumberToMoney = useConvertNumberToMoney(branchId)

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: locations, isLoading: isLoadingLocations } = useFetchLocations(branchId)

  const { data: transactionsWithBookings, isLoading: isLoadingTransactionsWithBookings } =
    useFetchTransactionsWithBookingLocations(startDate, endDate, timezone, branchId)

  const { t } = useTranslation()

  const { bookingsTotalValue, bookingsQuantity } = calcBookingsPayments(transactionsWithBookings)

  const isDataLoading = isLoadingLocations || isLoading || isLoadingTransactionsWithBookings

  const locationsWithVisits = getLocationsWithVisits(locations, transactionsWithBookings)

  if (!isLoading && !locationsWithVisits?.length)
    return <EmptyPlaceholder text={t('emptyPlaceholder.archive')} />

  return (
    <TableContainer>
      <TableHeader>
        <tr>
          <TableHeaderCell className="w-64">{t('location.name')}</TableHeaderCell>
          <TableHeaderCell>
            <div className="text-right">{t('visits')}</div>
          </TableHeaderCell>
          <TableHeaderCell>
            <div className="text-right">{t('amount')}</div>
          </TableHeaderCell>
        </tr>
      </TableHeader>

      <TableBody>
        {isDataLoading ? (
          <>
            <VisitsReportItem />
            <VisitsReportItem />
            <VisitsReportItem />
          </>
        ) : (
          <>
            {locationsWithVisits?.map(locationWithVisits => (
              <VisitsReportItem
                key={locationWithVisits.locationId}
                locationWithVisits={locationWithVisits}
                branchId={branchId}
              />
            ))}
          </>
        )}
      </TableBody>
      <TableFooter>
        <TableCell>{t('total')}</TableCell>
        <TableCell>
          <div className="text-right">{bookingsQuantity}</div>
        </TableCell>
        <TableCell>
          <div className="text-right">{convertNumberToMoney(bookingsTotalValue)}</div>
        </TableCell>
      </TableFooter>
    </TableContainer>
  )
}

type VisitsDto = {
  locationId: number
  locationName: string
  transactions: ServerTransactionWithBookings[]
}
const getLocationsWithVisits = (
  locations: ServerLocationWithServiceIdsType[] | undefined,
  transactions: ServerTransactionBriefWithBookingLocationType[] | undefined,
): VisitsDto[] => {
  if (!locations) return []
  const locationsWithVisits: VisitsDto[] = []

  for (const location of locations) {
    const filteredTransactionsByLocation = transactions?.filter(
      transaction => transaction?.booking?.location.id === location?.id,
    )

    const transactionsWithBookingsWithoutRefunds = getTransactionsWithBookingsWithoutRefunds(
      filteredTransactionsByLocation,
    )

    if (transactionsWithBookingsWithoutRefunds?.length)
      locationsWithVisits.push({
        locationId: location.id,
        locationName: location.name,
        transactions: transactionsWithBookingsWithoutRefunds,
      })
  }

  return locationsWithVisits
}

interface VisitsReportItemProps {
  locationWithVisits?: VisitsDto
  branchId?: number | undefined
}

const VisitsReportItem: FC<VisitsReportItemProps> = ({ locationWithVisits, branchId }) => {
  const convertNumberToMoney = useConvertNumberToMoney(branchId)
  return (
    <TableRow>
      <TableCell>
        {locationWithVisits ? locationWithVisits.locationName : <PlaceholderString />}
      </TableCell>
      <TableCell>
        {locationWithVisits ? (
          <div className="text-right">{locationWithVisits.transactions.length}</div>
        ) : (
          <PlaceholderString className="ml-auto" />
        )}
      </TableCell>
      <TableCell>
        {locationWithVisits ? (
          <div className="text-right">
            {convertNumberToMoney(calcValues(locationWithVisits.transactions))}
          </div>
        ) : (
          <PlaceholderString className="ml-auto" />
        )}
      </TableCell>
    </TableRow>
  )
}
