import React from 'react'
import { OnlineBookingURLsLogic } from './OnlineBookingURLsLogic'
import { useFetchBusinessLandingSettings, useFetchOnlineBookingURLs } from '@expane/data'
import { Spinner } from '@expane/ui'

export const OnlineBookingURLs = () => {
  const { data: businessLandingSettings, isLoading: isLoadingBusinessLandingSettings } =
    useFetchBusinessLandingSettings()
  const { data: onlineBookingURLs, isLoading: isLoadingOnlineBookingURLs } =
    useFetchOnlineBookingURLs()

  const isLoading = isLoadingOnlineBookingURLs || isLoadingBusinessLandingSettings

  if (isLoading) return <Spinner expandCentered />
  if (!businessLandingSettings || !onlineBookingURLs) return null

  return (
    <OnlineBookingURLsLogic
      businessLandingSettings={businessLandingSettings}
      onlineBookingURLs={onlineBookingURLs}
    />
  )
}
