import { ReviewType } from '@expane/data'
import { Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoSparklesOutline } from 'react-icons/io5'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'
import { CreatedAtCell } from './Cells/CreatedAtCell'
import { RatingCell } from './Cells/RatingCell'
import { PersonCell } from './Cells/PersonCell'
import { permissions } from '@expane/logic/permission'
import { ReviewActions } from 'widgets/ReviewActions'

interface ReviewsListProps {
  reviews: ReviewType[] | undefined
  myPermissions?: string[]
  isLoading: boolean
  archive?: boolean
}

export const ReviewsList: FC<ReviewsListProps> = memo(
  ({ reviews, myPermissions, isLoading, archive = false }) => {
    const { t } = useTranslation()

    const columns = useMemo<ColumnDef<ReviewType>[]>(() => {
      const cols: ColumnDef<ReviewType>[] = [
        {
          accessorKey: 'rating',
          header: t('rating'),
          cell: data => <RatingCell rating={data.row.original.rating} />,
        },
        {
          accessorKey: 'createdAt',
          header: t('dateTitle'),
          cell: data => <CreatedAtCell date={data.row.original.createdAt} />,
          size: 180,
        },
        {
          accessorKey: 'client',
          header: t('client.name'),
          cell: data => <PersonCell person={data.row.original.client} />,
          size: 180,
        },
        {
          accessorKey: 'text',
          header: t('review.name'),
          cell: data => data.getValue(),
          size: 400,
        },
        {
          accessorKey: 'approvedByEmployee',
          header: t('approved'),
          cell: data =>
            data.row.original.approvedByEmployee ? (
              <PersonCell person={data.row.original.approvedByEmployee} />
            ) : (
              '-'
            ),
          size: 180,
        },
      ]

      if (!archive && Boolean(myPermissions?.includes(permissions.review.set)))
        cols.push({
          accessorKey: 'approved',
          header: '',
          cell: data => <ReviewActions review={data.row.original} />,
          size: 160,
        })

      return cols
    }, [archive, myPermissions, t])

    if (reviews?.length === 0 && !isLoading)
      return <EmptyPlaceholder Icon={IoSparklesOutline} text={t('noItems')} />

    return <Table customCellContainer columns={columns} data={reviews} isLoading={isLoading} />
  },
)
