import { FC, useState } from 'react'
import { Controller, Control, useWatch } from 'react-hook-form'
import { ClientSelectDropdown } from 'widgets/ClientSelectDropdown'
import { BookingDialogFormValues } from '.'
import { useTranslation } from 'react-i18next'
import { BookingClientBlockWidget } from 'widgets/BookingClientBlockWidget'
import {
  useFetchClientById,
  useFetchClientsBriefs,
  useFetchClientsPhones,
  useFetchClientsWithFullNameFuzzSearch,
  useFetchCurrentBranchTimezone,
} from '@expane/data'
import { addPhonesToClients } from '@expane/logic/client'
import { permissions } from '@expane/logic/permission'
import { useFetchMyPermissions } from 'gql/employee'
import { store } from 'store'
import { Textarea } from '@expane/ui'

interface ClientBlockProps {
  control: Control<BookingDialogFormValues>
  disabled: boolean
  isSomeServicesPaid: boolean
}

export const ClientBlock: FC<ClientBlockProps> = ({ control, disabled, isSomeServicesPaid }) => {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState<string>('')

  const watchedClientId = useWatch({ control, name: 'clientId' })
  const watchedDate = useWatch({ control, name: 'startDate' })

  const branchId = store.branch.branchId
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: clients } = useFetchClientsBriefs(branchId, timezone, watchedClientId ?? undefined)
  const { data: clientsBySearchValue } = useFetchClientsWithFullNameFuzzSearch(
    searchValue,
    branchId,
    timezone,
  )
  const clientsToDisplay = searchValue ? clientsBySearchValue : clients
  const { data: clientsPhones } = useFetchClientsPhones()
  const { data: watchedClient } = useFetchClientById(watchedClientId ?? undefined, timezone)
  const { data: myPermissions } = useFetchMyPermissions()
  const tags = watchedClient?.clientTags.map(cT => cT.tag) ?? []

  const clientsWithPhones = addPhonesToClients(clientsToDisplay, clientsPhones)

  const isClientCreateAllowed = myPermissions?.includes(permissions.client.set)

  return (
    <div className="w-96 pr-2 relative">
      <Controller
        control={control}
        name="clientId"
        rules={{
          required: true,
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <ClientSelectDropdown
            value={value}
            onChange={onChange}
            clients={clientsWithPhones}
            disabled={disabled || isSomeServicesPaid}
            errorMessage={{ isShown: Boolean(error), text: t('formError.required') }}
            isCreateClient={isClientCreateAllowed}
            onSearchValueChange={setSearchValue}
            required
            tags={tags}
            customSearchPlaceholder={
              clients && clients.length === 0
                ? t('noItems')
                : clientsWithPhones.length === 0
                ? t('noClient')
                : undefined
            }
          />
        )}
      />

      <BookingClientBlockWidget watchedClientId={watchedClientId} watchedDate={watchedDate} />

      <Controller
        control={control}
        name="note"
        render={({ field: { value, onChange } }) => (
          <Textarea
            placeholder={t('placeholders.bookingComment')}
            label={t('note')}
            value={value}
            disabled={disabled}
            onChange={onChange}
            rows={1}
          />
        )}
      />
    </div>
  )
}
