import { CardType, useFetchCardsByClientId, useFetchCurrentBranchTimezone } from '@expane/data'
import { addDays } from '@expane/date'
import {
  checkIsFreeServicesInSubscription,
  getClientSubscriptions,
  getColorBySubscriptionStatus,
  getIsCardEndless,
  getSortedSubscriptions,
  getStatusSubscription,
  getSubscriptionInfo,
} from '@expane/logic/cards'
import { Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCardOutline } from 'react-icons/io5'
import { store } from 'store'
import { useOpenSubscriptionOptionsDialog } from 'widgets/ClientDialog/SubscriptionTab/SubscriptionOptionsDialog'
import { EmptyPlaceholder } from '../../EmptyPlaceholder'
import { SubscriptionServicesInfoIcon } from './InfoIcon'

interface Props {
  clientId: number
  isCardEditingAllowed: boolean
}

export const ClientDialogSubscriptionTab: FC<Props> = ({ clientId, isCardEditingAllowed }) => {
  const branchId = store.branch.branchId

  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: clientCards } = useFetchCardsByClientId(clientId, timezone, branchId)

  const { openEditDialog, dialog } = useOpenSubscriptionOptionsDialog()

  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()

  const clientSubscriptions = getClientSubscriptions(clientCards) ?? []

  const columns = useMemo<ColumnDef<CardType>[]>(
    () => [
      {
        id: 'infoIcon',
        cell: data => {
          const subscription = data.row.original

          return (
            <SubscriptionServicesInfoIcon
              subscriptionServices={subscription.cardTemplate.cardTemplateServices}
              usedServices={getSubscriptionInfo(subscription)}
            />
          )
        },
        size: 40,
      },
      {
        accessorKey: 'cardTemplate.name',
        header: t('name'),
        cell: data => data.getValue(),
        size: 400,
      },
      {
        id: 'status',
        accessorFn: subscription =>
          getStatusSubscription({
            activatedAt: subscription.activatedAt ?? undefined,
            canceledDate: subscription.canceledDate,
            cardPeriod: subscription.cardPeriod,
            isFreeServicesInSubscription: checkIsFreeServicesInSubscription(subscription),
            timezone,
          }),
        header: t('status'),
        cell: data => t(data.getValue<string>()),
        size: 200,
      },
      {
        accessorKey: 'activatedAt',
        header: t('dateOfActivation'),
        cell: data => dateFormatting('historyDate', data.getValue<Date | null>()),
        size: 200,
      },
      {
        id: 'deactivatedAt',
        accessorFn: subscription => {
          return subscription.activatedAt
            ? addDays(subscription.activatedAt, subscription.cardPeriod)
            : undefined
        },
        header: t('validUntil'),
        cell: data => {
          return getIsCardEndless(data.row.original.cardPeriod)
            ? t('unlimitedTime')
            : dateFormatting('historyDate', data.getValue<Date>())
        },
        size: 200,
      },
    ],
    [dateFormatting, t, timezone],
  )

  const sortedExtendedClientsSubscriptions = getSortedSubscriptions(clientSubscriptions, timezone)

  return (
    <>
      {sortedExtendedClientsSubscriptions.length > 0 ? (
        <Table
          columns={columns}
          data={sortedExtendedClientsSubscriptions}
          onRowClick={
            isCardEditingAllowed
              ? subscription => openEditDialog({ id: subscription.id, clientSubscriptions })
              : undefined
          }
          customRowClassName={subscription => {
            const status = getStatusSubscription({
              activatedAt: subscription.activatedAt ?? undefined,
              canceledDate: subscription.canceledDate,
              cardPeriod: subscription.cardPeriod,
              isFreeServicesInSubscription: checkIsFreeServicesInSubscription(subscription),
              timezone,
            })

            return getColorBySubscriptionStatus(status)
          }}
        />
      ) : (
        <EmptyPlaceholder Icon={IoCardOutline} text={t('noSubscriptions')} />
      )}
      {dialog}
    </>
  )
}
