import { expaneViberBotLink } from '@expane/logic/links'
import { Button } from '@expane/ui'
import { config } from 'config'
import { NotificationSettingsBlock } from 'pages/SettingsPage/NotificationsSettings/index'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IoQrCodeOutline } from 'react-icons/io5'
import { URLContainer } from 'ui/URLContainer'
import { ViberIcon } from 'ui/Icons'
import { useOpenQrCodeDialog } from 'widgets/QrCodeDialog'

export const ViberIntegrationSettings: FC = () => {
  const { t } = useTranslation()

  const { dialog: qrCodeDialog, openDialog: openQrCodeDialog } = useOpenQrCodeDialog()

  const url = expaneViberBotLink(config.ENV)

  return url ? (
    <NotificationSettingsBlock
      title={t('integration.viber')}
      icon={<ViberIcon size={'1.3rem'} />}
      className={'mt-2'}
    >
      <div className="flex items-center gap-1 text-secondary-color">
        <p>{t('link')}: </p>
        <URLContainer url={url} />
        <Button
          className="ml-auto"
          type="outline"
          onClick={() => openQrCodeDialog({ text: url, title: `Viber ${t('QRcode')}` })}
          Icon={IoQrCodeOutline}
        >
          {t('QRcode')}
        </Button>
      </div>

      {qrCodeDialog}
    </NotificationSettingsBlock>
  ) : null
}
