import { FC } from 'react'
import { SettingsWrapper } from 'pages/SettingsPage'
import { useFetchBusinessLandingSettings } from '@expane/data'
import { Spinner } from '@expane/ui'
import { BusinessLandingSettingsLogic } from 'pages/SettingsPage/BusinessLandingSettings/BusinessLandingSettingsLogic'
import { useFetchMyPermissions } from 'gql/employee'

export const BusinessLandingSettings: FC = () => {
  const { data: businessLandingSettings, isLoading: isLoadingBusinessLandingSettings } =
    useFetchBusinessLandingSettings()
  const { data: myPermissions, isLoading: isLoadingMyPermissions } = useFetchMyPermissions()

  const isLoading = isLoadingBusinessLandingSettings || isLoadingMyPermissions

  return (
    <SettingsWrapper>
      {isLoading ? (
        <Spinner expandCentered />
      ) : businessLandingSettings && myPermissions ? (
        <BusinessLandingSettingsLogic
          businessLandingSettings={businessLandingSettings}
          myPermissions={myPermissions}
        />
      ) : null}
    </SettingsWrapper>
  )
}
