import { useFetchNotificationsAggregateByEmployeeId } from '@expane/data'
import { Spinner } from '@expane/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { IoNotifications, IoNotificationsOutline } from 'react-icons/io5'
import { store } from 'store'
import { useOpenBookingDialog } from 'widgets/BookingDialog'
import { useOpenNotificationDialog } from 'widgets/NotificationDialog'

export const NotificationButton: FC = observer(() => {
  const { openNotificationDialog, notificationDialog, closeDialog } = useOpenNotificationDialog()
  const { openEditBookingDialog, bookingDialog } = useOpenBookingDialog()

  const {
    data: notificationCount,
    isLoading,
    isRefetching,
  } = useFetchNotificationsAggregateByEmployeeId(store.me.employeeId)

  const handleClick = () => {
    if (!notificationDialog) openNotificationDialog(openEditBookingDialog)
    else closeDialog()
  }

  return (
    <>
      <div
        className="relative h-8 w-8 text-primary-500 bg-hover rounded-md flex-centered cursor-pointer"
        onClick={handleClick}
      >
        {notificationDialog ? (
          <IoNotifications size="1.5rem" />
        ) : (
          <IoNotificationsOutline size="1.5rem" />
        )}

        {(isLoading || isRefetching) && (
          <div className="absolute">
            <Spinner />
          </div>
        )}

        {notificationCount !== undefined && notificationCount > 0 && (
          <div className="absolute -top-1 -right-1 text-xs text-white rounded-full bg-primary-400 w-5 h-5 p-0.5 flex-centered">
            <p className="truncate">{notificationCount}</p>
          </div>
        )}
      </div>

      {notificationDialog}
      {bookingDialog}
    </>
  )
})
