import {
  ServerExtendedServiceType,
  useArchiveService,
  useFetchDeactivatedServiceGroups,
  useResetServiceServiceGroup,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import { findById } from '@expane/logic/utils'
import {
  Input,
  OpenButton,
  Textarea,
  useShowConfirmationPopup,
  useShowWarningPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowArchiveConfirmationPopup } from 'logic/hooks/popup/useShowArchiveConfirmationPopup'
import { PageDataType } from 'pages/CreateBusinessPage/logic'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { reportError } from 'services/sentry'
import { store } from 'store'
import { Avatar } from 'ui/Avatar'
import { ArchiveButton, RestoreButton } from 'widgets/Buttons'

interface Props {
  editService: (id: number) => void
  service: ServerExtendedServiceType | undefined
  type?: PageDataType
}

export const ServiceQuickInfo: FC<Props> = ({ service, editService, type = 'page' }) => {
  const branchId = store.branch.branchId
  const { t } = useTranslation()

  const { data: myPermissions } = useFetchMyPermissions()

  const { mutateAsync: updateArchiveMutation } = useArchiveService()

  const { mutateAsync: resetServiceServiceGroup } = useResetServiceServiceGroup()
  const { data: deactivatedServiceGroups } = useFetchDeactivatedServiceGroups(branchId)

  const [openSnackbar] = useSnackbar()

  const mutateArchiveService = async (archive: boolean) => {
    if (!service?.id) {
      openSnackbar(t('submitError'), 'error')

      return
    }

    const result = await updateArchiveMutation({ id: service.id, archive: archive })

    if (result?.updateServiceArchives?.affected_rows)
      openSnackbar(
        archive
          ? t('service.archivingSuccessful', { name: service?.name })
          : t('service.restorationSuccessful', { name: service?.name }),
        'success',
      )
    else openSnackbar(t('submitError'), 'error')
  }

  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()

  const { warningModal, showWarningPopup } = useShowWarningPopup(
    t('warning'),
    t('serviceError.cannotArchived'),
  )

  const { archiveConfirmationModal, showArchiveConfirmationPopup } =
    useShowArchiveConfirmationPopup(service?.name ?? '', () => mutateArchiveService(true))

  const handleOnRestore = async () => {
    const groupId = service?.groupId

    const isGroupDeactivated = Boolean(groupId && findById(groupId, deactivatedServiceGroups))

    if (isGroupDeactivated) {
      showConfirmation({
        title: t('restore.name'),
        description: t('serviceError.restoreWithoutCategory'),
        onConfirm: async () => {
          if (service?.id) await resetServiceServiceGroup(service.id)
          await mutateArchiveService(false)
        },
      })
    } else await mutateArchiveService(false)
  }

  const isServiceInCardTemplate = Boolean(service?.cardTemplateServices.length)

  const handleOnArchive = async () => {
    if (isServiceInCardTemplate) showWarningPopup()
    else showArchiveConfirmationPopup()
  }

  const isArchivingAllowed = myPermissions?.includes(permissions.service.archive)

  const treeMenuWidth = type === 'page' ? 'w-1/4' : 'w-2/3'

  if (service === undefined) return null

  return (
    <div className={'py-5 px-2 ' + treeMenuWidth}>
      <div className="w-full flex-centered mb-2">
        {type !== 'createBusiness' && (
          <Avatar
            name={service?.name ?? ''}
            type="big"
            url={service?.picture ?? ''}
            reportErrorHandlerFunction={reportError}
          />
        )}
      </div>
      <div className="flex">
        <Input
          label={t('title')}
          containerClassName="flex-1 mr-2"
          value={service?.name ?? ''}
          readOnly
          disabled
        />
        <Input
          label={t('price')}
          containerClassName="flex-1"
          value={service?.price.toString() ?? '0'}
          readOnly
          disabled
        />
      </div>
      <div className="flex mt-2">
        <Input
          label={t('costPrice')}
          value={service?.costPrice.toString() ?? '0'}
          readOnly
          disabled
          containerClassName="flex-1 mr-2"
        />
        <Input
          label={t('duration')}
          value={service?.defaultDuration.toString() ?? '0'}
          containerClassName="flex-1"
          disabled
          readOnly
        />
      </div>
      <Textarea
        placeholder=""
        containerClassName="mt-2"
        label={t('description')}
        value={service?.description ?? ''}
        readOnly
        disabled
        rows={type !== 'createBusiness' ? 4 : 2}
      />
      <div
        className={`flex ${
          service.archived || !isArchivingAllowed ? 'justify-end' : 'justify-between'
        } mt-5`}
      >
        {isArchivingAllowed &&
          !service.availableToAllBranches &&
          type !== 'createBusiness' &&
          (!service.archived ? (
            <ArchiveButton className="mr-auto" onClick={handleOnArchive} />
          ) : (
            <RestoreButton onClick={() => handleOnRestore()} />
          ))}

        {service.archived ? null : <OpenButton onClick={() => editService(service.id)} />}
      </div>

      {archiveConfirmationModal}
      {confirmationModal}
      {warningModal}
    </div>
  )
}
