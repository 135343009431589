import {
  BranchWithSchedule,
  InterbranchServiceInsertInput,
  ServerEmployeeAllBranchesGroupType,
  ServerInterbranchServiceByIdType,
  SERVICE_TYPE,
  ServiceEmployeeInsertInput,
  ServiceLocationInsertInput,
  ServiceProductInsertInput,
  ServiceUpdateDto,
} from '@expane/data'
import { Consumable } from '@expane/logic/payment/booking'
import {
  getConsumablesDiff,
  getEmployeeIdsDiff,
  getEmployeeIdsThatWereUsedHashMap,
  getLocationIdsDiff,
} from '@expane/logic/service'
import { SelectDropDownItem } from '@expane/ui'
import { provideDefaultSelectedForEmployeeItems } from 'logic/service'
import { filterByBranchId } from 'logic/utils'
import { Control, UseFormGetFieldState, UseFormSetValue, UseFormTrigger } from 'react-hook-form'
import { TFunction } from 'react-i18next'
import { TreeMenuItem } from 'ui/TreeMenu'
import { extractItemsFromFolders, groupInitialTreeItems } from 'ui/TreeMenu/logic.common'
import {
  convertEmployeeGroupsToTreeItems,
  generateDefaultServiceEmployees,
} from 'ui/TreeMenu/logic.employee'

export const interbranchServiceTypes: SelectDropDownItem[] = [
  { id: SERVICE_TYPE.service, name: 'serviceType.forBooking' },
  { id: SERVICE_TYPE.group, name: 'serviceType.group' },
]

export type InterbranchServiceFormControl = Control<InterbranchServiceDialogFormData>
export type InterbranchServiceFormSetValue = UseFormSetValue<InterbranchServiceDialogFormData>
export type InterbranchServiceFormTrigger = UseFormTrigger<InterbranchServiceDialogFormData>
export type InterbranchServiceFormGetFieldState =
  UseFormGetFieldState<InterbranchServiceDialogFormData>

export interface BranchService {
  branchId: number
  branchName: string
  locations: SelectDropDownItem[]
  employees: TreeMenuItem[]
  consumables: Consumable[]
}

export interface InterbranchServiceDialogFormData {
  name: string
  defaultDuration: string
  description: string
  price: string
  costPrice: string
  picture: string
  color: string | undefined
  type: number
  availableForClient: boolean
  branchServices: BranchService[]
}

export const getDefaultEmployees = (
  serviceById: ServerInterbranchServiceByIdType | undefined,
  employeeTreeItems: TreeMenuItem[],
  branchId: number,
) => {
  if (!serviceById) return []

  const employeeIds: number[] = []
  for (const serviceEmployee of serviceById.serviceEmployees) {
    if (!serviceEmployee.employee) continue
    if (serviceEmployee.branchId === branchId) employeeIds.push(serviceEmployee.employee.id)
  }

  return groupInitialTreeItems(
    generateDefaultServiceEmployees(employeeIds, employeeTreeItems),
    employeeTreeItems,
  )
}

export const generateBranchServices = (
  branches: BranchWithSchedule[],
  interbranchServiceById: ServerInterbranchServiceByIdType | undefined,
  employeeGroups: ServerEmployeeAllBranchesGroupType[],
  t: TFunction,
): BranchService[] => {
  const branchServices: BranchService[] = []

  for (const branch of branches) {
    const locations: SelectDropDownItem[] = []
    const employees: TreeMenuItem[] = []
    const consumables: Consumable[] = []

    if (interbranchServiceById) {
      // Locations
      for (const serviceLocations of interbranchServiceById.serviceLocations) {
        if (serviceLocations.branchId === branch.id) {
          locations.push(serviceLocations.location)
        }
      }

      // Employees
      const employeeTreeMenuItems = convertEmployeeGroupsToTreeItems(employeeGroups)
      const employeeIdsHashMap = getEmployeeIdsThatWereUsedHashMap(interbranchServiceById)
      const employeesWithDisabled = provideDefaultSelectedForEmployeeItems(
        employeeTreeMenuItems,
        employeeIdsHashMap,
        t,
      )
      employees.push(
        ...getDefaultEmployees(interbranchServiceById, employeesWithDisabled, branch.id),
      )

      // Consumables
      for (const serviceProduct of interbranchServiceById.serviceProducts) {
        if (serviceProduct.branchId === branch.id) {
          consumables.push({
            productId: serviceProduct.productId,
            quantity: serviceProduct.quantity.toString(),
            bookingProductId: undefined,
          })
        }
      }
    }

    branchServices.push({
      branchId: branch.id,
      branchName: branch.name,
      employees,
      locations,
      consumables,
    })
  }

  return branchServices
}

const transformFormDataInterbranchServiceDependentDataForCreate = (
  branchServices: BranchService[],
): {
  serviceLocationInsertInput: ServiceLocationInsertInput[]
  serviceProductInsertInput: ServiceProductInsertInput[]
  serviceEmployeeInsertInput: ServiceEmployeeInsertInput[]
} => {
  const serviceLocationInsertInput: ServiceLocationInsertInput[] = []
  const serviceProductInsertInput: ServiceProductInsertInput[] = []
  const serviceEmployeeInsertInput: ServiceEmployeeInsertInput[] = []

  for (const branchService of branchServices) {
    for (const location of branchService.locations)
      serviceLocationInsertInput.push({
        branchId: branchService.branchId,
        locationId: location.id,
      })

    for (const consumable of branchService.consumables)
      serviceProductInsertInput.push({
        branchId: branchService.branchId,
        productId: Number(consumable.productId),
        quantity: Number(consumable.quantity),
      })

    const employees = extractItemsFromFolders(branchService.employees)

    for (const employee of employees) {
      serviceEmployeeInsertInput.push({
        branchId: branchService.branchId,
        employeeId: employee.id,
      })
    }
  }

  return { serviceLocationInsertInput, serviceProductInsertInput, serviceEmployeeInsertInput }
}

export const transformFormDataToInterbranchServiceCreate = (
  data: InterbranchServiceDialogFormData,
  pictureUrl: string | undefined,
): InterbranchServiceInsertInput => {
  const {
    name,
    type,
    description,
    defaultDuration,
    costPrice,
    price,
    color,
    availableForClient,
    branchServices,
  } = data

  const { serviceLocationInsertInput, serviceProductInsertInput, serviceEmployeeInsertInput } =
    transformFormDataInterbranchServiceDependentDataForCreate(branchServices)

  return {
    name,
    type,
    description,
    defaultDuration: Number(defaultDuration),
    costPrice: Number(costPrice),
    price: Number(price),
    color,
    picture: pictureUrl,
    availableForClient,
    availableToAllBranches: true,
    serviceLocations: { data: serviceLocationInsertInput },
    serviceProducts: { data: serviceProductInsertInput },
    serviceEmployees: { data: serviceEmployeeInsertInput },
  }
}

const transformLocationIdsToInsert = (
  ids: number[],
  branchId: number,
  serviceId: number,
): ServiceLocationInsertInput[] => {
  const locations: ServiceLocationInsertInput[] = []

  for (const locationId of ids) {
    locations.push({ locationId, branchId, serviceId })
  }

  return locations
}

const transformEmployeeIdsToInsert = (
  ids: number[],
  branchId: number,
  serviceId: number,
): ServiceEmployeeInsertInput[] => {
  const employees: ServiceEmployeeInsertInput[] = []

  for (const employeeId of ids) {
    employees.push({ employeeId, branchId, serviceId })
  }

  return employees
}

const transformConsumablesToInsert = (
  consumables: Consumable[],
  branchId: number,
  serviceId: number,
): ServiceProductInsertInput[] => {
  const consumableInsertInputs: ServiceProductInsertInput[] = []

  for (const consumable of consumables) {
    consumableInsertInputs.push({
      productId: Number(consumable.productId),
      quantity: Number(consumable.quantity),
      branchId,
      serviceId,
    })
  }

  return consumableInsertInputs
}

const transformFormDataInterbranchServiceDependentDataForUpdate = (
  branchServices: BranchService[],
  interbranchServiceById: ServerInterbranchServiceByIdType,
): {
  locationIdsToDelete: number[]
  serviceLocationInsertInputs: ServiceLocationInsertInput[]
  serviceEmployeeIdsToDelete: number[]
  serviceEmployeeInsertInputs: ServiceEmployeeInsertInput[]
  consumableIdsToDelete: number[]
  serviceProductInsertInputs: ServiceProductInsertInput[]
} => {
  const locationIdsToDelete: number[] = []
  const serviceLocationInsertInputs: ServiceLocationInsertInput[] = []
  const serviceEmployeeIdsToDelete: number[] = []
  const serviceEmployeeInsertInputs: ServiceEmployeeInsertInput[] = []
  const consumableIdsToDelete: number[] = []
  const serviceProductInsertInputs: ServiceProductInsertInput[] = []

  for (const branchService of branchServices) {
    // Locations
    const chosenLocationsIds = branchService.locations.map(location => location.id)
    const { locationIdsToDelete: locationIdsToDeleteByBranch, locationIdsToInsert } =
      getLocationIdsDiff(
        filterByBranchId(interbranchServiceById.serviceLocations, branchService.branchId),
        chosenLocationsIds,
      )
    locationIdsToDelete.push(...locationIdsToDeleteByBranch)
    serviceLocationInsertInputs.push(
      ...transformLocationIdsToInsert(
        locationIdsToInsert,
        branchService.branchId,
        interbranchServiceById.id,
      ),
    )
    // Employees
    const chosenEmployeesIds = extractItemsFromFolders(branchService.employees).map(
      employee => employee.id,
    )
    const {
      serviceEmployeeIdsToDelete: serviceEmployeeIdsToDeleteByBranchId,
      employeeIdsToInsert,
    } = getEmployeeIdsDiff(
      filterByBranchId(interbranchServiceById.serviceEmployees, branchService.branchId),
      chosenEmployeesIds,
    )
    serviceEmployeeIdsToDelete.push(...serviceEmployeeIdsToDeleteByBranchId)
    serviceEmployeeInsertInputs.push(
      ...transformEmployeeIdsToInsert(
        employeeIdsToInsert,
        branchService.branchId,
        interbranchServiceById.id,
      ),
    )
    // Consumables
    const { consumableIdsToDelete: consumableIdsToDeleteByBranchId, consumablesToInsert } =
      getConsumablesDiff(
        filterByBranchId(interbranchServiceById.serviceProducts, branchService.branchId),
        branchService.consumables,
      )

    consumableIdsToDelete.push(...consumableIdsToDeleteByBranchId)
    serviceProductInsertInputs.push(
      ...transformConsumablesToInsert(
        consumablesToInsert,
        branchService.branchId,
        interbranchServiceById.id,
      ),
    )
  }

  return {
    locationIdsToDelete,
    serviceLocationInsertInputs,
    serviceEmployeeIdsToDelete,
    serviceEmployeeInsertInputs,
    consumableIdsToDelete,
    serviceProductInsertInputs,
  }
}

export const transformFormDataToInterbranchServiceUpdate = (
  data: InterbranchServiceDialogFormData,
  interbranchServiceById: ServerInterbranchServiceByIdType,
  pictureUrl: string | undefined,
): ServiceUpdateDto => {
  const {
    name,
    type,
    description,
    defaultDuration,
    costPrice,
    price,
    color,
    availableForClient,
    branchServices,
  } = data

  const {
    locationIdsToDelete,
    serviceLocationInsertInputs,
    serviceEmployeeIdsToDelete,
    serviceEmployeeInsertInputs,
    consumableIdsToDelete,
    serviceProductInsertInputs,
  } = transformFormDataInterbranchServiceDependentDataForUpdate(
    branchServices,
    interbranchServiceById,
  )

  return {
    id: interbranchServiceById.id,
    serviceSetInput: {
      name,
      description,
      price: Number(price),
      costPrice: Number(costPrice),
      defaultDuration: Number(defaultDuration),
      picture: pictureUrl,
      color,
      type,
      availableForClient,
    },
    // Locations
    locationIdsToDelete,
    serviceLocationInsertInputs,
    // Employees
    serviceEmployeeIdsToDelete,
    serviceEmployeeInsertInputs,
    // Consumables
    consumableIdsToDelete,
    serviceProductInsertInputs,
    // Permissions
    permissionEmployeeIdsToDelete: [],
    servicePermissionInsertInputs: [],
  }
}
