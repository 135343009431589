import {
  ServerCheckboxCreateBulkReceiptsResult,
  ServerCheckboxCreateReceiptResult,
  ServerCloudFunctionResult,
  ServerCreateReceiptDto,
  ServerCreateServiceReceiptType,
} from '../../generated/graphql-types'

export const CHECKBOX_API_URL = 'https://api.checkbox.in.ua/api/v1/'

export const getReceiptPngUrl = (receiptId: string) =>
  CHECKBOX_API_URL + `receipts/${receiptId}/png`

export const getReceiptPdfUrl = (receiptId: string) =>
  CHECKBOX_API_URL + `receipts/${receiptId}/pdf`

export enum CheckboxResponseCodes {
  insufficientPermissions = 1,
  noSuchAccount,
  insufficientData,
  successful,
  noCredentials,
  noToken,
  checkboxError,
  checkboxCredentialsError,
  apiError,
  noLicenseKey,
  thereAreNoOpenShifts,
}

export interface UpdatePOSSetInput {
  licenseKey?: string
  login?: string
  name?: string
  password?: string
  softwarePOSId: number
  accounts?: number[]
  branchId: number
}

export interface CheckboxResult extends ServerCloudFunctionResult {
  code: CheckboxResponseCodes
}

export interface CheckboxCreateReceiptResult extends ServerCheckboxCreateReceiptResult {
  code: CheckboxResponseCodes
}

export interface CheckboxCreateBulkReceiptsResult extends ServerCheckboxCreateBulkReceiptsResult {
  code: CheckboxResponseCodes
}

export interface DataForCheckboxReceipt {
  softwarePOSId: number
  clientId: number
  receipt: Pick<ServerCreateReceiptDto, 'goods' | 'payments' | 'receiptId'>
}

export type CheckboxReturnReceipt = ServerCreateReceiptDto &
  Required<Pick<ServerCreateReceiptDto, 'relatedReceiptId' | 'receiptId'>> & { isReturn: true }

export interface DataForCheckboxReturnReceipt {
  softwarePOSId: number
  clientId: number
  receipt: CheckboxReturnReceipt
}

export interface DataForCheckboxBulkReturnReceipts {
  softwarePOSId: number
  clientId: number
  receipts: CheckboxReturnReceipt[]
}

export interface DataForCheckboxServiceReceipt {
  softwarePOSId: number
  type: ServerCreateServiceReceiptType
  value: number
}
