import { gql } from 'graphql-request'
import { queryClient, request, useMutation, reportGqlError } from '../../api'
import {
  ServerArchiveTypes,
  ServerLocationGroupInsertInput,
  ServerLocationInsertInput,
  ServerLocationSetInput,
} from '../../generated/graphql-types'
import { LOCATIONS_QUERY_KEY } from './queryKeys'
import { LOCATION_GROUPS_QUERY_KEY } from '../locationGroup/queryKeys'
import { ArchiveLocationResult } from './logic'

export function useCreateLocation() {
  return useMutation(
    async (
      locationInsertInput: ServerLocationInsertInput &
        Required<Pick<ServerLocationInsertInput, 'branchId' | 'name'>>,
    ): Promise<{ insertLocation?: { id?: number }; serviceLocations: { serviceId: number }[] }> => {
      return request(
        gql`
          mutation ($locationInsertInput: location_insert_input!) {
            insertLocation(object: $locationInsertInput) {
              id
            }
          }
        `,
        { locationInsertInput },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(LOCATIONS_QUERY_KEY),
        })
        queryClient.invalidateQueries([LOCATION_GROUPS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateLocation() {
  return useMutation(
    async (dto: {
      id: number
      locationSetInput: ServerLocationSetInput
    }): Promise<{ updateLocationById?: { id?: number } }> => {
      return request(
        gql`
          mutation ($id: Int!, $locationSetInput: location_set_input!) {
            updateLocationById(pk_columns: { id: $id }, _set: $locationSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(LOCATIONS_QUERY_KEY),
        })
        queryClient.invalidateQueries([LOCATION_GROUPS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useImportLocations() {
  return useMutation(
    async (dto: {
      locationGroupInsertInputs: ServerLocationGroupInsertInput[]
      locationInsertInputs: ServerLocationInsertInput[]
    }): Promise<{
      insertLocations: { affectedRows: number }
      insertMultipleLocationGroups: { affectedRows: number }
    }> => {
      return request(
        gql`
          mutation (
            $locationGroupInsertInputs: [locationGroup_insert_input!]!
            $locationInsertInputs: [location_insert_input!]!
          ) {
            insertLocations(objects: $locationInsertInputs) {
              affectedRows: affected_rows
            }
            insertMultipleLocationGroups(objects: $locationGroupInsertInputs) {
              affectedRows: affected_rows
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(LOCATIONS_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(LOCATION_GROUPS_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}

export function useArchiveLocation() {
  return useMutation(
    async (dto: {
      locationId: number
      archiveType: ServerArchiveTypes
    }): Promise<{ archiveLocation: ArchiveLocationResult }> => {
      return request(
        gql`
          mutation ($locationId: Int!, $archiveType: ArchiveTypes!) {
            archiveLocation(locationId: $locationId, archiveType: $archiveType) {
              message
              code
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: (_, { archiveType }) => {
        if (archiveType === ServerArchiveTypes.Check) return
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(LOCATION_GROUPS_QUERY_KEY),
        })
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(LOCATIONS_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}
