import {
  BookingWarningsTypes,
  GroupBooking,
  invalidateBookings,
  ServerRecurringBookingByIdType,
  useCancelBooking,
  useCancelBookings,
  useCreateBooking,
  useCreateRecurringBookings,
  useFetchBookingById,
  useFetchClientsBriefs,
  useFetchCurrentBranchTimezone,
  useUpdateBooking,
  useUpdateGroupBookings,
  validateBooking,
} from '@expane/data'
import { set, zonedTimeToUtc } from '@expane/date'
import { generateMessageAfterValidation } from '@expane/logic/bookingChecks'
import { transformPersonName } from '@expane/logic/utils'
import {
  Button,
  CancelButton,
  CloseButton,
  Dialog,
  RadioGroupOption,
  useShowConfirmationPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useShowCancelBookingPopup } from 'logic/hooks/popup/useShowCancelBookingPopup'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import {
  Control,
  SubmitHandler,
  UseFormHandleSubmit,
  useFormState,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoDocumentsOutline, IoWalletOutline } from 'react-icons/io5'
import { store } from 'store'
import { SaveButton } from 'widgets/Buttons'
import { ConfirmationDescription } from 'widgets/ConfirmationDescription'
import { GroupBookingDialogFormValues } from '.'
import { GroupBookingPaymentDialog } from './GroupBookingPaymentDialog'
import { RecurringEditType, useOpenRecurringEditDialog } from 'widgets/RecurringEditDialog'
import {
  getIsDayOfBookingStartDateChanged,
  getRecurringBookingIdsThatCanBeCanceled,
  getRecurringBookingThatCanBeEdited,
} from '@expane/logic/booking'
import { getClientsToBeRemovedAndInsertedIds } from 'widgets/BookingDialog/logic'
import {
  getAreRecurringBookingsWithInvalidDate,
  getRecurringBookingsDates,
} from '@expane/logic/recurringBookings'

type Props = {
  id: number | undefined
  isCreate: boolean
  control: Control<GroupBookingDialogFormValues>
  handleSubmit: UseFormHandleSubmit<GroupBookingDialogFormValues>
  isEditable: boolean
  isBookingPaid: boolean
  formIsDisabled: boolean
  isTransactionsGetAllowed: boolean
  closeDialog: () => void
  closePopups: () => void
}

export const GroupBookingFooter: FC<Props> = ({
  id,
  isCreate,
  isEditable,
  isBookingPaid,
  isTransactionsGetAllowed,
  formIsDisabled,
  control,
  closeDialog,
  closePopups,
  handleSubmit,
}) => {
  const { t } = useTranslation()
  const [openSnackbar] = useSnackbar()
  const { confirmationModal, showConfirmation } = useShowConfirmationPopup()
  const {
    confirmationModal: clientsConfirmationModal,
    showConfirmation: showClientsConfirmationModal,
  } = useShowConfirmationPopup()
  const { cancelBookingModal, showConfirmCancelBooking } = useShowCancelBookingPopup()

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!
  const timezone = useFetchCurrentBranchTimezone(branchId)
  const { data: groupBookingById } = useFetchBookingById(id, timezone, branchId)
  const { data: clients } = useFetchClientsBriefs(branchId, timezone)

  const { mutateAsync: createGroupBooking } = useCreateBooking()
  const { mutateAsync: updateGroupBooking } = useUpdateBooking()

  const { mutateAsync: cancelBooking } = useCancelBooking()
  const { mutateAsync: cancelBookings } = useCancelBookings()

  const { mutateAsync: createRecurringBookings, isLoading } = useCreateRecurringBookings()
  const { mutateAsync: updateRecurringBookings } = useUpdateGroupBookings()

  const { dialog: groupBookingPaymentDialog, openEditDialog: openGroupBookingPaymentDialog } =
    useOpenDialog(GroupBookingPaymentDialog)

  const { recurringEditDialog, openRecurringEditDialog } = useOpenRecurringEditDialog()

  const { isSubmitting, isDirty, dirtyFields } = useFormState({ control })

  const mutateGroupBooking = async (data: GroupBookingDialogFormValues, onSuccess: () => void) => {
    const startDate = timezone ? zonedTimeToUtc(data.startDate, timezone) : data.startDate

    const validationResult = await validateBooking({
      bookingId: id ?? null,
      isGroupBooking: true,
      clientIds: data.clients.map(client => client.id),
      clientId: null,
      serviceId: data.serviceId ?? null,
      serviceIds: null,
      locationId: data.locationId,
      employeeId: data.employeeId ?? null,
      startDate,
      duration: Number(data.duration),
      branchId,
    })

    const confirmValidation = async () => {
      if (isCreate) {
        try {
          await createGroupBooking({
            locationId: data.locationId,
            employeeId: data.employeeId,
            duration: Number(data.duration),
            startDate,
            serviceId: data.serviceId,
            bookingClients: { data: data.clients.map(client => ({ clientId: client.id })) },
            isGroupBooking: true,
            note: data.note,
            branchId,
          })

          openSnackbar(t('groupBooking.createSuccess'), 'success', 3000)
        } catch (error) {
          openSnackbar(t('submitError'), 'error', 3000)
        }
      } else {
        try {
          const { bookingClientsToBeRemovedIds, bookingClientsToBeInserted } =
            getClientsToBeRemovedAndInsertedIds(groupBookingById as GroupBooking, data.clients)

          await updateGroupBooking({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            id: id!,
            bookingSetInput: {
              startDate,
              duration: Number(data.duration),
              employeeId: data.employeeId,
              locationId: data.locationId,
              serviceId: data.serviceId,
              note: data.note,
            },
            oldStartDate: groupBookingById?.startDate ?? new Date(),
            bookingClientInsertInput: dirtyFields.clients
              ? bookingClientsToBeInserted.map(client => ({
                  clientId: client.id,
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  bookingId: id!,
                }))
              : undefined,
            bookingClientsToBeRemovedIds: dirtyFields.clients
              ? bookingClientsToBeRemovedIds
              : undefined,
            branchId,
          })
          openSnackbar(t('groupBooking.updateSuccess'), 'success', 3000)
        } catch (error) {
          openSnackbar(t('submitError'), 'error', 3000)
        }
      }
      onSuccess()
    }

    if (!validationResult.type) await confirmValidation()

    if (validationResult.type) {
      // if this is edit and we didn't change the date, no need to display warning about busy employee
      if (
        (validationResult.type === BookingWarningsTypes.employeesAreBusy ||
          BookingWarningsTypes.employeesNotWorking) &&
        !dirtyFields.startDate &&
        !isCreate
      ) {
        await confirmValidation()
        return
      }

      const clientNames = validationResult.busyClientIds
        ? clients?.reduce((names, client) => {
            if (validationResult.busyClientIds?.includes(client.id)) {
              names.push(transformPersonName(client))
            }
            return names
          }, [] as string[])
        : undefined

      const warning = generateMessageAfterValidation({
        clientNames,
        warningType: validationResult.type,
        t,
      })
      showConfirmation({
        title: isCreate ? t('creating') : t('editing'),
        description: (
          <ConfirmationDescription
            text={warning}
            question={`${
              isCreate ? t('groupBooking.createConfirmation') : t('groupBooking.updateConfirmation')
            }`}
          />
        ),
        onConfirm: confirmValidation,
      })
    }
  }

  const handleMultiBooking: SubmitHandler<GroupBookingDialogFormValues> = async data => {
    const startDate = timezone ? zonedTimeToUtc(data.startDate, timezone) : data.startDate

    const confirmValidation = () => {
      store.multiBooking.setFirstBooking({
        locationId: data.locationId,
        employeeId: data.employeeId,
        duration: Number(data.duration),
        startDate: data.startDate,
        serviceId: data.serviceId,
        bookingClients: { data: data.clients.map(client => ({ clientId: client.id })) },
        isGroupBooking: true,
        note: data.note,
      })
      closeDialog()
    }

    const { type } = await validateBooking({
      bookingId: id ?? null,
      isGroupBooking: true,
      clientIds: data.clients.map(client => client.id),
      clientId: null,
      serviceId: data.serviceId ?? null,
      serviceIds: null,
      locationId: data.locationId,
      employeeId: data.employeeId ?? null,
      startDate,
      duration: Number(data.duration),
      branchId,
    })
    if (type === undefined || type === null) {
      confirmValidation()
    } else {
      const warning = generateMessageAfterValidation({ warningType: type, t })
      showConfirmation({
        title: t('create'),
        onConfirm: confirmValidation,
        description: (
          <ConfirmationDescription text={warning} question={t('bookingValidation.confirmation')} />
        ),
      })
    }
  }

  const cancelExistingBooking = async (reason: string | null) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const result = await cancelBooking({ id: id!, canceledReason: reason })
    if (result?.updateBookingById?.id) {
      openSnackbar(t('cancelBooking.success'), 'success')
    } else {
      openSnackbar(t('submitError'), 'error')
    }

    closeDialog()
  }

  const cancelExistingRecurringBooking = async (
    type: RadioGroupOption | undefined,
    recurringBookings: ServerRecurringBookingByIdType[],
    reason: string | null,
  ) => {
    if (type && type.id === RecurringEditType.single) {
      await cancelExistingBooking(reason)
    } else if (groupBookingById?.recurringBooking?.id) {
      const recurringBookingIdsToCancel = getRecurringBookingIdsThatCanBeCanceled(recurringBookings)

      const result = await cancelBookings({
        ids: recurringBookingIdsToCancel,
        canceledReason: reason,
      })

      if (result?.updateBookings?.affectedRows) {
        openSnackbar(t('cancelBooking.success'), 'success')
      } else {
        openSnackbar(t('submitError'), 'error')
      }
    }

    closeDialog()
  }

  const handleCancelBooking = () => {
    if (groupBookingById?.recurringBooking?.id) {
      openRecurringEditDialog({
        recurringId: groupBookingById.recurringBooking.id,
        startDate: groupBookingById.startDate,
        onSave: (type, recurringBookings) =>
          showConfirmCancelBooking({
            onConfirm: reason => cancelExistingRecurringBooking(type, recurringBookings, reason),
          }),
      })
    } else
      showConfirmCancelBooking({
        onConfirm: cancelExistingBooking,
      })
  }

  const submitRecurringBookings = async (
    data: GroupBookingDialogFormValues,
    dates: Date[],
    addClientsToAllRecurringBookings: boolean,
  ) => {
    if (branchId && data.recurring) {
      const clients = data.clients.map(client => ({ clientId: client.id }))

      const recurringBookings = dates.map((date, index) => ({
        locationId: data.locationId,
        employeeId: data.employeeId,
        duration: Number(data.duration),
        startDate: date,
        serviceId: data.serviceId,
        // первый элемент массива это всегда основная запись с которой мы создаем повторяющиеся и в нее мы добавляем клиентов
        bookingClients: { data: index === 0 || addClientsToAllRecurringBookings ? clients : [] },
        isGroupBooking: true,
        note: data.note,
        branchId,
      }))

      const result = await createRecurringBookings({
        branchId,
        bookingInsertInput: recurringBookings,
      })

      if (result?.insertRecurringBooking?.bookings) {
        openSnackbar(t('booking.createSuccess'), 'success')

        closeDialog()
      } else openSnackbar(t('submitError'), 'error')
    }
  }

  const submitUpdatingRecurringBookings = async (
    data: GroupBookingDialogFormValues,
    recurringBookings: ServerRecurringBookingByIdType[],
    editClientsInAllRecurringBookings: boolean,
  ) => {
    try {
      if (timezone && groupBookingById && branchId) {
        const { bookingClientsToBeRemovedIds, bookingClientsToBeInserted } =
          getClientsToBeRemovedAndInsertedIds(groupBookingById as GroupBooking, data.clients)

        const filteredRecurringBookings = getRecurringBookingThatCanBeEdited(recurringBookings)

        const bookingsToUpdate = filteredRecurringBookings.map((booking, index) => {
          return {
            id: booking.id,
            bookingSetInput: {
              startDate: zonedTimeToUtc(
                set(booking.startDate, {
                  hours: data.startDate.getHours(),
                  minutes: data.startDate.getMinutes(),
                }),
                timezone,
              ),
              duration: Number(data.duration),
              employeeId: data.employeeId,
              locationId: data.locationId,
              serviceId: data.serviceId,
              note: data.note,
            },
            oldStartDate: groupBookingById?.startDate,
            bookingClientInsertInput:
              dirtyFields.clients && (index === 0 || editClientsInAllRecurringBookings)
                ? bookingClientsToBeInserted.map(client => ({
                    clientId: client.id,
                    bookingId: booking.id,
                  }))
                : [],
            bookingClientsToBeRemovedIds:
              dirtyFields.clients && index === 0 ? bookingClientsToBeRemovedIds : [],
            branchId,
          }
        })

        updateRecurringBookings(bookingsToUpdate)

        invalidateBookings({
          date: data.startDate,
          ids: filteredRecurringBookings.map(({ id }) => id),
          dateRange: [
            filteredRecurringBookings[0].startDate,
            filteredRecurringBookings[filteredRecurringBookings.length - 1].startDate,
          ],
          branchId,
        })

        openSnackbar(t('groupBooking.updateSuccess'), 'success', 3000)
      }
    } catch (error) {
      openSnackbar(t('submitError'), 'error', 3000)
    }

    closeDialog()
  }

  const handleOnSave: SubmitHandler<GroupBookingDialogFormValues> = async data => {
    if (isCreate && data.recurring && timezone) {
      const dates = getRecurringBookingsDates(data.recurring, data.startDate, timezone)

      // если после выбора количества повторений - количество букингов 1
      if (dates.length === 1) await mutateGroupBooking(data, closeDialog)

      if (getAreRecurringBookingsWithInvalidDate({ dates, timezone, startDate: data.startDate })) {
        openSnackbar(t('recurringBookings.invalidEndDate'), 'error')
        return
      }

      if (dates.length === 0) {
        openSnackbar(t('recurringBookings.invalidInterval'), 'error')

        return
      }

      const showValidationWarning = (addClientsToAllRecurringBookings: boolean) =>
        showConfirmation({
          title: t('warning'),
          onConfirm: () => submitRecurringBookings(data, dates, addClientsToAllRecurringBookings),
          description: (
            <div className={'w-120'}>
              <p>{t('recurringBookings.validationWarning')}</p>
            </div>
          ),
        })

      if (dirtyFields.clients) {
        showClientsConfirmationModal({
          title: t('recurringBookings.clients.addTitle'),
          onConfirm: () => showValidationWarning(true),
          onDeny: () => showValidationWarning(false),
          confirmButton: { text: t('yes') },
          cancelButton: { text: t('no') },
          description: (
            <div className={'w-120'}>
              <p>{t('recurringBookings.clients.addConfirmation')}</p>
            </div>
          ),
        })
      } else showValidationWarning(false)
    } else {
      if (groupBookingById?.recurringBooking?.id) {
        const isDateChanged = Boolean(dirtyFields.startDate)
          ? getIsDayOfBookingStartDateChanged(groupBookingById.startDate, data.startDate)
          : false

        if (isDateChanged) {
          showConfirmation({
            title: t('warning'),
            description: (
              <div className={'w-120'}>
                <p>{t('recurringBookings.editDateWarning')}</p>
              </div>
            ),
            onConfirm: () => mutateGroupBooking(data as GroupBookingDialogFormValues, closeDialog),
          })
        } else {
          const showOpenRecurringEditDialog = (editClientsInAllRecurringBookings: boolean) =>
            openRecurringEditDialog({
              // выше есть проверка на наличие groupBookingById.recurringBooking!.id
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              recurringId: groupBookingById.recurringBooking!.id,
              startDate: groupBookingById.startDate,
              onSave: (type, recurringBookings) => {
                if (type?.id === RecurringEditType.all) {
                  submitUpdatingRecurringBookings(
                    data,
                    recurringBookings,
                    editClientsInAllRecurringBookings,
                  )
                } else mutateGroupBooking(data as GroupBookingDialogFormValues, closeDialog)
              },
            })

          if (dirtyFields.clients) {
            showClientsConfirmationModal({
              title: t('recurringBookings.clients.editTitle'),
              onConfirm: () => showOpenRecurringEditDialog(true),
              onDeny: () => showOpenRecurringEditDialog(false),
              confirmButton: { text: t('yes') },
              cancelButton: { text: t('no') },
              description: (
                <div className={'w-120'}>
                  <p>{t('recurringBookings.clients.editConfirmation')}</p>
                </div>
              ),
            })
          } else showOpenRecurringEditDialog(false)
        }
      } else {
        await mutateGroupBooking(data as GroupBookingDialogFormValues, closeDialog)
      }
    }
  }

  const watchedRecurring = useWatch({ control, name: 'recurring' })

  return (
    <Dialog.Footer>
      {isEditable && !formIsDisabled && (
        <SaveButton
          disabled={isSubmitting || !isDirty || isLoading}
          spinner={isSubmitting || isLoading}
          onClick={handleSubmit(handleOnSave)}
          isCreate={isCreate}
        />
      )}

      <CloseButton onClick={closePopups} />
      {id && isTransactionsGetAllowed && (
        <Button
          onClick={() =>
            isDirty
              ? handleSubmit(data =>
                  mutateGroupBooking(data as GroupBookingDialogFormValues, () =>
                    openGroupBookingPaymentDialog(id),
                  ),
                )()
              : openGroupBookingPaymentDialog(id)
          }
          Icon={IoWalletOutline}
        >
          {t('payments')}
        </Button>
      )}
      {isCreate && !watchedRecurring && (
        <Button
          onClick={handleSubmit(handleMultiBooking)}
          className="mr-auto"
          type="outline"
          Icon={IoDocumentsOutline}
        >
          {t('createMultiple')}
        </Button>
      )}
      {!isCreate && !formIsDisabled && (
        <CancelButton className="mr-auto" disabled={isBookingPaid} onClick={handleCancelBooking} />
      )}
      {confirmationModal}
      {groupBookingPaymentDialog}
      {cancelBookingModal}
      {recurringEditDialog}
      {clientsConfirmationModal}
    </Dialog.Footer>
  )
}
