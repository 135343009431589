import React, { FC } from 'react'
import { Controller, useFieldArray, useWatch } from 'react-hook-form'
import { EditablePhoto } from 'widgets/EditablePhoto'
import { useTranslation } from 'react-i18next'
import { IMAGE_SIZE, LandingSettingsBlockProps } from '../logic'
import { BlockWrapper } from './BlockWrapper'
import { IoAddOutline } from 'react-icons/io5'

export const OurWorks: FC<LandingSettingsBlockProps> = ({ control, disabled }) => {
  const { t } = useTranslation()

  const watchedFields = useWatch({ control, name: 'images' })

  return (
    <BlockWrapper
      label={t('businessLandingSettings.ourWorks')}
      hint={t('businessLandingSettings.ourWorksHint')}
    >
      <div className="flex gap-4 flex-wrap">
        {watchedFields.map((_, index) => (
          <Controller
            key={`images.${index}`}
            name={`images.${index}`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <EditablePhoto
                cropShape="rect"
                size="big"
                defaultPhoto={value.url}
                onChange={base64File => {
                  onChange({ url: base64File })
                }}
                disabled={disabled}
                croppedSize={IMAGE_SIZE}
              />
            )}
          />
        ))}

        <AddImageButton control={control} disabled={disabled} />
      </div>
    </BlockWrapper>
  )
}

const AddImageButton: FC<LandingSettingsBlockProps> = ({ control, disabled }) => {
  let addButtonStyle =
    'flex flex-centered cursor-pointer border-2 border-dashed rounded-2xl w-32 h-32'
  addButtonStyle += disabled
    ? ' border-gray-300 text-gray-300 pointer-events-none'
    : ' border-primary-400 bg-hover text-primary-400'

  const { fields, append } = useFieldArray({ control, name: 'images' })

  if (fields.length > 9) return null

  return (
    <div className={addButtonStyle} onClick={() => append({ url: '' })}>
      <IoAddOutline size="2rem" />
    </div>
  )
}
