import { FC } from 'react'
import { BusinessLandingSettingsType, useUpdateBusinessLandingSettings } from '@expane/data'
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { Switch } from '@expane/ui'
import { permissions } from '@expane/logic/permission'
import { useTranslation } from 'react-i18next'
import { BusinessLandingSettingsFormData } from './logic'
import { SaveButton } from 'widgets/Buttons'
import { useUpdateFile } from '@expane/logic/file'
import { useSnackbar } from '@expane/widgets'
import { Path } from './Blocks/Path'
import { SocialMediaLinks } from './Blocks/SocialMediaLinks'
import { OurWorks } from './Blocks/OurWorks'
import { MainBanner } from './Blocks/MainBanner'
import { OtherSettings } from './Blocks/OtherSettings'

interface BusinessLandingSettingsLogicProps {
  businessLandingSettings: BusinessLandingSettingsType
  myPermissions: string[]
}

export const BusinessLandingSettingsLogic: FC<BusinessLandingSettingsLogicProps> = ({
  businessLandingSettings,
  myPermissions,
}) => {
  const { t } = useTranslation()

  const updatePhoto = useUpdateFile()
  const { mutateAsync: mutateUpdateBusinessLandingSettings } = useUpdateBusinessLandingSettings()

  const [openSnackBar] = useSnackbar()

  const { control, handleSubmit, formState, getFieldState } =
    useForm<BusinessLandingSettingsFormData>({
      defaultValues: {
        bannerTitle: businessLandingSettings.bannerTitle ?? '',
        bannerDescription: businessLandingSettings.bannerDescription ?? '',
        bannerImage: businessLandingSettings.bannerImage ?? '',
        enabled: businessLandingSettings.enabled,
        path: businessLandingSettings.path ?? '',
        images: businessLandingSettings.images.map(url => ({ url })),
        allowableTimeForEditingBookingStartDate:
          businessLandingSettings.allowableTimeForEditingBookingStartDate ?? 24,
        facebook: businessLandingSettings.facebook ?? '',
        instagram: businessLandingSettings.instagram ?? '',
        telegram: businessLandingSettings.telegram ?? '',
        viber: businessLandingSettings.viber ?? '',
        theme: businessLandingSettings.theme ?? 'primary',
      },
    })

  const watchedEnabled = useWatch({ control, name: 'enabled' })
  const isEditAllowed = myPermissions.includes(permissions.business.set)

  const disabled = !watchedEnabled || !isEditAllowed || formState.isSubmitting

  const submitHandler: SubmitHandler<BusinessLandingSettingsFormData> = async data => {
    const bannerImage = await updatePhoto({
      prevFile: businessLandingSettings?.bannerImage ?? undefined,
      file: data.bannerImage,
    })

    // TODO: Подивитись чи не викликає проблеми такий флоу, і якщо треба покращити (заливати напряму в GCS SignedUrl)
    // TODO: Додати в GCF в scheduler перевірку файлів які не використовуються і видаляти їх
    // Бо з таким флоу у нас старі файли залишаються у сховищі
    const images: string[] = []

    if (getFieldState('images').isDirty) {
      // Завантажуємо по черзі всі файли
      for (const image of data.images) {
        // Оновлюємо тільки якщо тут новий файл
        if (image.url && image.url.includes('base64')) {
          const url = await updatePhoto({
            file: image.url,
          })

          if (url) images.push(url)
          // інакше просто залишаємо ставрий url
        } else if (image.url) {
          images.push(image.url)
        }
      }
    }

    const result = await mutateUpdateBusinessLandingSettings({
      id: businessLandingSettings.id,
      serverBusinessLandingSetInput: {
        path: data.path || null,
        enabled: data.enabled,
        bannerTitle: data.bannerTitle,
        bannerDescription: data.bannerDescription,
        bannerImage,
        facebook: data.facebook,
        viber: data.viber,
        instagram: data.instagram,
        telegram: data.telegram,
        allowableTimeForEditingBookingStartDate: data.allowableTimeForEditingBookingStartDate,
        theme: data.theme,
        ...(images.length > 0 ? { images } : undefined),
      },
    })

    if (result.updateBusinessLandingById.id) openSnackBar(t('changesSaved'), 'success', 3000)
    else openSnackBar(t('submitError'), 'error', 3000)
  }

  return (
    <div className="max-w-6xl">
      <Controller
        name="enabled"
        control={control}
        render={({ field: { value, onChange } }) => (
          <Switch
            containerClassName="mb-4"
            label={t('businessLandingSettings.enableLanding')}
            checked={value}
            onChange={onChange}
          />
        )}
      />

      <Path
        control={control}
        disabled={disabled}
        businessLandingSettings={businessLandingSettings}
      />

      <MainBanner control={control} disabled={disabled} />

      <SocialMediaLinks control={control} disabled={disabled} />

      <OtherSettings control={control} disabled={disabled} />

      <OurWorks control={control} disabled={disabled} />

      <SaveButton
        onClick={handleSubmit(submitHandler)}
        disabled={!formState.isDirty || formState.isSubmitting}
        spinner={formState.isSubmitting}
      />
    </div>
  )
}
