import {
  ClientBriefWithTagType,
  useFetchClientsPhones,
  useFetchDeactivatedClients,
} from '@expane/data'
import { addPhonesToClients, ClientWithPhones } from '@expane/logic/client'
import { permissions } from '@expane/logic/permission'
import { transformPersonName } from '@expane/logic/utils'
import { Table, Tag } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { config } from 'config'
import { useFetchMyPermissions } from 'gql/employee'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoFileTrayFullOutline } from 'react-icons/io5'
import { store } from 'store'
import { Avatar } from 'ui/Avatar'
import { Phone } from 'ui/Phone'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

export const ArchiveClientsList: FC<{ onRowClick: (id: number) => void }> = observer(
  ({ onRowClick }) => {
    const branchId = store.branch.branchId
    const { t } = useTranslation()

    const { data: deactivatedClients, isLoading: isLoadingDeactivatedClients } =
      useFetchDeactivatedClients(branchId)
    const { data: clientPhones, isLoading: isLoadingClientPhones } = useFetchClientsPhones()
    const { data: myPermissions } = useFetchMyPermissions()

    const isLoading = isLoadingClientPhones || isLoadingDeactivatedClients

    const data = addPhonesToClients(deactivatedClients, clientPhones)

    const columns = useMemo(() => {
      const columns: ColumnDef<ClientWithPhones<ClientBriefWithTagType>>[] = [
        {
          id: 'fullName',
          accessorFn: client => transformPersonName(client),
          header: t('client.name'),
          cell: data => (
            <div className="flex items-center">
              <Avatar
                url={
                  data.row.original.photo?.length
                    ? data.row.original.photo
                    : config.PHOTO_PLACEHOLDER_URL
                }
                name={data.getValue<string>()}
                className="mr-2"
              />
              <p>{data.getValue<string>()}</p>
              {data.row.original.clientTags.map(({ tag }) => (
                <Tag key={tag.id} name={tag.name} color={tag.color} className="ml-1" />
              ))}
            </div>
          ),
          size: 400,
        },
      ]
      if (myPermissions?.includes(permissions.client.phone))
        columns.push({
          accessorKey: 'phone',
          header: t('phone'),
          cell: data => <Phone number={data.getValue<string>()} />,
          size: 300,
        })

      return columns
    }, [myPermissions, t])

    if (data?.length === 0 && !isLoading)
      return (
        <div className="flex flex-col flex-1">
          <EmptyPlaceholder Icon={IoFileTrayFullOutline} text={t('emptyPlaceholder.archive')} />
        </div>
      )

    return (
      <Table
        columns={columns}
        data={data}
        isLoading={isLoading}
        onRowClick={client => onRowClick(client.id)}
      />
    )
  },
)
