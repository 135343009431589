import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFetchMyPermissions } from 'gql/employee'
import { SettingsWrapper } from 'pages/SettingsPage/index'
import { useFetchBranches, useFetchSchedules } from '@expane/data'
import { BranchBlock } from './BranchBlock'
import { treeMenuClassName } from 'ui/styles'
import { onChangeSingleTreeMenu } from 'ui/TreeMenu/logic.onChange'
import { TreeMenu, TreeMenuItem } from 'ui/TreeMenu'
import { permissions } from '@expane/logic/permission'
import { useOpenBranchCreateDialog } from 'widgets/BranchCreateDialog'
import { useBusinessModulesSettings } from '@expane/logic/modules'

export const BranchesSettings: FC = () => {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = useState<TreeMenuItem>()

  const { openDialog: openBranchCreateDialog, dialog: branchCreateDialog } =
    useOpenBranchCreateDialog()

  const { data: branches } = useFetchBranches()
  const { data: myPermissions } = useFetchMyPermissions()
  const { data: schedules } = useFetchSchedules()

  const { getModuleSetting } = useBusinessModulesSettings()
  const areManyBranchesEnabled = getModuleSetting('branches')

  if (!branches || !myPermissions || !schedules) return null

  const isOneBranch = branches.length === 1
  const isEditingAllowed = myPermissions.includes(permissions.branch.set)

  const selectedBranch = branches.find(branch => branch.id === selectedItem?.id)

  return (
    <SettingsWrapper
      onPlusClick={isEditingAllowed && areManyBranchesEnabled ? openBranchCreateDialog : undefined}
    >
      {isOneBranch ? (
        <BranchBlock
          className={branchBlockWidth}
          branch={branches[0]}
          schedules={schedules}
          myPermissions={myPermissions}
        />
      ) : (
        <div className="flex">
          <TreeMenu
            type="SinglePickMode"
            className={treeMenuClassName + ' w-56'}
            items={branches}
            selected={selectedItem}
            onSelected={item => setSelectedItem(onChangeSingleTreeMenu(item, selectedItem))}
            openSwitch
            isSearch={false}
          />

          {selectedBranch ? (
            <BranchBlock
              key={selectedBranch.id}
              className={branchBlockWidth + ' ml-4'}
              schedules={schedules}
              myPermissions={myPermissions}
              branch={selectedBranch}
            />
          ) : (
            <div className={branchBlockWidth + ' ml-4 flex flex-centered'}>
              <p className="text-main-color">{t('branch.selectBranchFromList')}</p>
            </div>
          )}
        </div>
      )}

      {branchCreateDialog}
    </SettingsWrapper>
  )
}

const branchBlockWidth = 'w-152'
