import { ServerTagType } from '@expane/data'
import { Table, Tag } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoPricetagsOutline } from 'react-icons/io5'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

export const TagsList: FC<{
  onRowClick: (tag: ServerTagType) => void
  tags: ServerTagType[] | undefined
  isLoading: boolean
  archive?: boolean
}> = memo(({ onRowClick, tags, isLoading, archive }) => {
  const { t } = useTranslation()
  const columns = useMemo<ColumnDef<ServerTagType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('tag.name'),
        cell: data => (
          <div className="my-0.5">
            <Tag
              name={data.row.original.name}
              color={data.row.original.color}
              className={'line-clamp-1 w-fit'}
            />
          </div>
        ),
        size: 300,
      },
      {
        accessorKey: 'description',
        header: t('description'),
        cell: data => data.getValue(),
        size: 400,
      },
    ],
    [t],
  )

  if (tags?.length === 0 && !isLoading)
    return (
      <EmptyPlaceholder
        Icon={IoPricetagsOutline}
        text={archive ? t('emptyPlaceholder.archive') : t('emptyPlaceholder.tag')}
      />
    )

  return <Table columns={columns} data={tags} isLoading={isLoading} onRowClick={onRowClick} />
})
