import { gql } from 'graphql-request'

export const businessLandingSettingsFragment = gql`
  fragment businessLandingSettingsType on businessLanding {
    id
    bannerTitle
    bannerDescription
    bannerImage
    enabled
    images
    path
    allowableTimeForEditingBookingStartDate
    facebook
    telegram
    instagram
    viber
    theme
  }
`

export const anonymouslyBusinessLandingSettingsFragment = gql`
  fragment anonymouslyBusinessLandingSettingsType on businessLandingView {
    allowableTimeForEditingBookingStartDate
    bannerDescription
    bannerImage
    bannerTitle
    businessId
    facebook
    images
    instagram
    path
    telegram
    theme
    viber
  }
`
