import { ServerCardTemplateType } from '@expane/data'
import { PropsWithBranchId } from '@expane/logic/branch'
import { getIsCardEndless } from '@expane/logic/cards'
import { useConvertNumberToMoney } from '@expane/logic/currency'
import { Table, Tag } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoJournalOutline } from 'react-icons/io5'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

export const SubscriptionsPageList: FC<
  PropsWithBranchId<{
    onRowClick: (subscription: ServerCardTemplateType) => void
    subscriptions: ServerCardTemplateType[] | undefined
    isLoading: boolean
    archive?: boolean
  }>
> = memo(({ onRowClick, subscriptions, isLoading, archive, branchId }) => {
  const { t } = useTranslation()
  const convertNumberToMoney = useConvertNumberToMoney(branchId)
  const columns = useMemo<ColumnDef<ServerCardTemplateType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name'),
        cell: data => {
          return (
            <div className="flex gap-2">
              <p>{data.getValue<string>()}</p>
              {data.row.original.availableToAllBranches && (
                <Tag name={t('availableForAllBranches')} color={'primary-300'} />
              )}
            </div>
          )
        },
        size: 400,
      },
      {
        accessorKey: 'cardPeriod',
        header: t('validityDays'),
        cell: data => {
          const cardPeriod = data.getValue<number>()

          return getIsCardEndless(cardPeriod) ? t('unlimitedTime') : cardPeriod
        },
      },
      {
        accessorKey: 'price',
        header: () => <span className="w-full text-right">{t('price')}</span>,
        cell: data => (
          <div className="text-right">{convertNumberToMoney(data.getValue<number>())}</div>
        ),
      },
    ],
    [convertNumberToMoney, t],
  )

  if (subscriptions?.length === 0 && !isLoading)
    return (
      <EmptyPlaceholder
        Icon={IoJournalOutline}
        text={archive ? t('emptyPlaceholder.archive') : t('emptyPlaceholder.subscription')}
      />
    )

  return (
    <Table columns={columns} data={subscriptions} isLoading={isLoading} onRowClick={onRowClick} />
  )
})
