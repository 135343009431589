import React, { FC } from 'react'
import { BusinessLandingSettingsType, ServerOnlineBookingUrlType } from '@expane/data'
import { useTranslation } from 'react-i18next'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'
import { IoDesktop, IoLinkOutline } from 'react-icons/io5'
import { useOpenDialog } from 'logic/hooks/useOpenDialog'
import { OnlineBookingURLDialog } from 'widgets/OnlineBookingURLDialog'
import { SettingsWrapper } from 'pages/SettingsPage'
import { OnlineBookingURLsList } from './List'
import { Link } from 'react-router-dom'
import { SaveButton } from 'widgets/Buttons'

interface OnlineBookingURLsLogicProps {
  businessLandingSettings: BusinessLandingSettingsType
  onlineBookingURLs: ServerOnlineBookingUrlType[]
}

export const OnlineBookingURLsLogic: FC<OnlineBookingURLsLogicProps> = ({
  businessLandingSettings,
  onlineBookingURLs,
}) => {
  const { openEditDialog, openCreateDialog, dialog } = useOpenDialog(OnlineBookingURLDialog)

  const { t } = useTranslation()

  return (
    <SettingsWrapper>
      <div className="min-h-60">
        {businessLandingSettings.enabled ? (
          <>
            <SaveButton
              isCreate
              className="mb-4"
              onClick={() => {
                openCreateDialog()
              }}
            />

            {onlineBookingURLs.length > 0 ? (
              <OnlineBookingURLsList
                onlineBookingURLs={onlineBookingURLs}
                businessLandingSettings={businessLandingSettings}
                onRowClick={openEditDialog}
              />
            ) : (
              <EmptyPlaceholder Icon={IoLinkOutline} text={t('onlineBookingURL.noURLs')} />
            )}
          </>
        ) : (
          <EmptyPlaceholder
            Icon={IoDesktop}
            text={
              <>
                {t('onlineBookingURL.notEnabledLanding.1')}{' '}
                <Link to="/settings/landing-settings" className="hover:text-primary-500 underline">
                  {t('onlineBookingURL.notEnabledLanding.2')}
                </Link>
              </>
            }
          />
        )}
      </div>

      {dialog}
    </SettingsWrapper>
  )
}
