import { Input } from '@expane/ui'
import { BusinessSettingsForm } from 'pages/SettingsPage/BusinessSettings/index'
import { FC } from 'react'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { EditablePhoto } from 'widgets/EditablePhoto'

interface UpdateBusinessProps {
  control: Control<BusinessSettingsForm>
  defaultPhoto: string | undefined
}

export const MainBusinessInfo: FC<UpdateBusinessProps> = ({ control, defaultPhoto }) => {
  const { t } = useTranslation()

  return (
    <div className="flex relative">
      <Controller
        name="name"
        control={control}
        rules={{ required: true }}
        render={({ field, fieldState: { error } }) => (
          <Input
            label={t('title')}
            placeholder={t('placeholders.businessName')}
            containerClassName="w-64"
            required
            errorMessage={{
              isShown: Boolean(error),
              text: t('formError.required'),
              reserveIndent: false,
            }}
            {...field}
          />
        )}
      />
      <Controller
        name="photo"
        control={control}
        render={({ field: { onChange } }) => (
          <EditablePhoto
            defaultPhoto={defaultPhoto}
            onChange={onChange}
            containerClassName="flex justify-center absolute right-2 -top-2"
          />
        )}
      />
    </div>
  )
}
