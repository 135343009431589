import { useFetchBusinessSettings, useFetchMyBusinesses } from '@expane/data'
import { Spinner } from '@expane/ui'
import { useCreateBusinessSteps } from 'pages/CreateBusinessPage/index'
import { BusinessSettingsLogic } from 'pages/SettingsPage/BusinessSettings'
import { FC } from 'react'
import { store } from 'store'

export const BusinessSettingsStep: FC = () => {
  const { proceedToNextStep } = useCreateBusinessSteps()

  const currentBusinessId = store.me.businessId

  const { data: businesses, isLoading: areBusinessesLoading } = useFetchMyBusinesses(
    store.me.isAuthorised,
  )
  const { data: businessSettings, isLoading: areBusinessSettingsLoading } =
    useFetchBusinessSettings()

  const myBusiness = businesses?.find(business => business.id === currentBusinessId)

  if (!myBusiness || !businessSettings) return null

  return (
    <div className={'flex items-end'}>
      {areBusinessSettingsLoading || areBusinessesLoading ? (
        <Spinner expandCentered />
      ) : (
        <BusinessSettingsLogic
          businessSettings={businessSettings}
          myBusiness={myBusiness}
          type="createBusiness"
          onApply={proceedToNextStep}
        />
      )}
    </div>
  )
}
