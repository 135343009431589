import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { CLIENTS_QUERY_KEY, MY_CLIENT_QUERY_KEY } from '../client'
import { CARDS_QUERY_KEY } from './queryKeys'
import { gql } from 'graphql-request'
import { ActivateGiftCardAsClientResult } from './logic'

export function useDeactivateClientCards() {
  return useMutation(
    async (cardIds: number[]) => {
      // eslint-disable-next-line camelcase
      return request<{ updateCards: { affected_rows: number } }>(
        gql`
          mutation ($cardIds: [Int!]!, $canceledDate: timestamptz) {
            updateCards(where: { id: { _in: $cardIds } }, _set: { canceledDate: $canceledDate }) {
              affected_rows
            }
          }
        `,
        { cardIds, canceledDate: new Date().toISOString() },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CLIENTS_QUERY_KEY])

        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(CARDS_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}

export function useActivateClientCardById() {
  return useMutation(
    async (dto: {
      cardId: number
      activatedAt: Date
    }): Promise<{ updateCardById: { id: number } }> => {
      return request(
        gql`
          mutation ($cardId: Int!, $activatedAt: timestamptz) {
            updateCardById(pk_columns: { id: $cardId }, _set: { activatedAt: $activatedAt }) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CARDS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}

export function useActivateGiftCard() {
  return useMutation(
    async (dto: {
      id: number
      clientId: number
      activatedAt: Date
    }): Promise<{
      id: number
    }> => {
      return request(
        gql`
          mutation ($id: Int!, $activatedAt: timestamptz, $clientId: Int!) {
            updateCardById(
              pk_columns: { id: $id }
              _set: { activatedAt: $activatedAt, clientId: $clientId }
            ) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([CARDS_QUERY_KEY, { clientId: variables.clientId }])
      },
      onError: reportGqlError,
    },
  )
}

export function useActivateGiftCardAsClient() {
  return useMutation(
    async (dto: {
      code: string
      branchId: number
    }): Promise<{
      activateGiftCard: ActivateGiftCardAsClientResult
    }> => {
      return request(
        gql`
          mutation ($code: String!, $branchId: Int!) {
            activateGiftCard(code: $code, branchId: $branchId) {
              code
              message
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CARDS_QUERY_KEY])
        queryClient.invalidateQueries({
          predicate: query => query.queryKey.includes(MY_CLIENT_QUERY_KEY),
        })
      },
      onError: reportGqlError,
    },
  )
}

export function useUpdateClientCardById() {
  return useMutation(
    async (dto: {
      cardId: number
      clientId: number
      cardPeriod: number
    }): Promise<{
      updateCardById: { id: number; clientId: number; cardPeriod: number }
    }> => {
      return request(
        gql`
          mutation ($cardId: Int!, $clientId: Int!, $cardPeriod: Int!) {
            updateCardById(
              pk_columns: { id: $cardId }
              _set: { clientId: $clientId, cardPeriod: $cardPeriod }
            ) {
              id
              clientId
            }
          }
        `,
        dto,
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CARDS_QUERY_KEY])
      },
      onError: reportGqlError,
    },
  )
}
