import { ServerCloudFunctionResult } from '../../generated/graphql-types'

export enum ArchiveLocationGroupResponseCodes {
  noRequiredFields,
  noFound,
  noPermission,
  nonEmpty,
  updateError,
  success,
}

export interface ArchiveLocationGroupResult extends ServerCloudFunctionResult {
  code: ArchiveLocationGroupResponseCodes
}
