import { utcToZonedTime } from '@expane/date'
import {
  EmployeeScheduleToParse,
  parseDatesInEmployeeScheduleGqlResponse,
} from '../employeeSchedule/logic'
import { parseDatesInTimeOffGqlResponse, TimeOffToParse } from '../timeOff/logic'
import { parseDatesInSalarySettingGqlResponse, SalarySettingToParse } from '../salarySetting/logic'
import {
  ServerAnonymouslyEmployeeType,
  ServerCloudFunctionResult,
  ServerEmployeeByIdExtendedType,
  ServerEmployeeExtendedType,
  ServerEmployeeForMobileCalendarType,
  ServerEmployeeInEmployeeGroupType,
  ServerEmployeeWithScheduleType,
} from '../../generated/graphql-types'
import { EmployeeScheduleDto } from '../employeeSchedule'
import { EmployeeConnections } from '../employeeSettings'

export type EmployeeWithSchedule = Omit<ServerEmployeeWithScheduleType, 'employeeSchedules'> & {
  employeeSchedules: EmployeeScheduleDto[]
}

export type EmployeeByIdExtended = Omit<
  ServerEmployeeByIdExtendedType,
  'employeeSchedules' | 'employeeSettings'
> & {
  employeeSchedules: EmployeeScheduleDto[]
  employeeSettings: { preferredConnections: EmployeeConnections }
}

export type ExtendedEmployee = Omit<ServerEmployeeExtendedType, 'employeeSchedules'> & {
  employeeSchedules: EmployeeScheduleDto[]
}

export type EmployeeWithSalarySettingsType = Omit<
  ServerEmployeeInEmployeeGroupType,
  'employeeSchedules'
> & {
  employeeSchedules: EmployeeScheduleDto[]
}

export type EmployeeForCalendar = Omit<ServerEmployeeForMobileCalendarType, 'employeeSchedules'> & {
  employeeSchedules: EmployeeScheduleDto[]
}

// Sync with similar function in functions/gql/employee/helper.ts
export type EmployeeToParse = {
  birthDate?: string | Date | null
  employeeSchedules?: EmployeeScheduleToParse[] | null
  timeoffs?: TimeOffToParse[] | null
  salarySettings?: SalarySettingToParse[] | null
}
export const parseDatesInEmployeeGqlResponse = <T extends EmployeeToParse>(
  employee: T,
  timezone: string,
) => {
  if (employee.birthDate) employee.birthDate = utcToZonedTime(employee.birthDate, timezone)

  if (employee.employeeSchedules) {
    employee.employeeSchedules = employee.employeeSchedules.map(eS =>
      parseDatesInEmployeeScheduleGqlResponse(eS, timezone),
    )
  }

  if (employee.timeoffs) {
    employee.timeoffs = employee.timeoffs.map(timeOff =>
      parseDatesInTimeOffGqlResponse(timeOff, timezone),
    )
  }

  if (employee.salarySettings) {
    employee.salarySettings = employee.salarySettings.map(salarySetting =>
      parseDatesInSalarySettingGqlResponse(salarySetting, timezone),
    )
  }

  return employee
}

export enum ArchiveEmployeeResponseCodes {
  noRequiredFields,
  noFound,
  noPermission,
  cannotHimself,
  cannotOwner,
  hasActiveSchedules,
  updateError,
  success,
}

export interface ArchiveEmployeeResult extends ServerCloudFunctionResult {
  code: ArchiveEmployeeResponseCodes
}

export interface EmployeeTypeWithReviews extends ServerAnonymouslyEmployeeType {
  reviewRating: { averageRating: number; quantity: number } | null
  reviews: Array<{
    id: number
    text: string
    rating: number
    clientFirstName: string
    clientLastName: string | null
  }>
}
