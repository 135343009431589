import {
  ServerServiceGroupType,
  useArchiveServiceGroup,
  useCreateServiceGroup,
  useFetchServiceGroupById,
  useFetchServiceGroups,
  useUpdateServiceGroup,
} from '@expane/data'
import { permissions } from '@expane/logic/permission'
import {
  CloseButton,
  CommonPlaceholderDialogProps,
  Dialog,
  Input,
  Modal,
  PlaceholderDialog,
  PlaceholderInput,
  PlaceholderTextarea,
  SelectDropdown,
  Textarea,
  useShowWarningPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useFetchMyPermissions } from 'gql/employee'
import { useShowArchiveConfirmationPopup } from 'logic/hooks/popup/useShowArchiveConfirmationPopup'
import { useShowPopupOnDirtyFormClose } from 'logic/hooks/popup/useShowPopupOnDirtyFormClose'
import { DialogProps } from 'logic/hooks/useOpenDialog'
import { FC } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { store } from 'store'
import { ArchiveButton, RestoreButton, SaveButton } from 'widgets/Buttons'

export const ServiceGroupDialog: FC<DialogProps> = ({ id, closeDialog, isCreate, onCreate }) => {
  const branchId = store.branch.branchId
  const { t } = useTranslation()
  const { data: serviceGroups, isLoading: isLoadingServiceGroups } = useFetchServiceGroups(branchId)
  const { data: serviceGroupById, isLoading: isLoadingServiceGroupById } =
    useFetchServiceGroupById(id)

  const [openSnackbar] = useSnackbar()

  const isLoading = (isLoadingServiceGroupById && !isCreate) || isLoadingServiceGroups

  if (isLoading) return <ServiceGroupDialogPlaceholder closeDialog={closeDialog} />
  else if ((!isCreate && !serviceGroupById) || !serviceGroups) {
    openSnackbar(t('dialogFailed'), 'error')
    return null
  }

  return (
    <ServiceGroupDialogLogic
      isCreate={isCreate}
      serviceGroupById={serviceGroupById}
      serviceGroups={serviceGroups}
      closeDialog={closeDialog}
      onCreate={onCreate}
    />
  )
}

const ServiceGroupDialogLogic: FC<ServiceGroupDialogLogicProps> = ({
  isCreate,
  serviceGroupById,
  serviceGroups,
  closeDialog,
  onCreate,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const branchId = store.branch.branchId!
  const { t } = useTranslation()

  const { data: myPermissions } = useFetchMyPermissions()

  const { formState, control, handleSubmit } = useForm<ServiceGroupsFormValues>({
    defaultValues: {
      name: serviceGroupById?.name ?? '',
      parentId: serviceGroupById?.parentId ?? undefined,
      description: serviceGroupById?.description ?? '',
    },
  })

  const { mutateAsync: mutateArchiveServiceGroup, isLoading: isLoadingOnArchive } =
    useArchiveServiceGroup()

  const { mutateAsync: createMutation } = useCreateServiceGroup()
  const { mutateAsync: updateMutation } = useUpdateServiceGroup()

  const { closePopups, confirmPopup } = useShowPopupOnDirtyFormClose(formState, closeDialog)
  const [openSnackbar] = useSnackbar()

  const mutateServiceGroup: SubmitHandler<ServiceGroupsFormValues> = async data => {
    const { name, description, parentId } = data

    if (isCreate) {
      const result = await createMutation({ name, description, parentId, branchId })

      if (result?.insertServiceGroup?.id) {
        onCreate?.(result.insertServiceGroup.id)
        openSnackbar(t('serviceGroup.createdSuccessfully'), 'success')
      } else openSnackbar(t('submitError'), 'error')
    } else if (serviceGroupById) {
      const result = await updateMutation({
        id: serviceGroupById.id,
        serviceGroupSetInput: { name, description, parentId: parentId ?? null },
      })

      if (result?.updateServiceGroupById?.id)
        openSnackbar(t('serviceGroup.updatedSuccessfully'), 'success')
      else openSnackbar(t('submitError'), 'error')
    }

    closeDialog()
  }

  const archiveServiceGroup = async (archived: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const result = await mutateArchiveServiceGroup({ id: serviceGroupById!.id, archived })

    if (result?.updateServiceGroupArchives?.affected_rows)
      openSnackbar(
        archived
          ? t('serviceGroup.archivingSuccessful', { name: serviceGroupById?.name })
          : t('serviceGroup.restorationSuccessful', { name: serviceGroupById?.name }),
        'success',
      )
    else openSnackbar(t('submitError'), 'error')

    closeDialog()
  }

  const { showArchiveConfirmationPopup, archiveConfirmationModal } =
    useShowArchiveConfirmationPopup(serviceGroupById?.name ?? '', () => archiveServiceGroup(true))

  const { warningModal, showWarningPopup } = useShowWarningPopup(
    t('warning'),
    t('serviceCategoryWarning'),
  )

  const handleOnArchive = () => {
    const services = serviceGroupById?.services?.filter(s => s.archived === null)

    if (services?.length !== 0) showWarningPopup()
    else showArchiveConfirmationPopup()
  }

  const isEditingAllowed = myPermissions?.includes(permissions.service.set)
  const isArchivingAllowed = myPermissions?.includes(permissions.service.archive)

  return (
    <>
      <Modal
        close={closePopups}
        confirm={() => {
          if (isEditingAllowed && !formState.isSubmitting && formState.isDirty)
            handleSubmit(mutateServiceGroup)()
        }}
        animation="onlyFadeOut"
      >
        <Dialog>
          <Dialog.Title>{t('serviceCategory')}</Dialog.Title>

          <Dialog.Body>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Input
                  label={t('title')}
                  placeholder={t('placeholders.serviceGroups')}
                  required
                  errorMessage={{
                    isShown: Boolean(formState.errors.name),
                    text: t('formError.required'),
                  }}
                  value={value}
                  onChange={onChange}
                  disabled={!isEditingAllowed}
                  autoFocus
                />
              )}
            />

            <Controller
              control={control}
              name="parentId"
              render={({ field: { value, onChange } }) => (
                <SelectDropdown
                  value={value}
                  onSelectChange={onChange}
                  items={serviceGroups.filter(
                    serviceGroup => serviceGroup.id !== serviceGroupById?.id,
                  )}
                  label={t('parentGroup')}
                  isClearable
                  disabled={!isEditingAllowed}
                />
              )}
            />

            <Controller
              name="description"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Textarea
                  label={t('description')}
                  placeholder={t('placeholders.serviceGroupsDescription')}
                  containerClassName="mt-3"
                  value={value}
                  onChange={onChange}
                  disabled={!isEditingAllowed}
                />
              )}
            />
          </Dialog.Body>

          <Dialog.Footer>
            {isEditingAllowed && (
              <SaveButton
                onClick={handleSubmit(mutateServiceGroup)}
                disabled={formState.isSubmitting || !formState.isDirty}
                spinner={formState.isSubmitting}
                isCreate={isCreate}
              />
            )}

            <CloseButton onClick={closePopups} />

            {isArchivingAllowed &&
              !isCreate &&
              (serviceGroupById?.archived ? (
                <RestoreButton
                  className="mr-auto"
                  onClick={() => archiveServiceGroup(false)}
                  disabled={isLoadingOnArchive}
                  spinner={isLoadingOnArchive}
                />
              ) : (
                <ArchiveButton
                  className="mr-auto"
                  onClick={handleOnArchive}
                  disabled={isLoadingOnArchive}
                  spinner={isLoadingOnArchive}
                />
              ))}
          </Dialog.Footer>
        </Dialog>
      </Modal>

      {confirmPopup}
      {archiveConfirmationModal}
      {warningModal}
    </>
  )
}

export const ServiceGroupDialogPlaceholder: FC<CommonPlaceholderDialogProps> = ({
  closeDialog,
}) => {
  const { t } = useTranslation()

  return (
    <PlaceholderDialog title={t('serviceCategory')} closeDialog={closeDialog}>
      <PlaceholderInput label={t('title')} className="mb-4" />
      <PlaceholderInput label={t('parentGroup')} />
      <PlaceholderTextarea label={t('description')} className="mt-3" />
    </PlaceholderDialog>
  )
}

interface ServiceGroupDialogLogicProps extends Omit<DialogProps, 'id'> {
  serviceGroupById: ServerServiceGroupType | undefined
  serviceGroups: ServerServiceGroupType[]
}

type ServiceGroupsFormValues = {
  name: string
  description: string
  parentId: number | undefined
}
