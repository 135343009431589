import { ServerInventoryType } from '@expane/data'
import { Table } from '@expane/ui'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoReceiptOutline } from 'react-icons/io5'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

interface InventoryListProps {
  onRowClick: (id: number) => void
  inventories: ServerInventoryType[]
  isLoading: boolean
}

export const InventoryList: FC<InventoryListProps> = ({ onRowClick, inventories, isLoading }) => {
  const { t } = useTranslation()
  const dateFormatting = useDateFormatting()

  const columns = useMemo<ColumnDef<ServerInventoryType>[]>(
    () => [
      {
        accessorKey: 'number',
        header: t('number'),
        cell: data => data.getValue(),
        size: 200,
      },
      {
        accessorKey: 'inventoryDate',
        header: t('dateTitle'),
        cell: data => dateFormatting('historyDateTime', data.getValue<Date>()),
        size: 200,
      },
      {
        accessorKey: 'storage.name',
        header: t('storage.name'),
        cell: data => data.getValue(),
        size: 200,
      },
    ],
    [dateFormatting, t],
  )

  if (inventories.length === 0 && !isLoading)
    return <EmptyPlaceholder Icon={IoReceiptOutline} text={t('emptyPlaceholder.inventory')} />

  return (
    <Table
      columns={columns}
      data={inventories}
      isLoading={isLoading}
      onRowClick={inventory => onRowClick(inventory.id)}
    />
  )
}
