import {
  CheckboxResponseCodes,
  ServerSoftwarePos,
  useCashierSignIn,
  useCashierSignOut,
  useCheckboxCloseShift,
  useCheckboxOpenShift,
  useFetchShiftStatusBySoftwarePOSId,
} from '@expane/data'
import {
  ButtonProps,
  CloseButton,
  Hint,
  OpenButton,
  Spinner,
  Table,
  useShowConfirmationPopup,
} from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { ColumnDef } from '@tanstack/react-table'
import { useDateFormatting } from 'logic/hooks/useDateFormatting'
import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoEnterOutline, IoExitOutline, IoFileTrayStackedOutline } from 'react-icons/io5'
import { ButtonWithBillingCheck } from 'widgets/Buttons'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

export const SoftwarePOSsList: FC<{
  onRowClick: (softwarePOS: ServerSoftwarePos) => void
  softwarePOSs: ServerSoftwarePos[] | undefined
  isLoading: boolean
}> = memo(({ onRowClick, softwarePOSs, isLoading }) => {
  const { t } = useTranslation()

  const columns = useMemo<ColumnDef<ServerSoftwarePos>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('softwarePOS.name'),
        cell: data => data.getValue(),
        size: 200,
      },
      {
        accessorKey: 'login',
        header: t('softwarePOS.login'),
        cell: data => (
          <LoginCell
            login={data.getValue() as string}
            softwarePOSId={data.row.original.id}
            token={data.row.original.token ?? undefined}
          />
        ),
        size: 390,
      },
      {
        accessorKey: 'licenseKey',
        header: t('shift.title'),
        cell: data => <LicenseKeyCell softwarePOSId={data.row.original.id} />,
        size: 400,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, softwarePOSs],
  )

  if (softwarePOSs?.length === 0 && !isLoading)
    return (
      <EmptyPlaceholder Icon={IoFileTrayStackedOutline} text={t('emptyPlaceholder.softwarePOS')} />
    )

  return (
    <Table columns={columns} data={softwarePOSs} isLoading={isLoading} onRowClick={onRowClick} />
  )
})

const LoginCell: FC<{
  login: string
  softwarePOSId: number
  token: string | undefined
}> = ({ login, softwarePOSId, token }) => {
  const { t } = useTranslation()

  const { mutateAsync: mutateCashierSignIn, isLoading: isLoadingMutateCashierSignIn } =
    useCashierSignIn()
  const { mutateAsync: mutateCashierSignOut, isLoading: isLoadingMutateCashierSignOut } =
    useCashierSignOut()

  const [openSnackbar] = useSnackbar()

  const signIn = async () => {
    const result = await mutateCashierSignIn({ softwarePOSId })

    if (result.checkboxCashierSignin?.code === CheckboxResponseCodes.successful) return
    else if (result.checkboxCashierSignin?.code === CheckboxResponseCodes.checkboxCredentialsError)
      openSnackbar(t('softwarePOS.credentialsError'), 'error', 3000)
    else if (result.checkboxCashierSignin?.code === CheckboxResponseCodes.checkboxError)
      openSnackbar(t('softwarePOS.checkboxError'), 'error', 3000)
    else openSnackbar(t('submitError'), 'error', 3000)
  }

  const signOut = async () => {
    const result = await mutateCashierSignOut({ softwarePOSId })

    if (result.checkboxCashierSignout?.code === CheckboxResponseCodes.successful) return

    openSnackbar(t('submitError'), 'error', 3000)
  }

  const commonButtonProps: ButtonProps = { className: 'w-48', twoLines: true }

  return (
    <div className="flex items-center gap-2 p-1">
      <span className={token ? 'text-green-500' : 'text-red-500'}>{login}</span>

      <div className="ml-auto">
        {Boolean(token) ? (
          <ButtonWithBillingCheck
            type="danger"
            Icon={IoExitOutline}
            onClick={async event => {
              event.stopPropagation()
              await signOut()
            }}
            disabled={isLoadingMutateCashierSignOut}
            spinner={isLoadingMutateCashierSignOut}
            {...commonButtonProps}
          >
            {t('softwarePOS.signOutOfTheCashierAccount')}
          </ButtonWithBillingCheck>
        ) : (
          <ButtonWithBillingCheck
            type="outline"
            Icon={IoEnterOutline}
            onClick={async event => {
              event.stopPropagation()
              await signIn()
            }}
            disabled={isLoadingMutateCashierSignIn}
            spinner={isLoadingMutateCashierSignIn}
            {...commonButtonProps}
          >
            {t('softwarePOS.signInTheCashierAccount')}
          </ButtonWithBillingCheck>
        )}
      </div>
    </div>
  )
}

const LicenseKeyCell: FC<{ softwarePOSId: number }> = ({ softwarePOSId }) => {
  const { t } = useTranslation()
  const format = useDateFormatting()

  const { showConfirmation, confirmationModal } = useShowConfirmationPopup()

  const { data, isLoading, refetch, isRefetching } =
    useFetchShiftStatusBySoftwarePOSId(softwarePOSId)

  const { mutateAsync: mutateOpenShift, isLoading: isLoadingMutateOpenShift } =
    useCheckboxOpenShift()
  const { mutateAsync: mutateCloseShift, isLoading: isLoadingMutateCloseShift } =
    useCheckboxCloseShift()

  const [openSnackbar] = useSnackbar()

  const openShift = async () => {
    const result = await mutateOpenShift({ softwarePOSId })

    if (result.checkboxOpenShift?.code === CheckboxResponseCodes.successful) {
      refetch()

      return
    } else if (result.checkboxOpenShift?.code === CheckboxResponseCodes.checkboxCredentialsError)
      openSnackbar(t('shift.credentialError'), 'error', 3000)
    else if (result.checkboxOpenShift?.code === CheckboxResponseCodes.checkboxError)
      openSnackbar(t('softwarePOS.checkboxError'), 'error', 3000)
    else openSnackbar(t('submitError'), 'error', 3000)
  }

  const closeShift = async () => {
    const result = await mutateCloseShift({ softwarePOSId })

    if (result.checkboxCloseShift?.code === CheckboxResponseCodes.successful) {
      refetch()

      return
    }

    openSnackbar(t('submitError'), 'error', 3000)
  }

  if (isLoading || isRefetching) return <Spinner expandCentered />

  const buttonStyle = 'ml-auto'

  return (
    <div className="flex items-center gap-2 p-1">
      {data?.code !== CheckboxResponseCodes.successful && t('shift.notOpen')}

      {data?.code === CheckboxResponseCodes.thereAreNoOpenShifts && (
        <OpenButton
          className={buttonStyle}
          onClick={async e => {
            e.stopPropagation()
            await openShift()
          }}
          disabled={isLoadingMutateOpenShift}
          spinner={isLoadingMutateOpenShift}
        />
      )}

      {data?.code !== CheckboxResponseCodes.thereAreNoOpenShifts &&
        data?.code !== CheckboxResponseCodes.successful && <Hint>{t('shift.notOpenHint')}</Hint>}

      {data?.openedAt && (
        <>
          {t('shift.openAt')} {format('dateTime', new Date(data.openedAt))}
          <CloseButton
            className={buttonStyle}
            onClick={async e => {
              e.stopPropagation()

              showConfirmation({
                title: t('shift.confirmationTitle'),
                description: t('shift.confirmationDescription'),
                onConfirm: closeShift,
              })
            }}
            disabled={isLoadingMutateCloseShift}
            spinner={isLoadingMutateCloseShift}
          />
        </>
      )}

      {confirmationModal}
    </div>
  )
}
