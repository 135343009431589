import { CloseButton, Dialog, Modal, Spinner, usePopupOpenState } from '@expane/ui'
import { useSnackbar } from '@expane/widgets'
import { useModuleLazy } from 'logic/hooks/useModuleLazy'
import { usePrintWrapper } from 'logic/hooks/usePrintWrapper'
import { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { IoAlertCircleOutline, IoPrintOutline } from 'react-icons/io5'
import { EmptyPlaceholder } from 'widgets/EmptyPlaceholder'

interface QrCodeDialogProps {
  text: string
  title: string
  close: () => void
}

const QrCodeDialog: FC<QrCodeDialogProps> = ({ text, title, close }) => {
  const { t } = useTranslation()

  const [QRCode, moduleError] = useModuleLazy(async () => await import('qrcode'))

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const { PrintWrapper, onPrint } = usePrintWrapper(title)

  const [openSnackbar] = useSnackbar()

  useEffect(() => {
    if (canvasRef.current && QRCode) {
      QRCode.toCanvas(canvasRef.current, text, {
        width: 256,
      }).catch(() => {
        openSnackbar(t('dialogFailed'), 'error')
        close()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef.current, QRCode, text, openSnackbar, t, close])

  return (
    <Modal close={close}>
      <Dialog>
        <Dialog.Title>
          <div className="flex justify-between items-center">
            {title}
            <div
              className="text-primary-400 hover:text-primary-600 cursor-pointer"
              onClick={onPrint}
            >
              <IoPrintOutline size="1.2rem" />
            </div>
          </div>
        </Dialog.Title>
        <Dialog.Body>
          {QRCode ? (
            <PrintWrapper>
              <div className="flex justify-center">
                <div className="w-64 rounded-md overflow-hidden">
                  <canvas ref={canvasRef} />
                </div>
              </div>
            </PrintWrapper>
          ) : (
            <div className="h-64">
              {moduleError ? (
                <EmptyPlaceholder text={t('dialogFailed')} Icon={IoAlertCircleOutline} />
              ) : (
                <Spinner expandCentered />
              )}
            </div>
          )}
        </Dialog.Body>
        <Dialog.Footer>
          <CloseButton onClick={close} />
        </Dialog.Footer>
      </Dialog>
    </Modal>
  )
}

interface OpenQrCodeDialogProps {
  text: string
  title: string
}

export const useOpenQrCodeDialog = () => {
  const { isOpen, openPopup, closePopup } = usePopupOpenState()

  const props = useRef<OpenQrCodeDialogProps | null>(null)

  const openDialog = (dto: OpenQrCodeDialogProps) => {
    props.current = dto

    openPopup()
  }

  const dialog =
    isOpen && props.current ? <QrCodeDialog close={closePopup} {...props.current} /> : null

  return { openDialog, dialog }
}
