import { FC } from 'react'
import { BlockWrapper } from './BlockWrapper'
import { LandingSettingsBlockProps } from '../logic'
import { Controller } from 'react-hook-form'
import { Input } from '@expane/ui'
import { IoLogoFacebook, IoLogoInstagram } from 'react-icons/io5'
import { TelegramIcon, ViberIcon } from 'ui/Icons'
import { useTranslation } from 'react-i18next'

export const SocialMediaLinks: FC<LandingSettingsBlockProps> = ({ control, disabled }) => {
  const { t } = useTranslation()

  return (
    <BlockWrapper label={t('businessLandingSettings.socialMedia')}>
      <div className="flex gap-4 mb-4">
        <Controller
          name="telegram"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              containerClassName="grow"
              Icon={TelegramIcon}
              label="Telegram"
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />

        <Controller
          name="viber"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              containerClassName="grow"
              Icon={ViberIcon}
              label="Viber"
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />
      </div>

      <div className="flex gap-4">
        <Controller
          name="instagram"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              containerClassName="grow"
              Icon={IoLogoInstagram}
              label="Instagram"
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />

        <Controller
          name="facebook"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              containerClassName="grow"
              Icon={IoLogoFacebook}
              label="Facebook"
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          )}
        />
      </div>
    </BlockWrapper>
  )
}
