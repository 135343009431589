import { gql } from 'graphql-request'
import { CheckPromoCodeResult } from './logic'
import { queryClient, reportGqlError, request, useMutation } from '../../api'
import { ServerPromoCodeInsertInput, ServerPromoCodeSetInput } from '../../generated/graphql-types'
import { PROMO_CODE_QUERY_KEY } from './queryKeys'

export const useCreatePromoCode = () => {
  return useMutation(
    (
      promoCodeInsertInput: ServerPromoCodeInsertInput,
    ): Promise<{ insertOnePromoCode?: { id?: string } }> => {
      return request(
        gql`
          mutation ($promoCodeInsertInput: promoCode_insert_input!) {
            insertOnePromoCode(object: $promoCodeInsertInput) {
              id
            }
          }
        `,
        { promoCodeInsertInput },
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => {
        queryClient.invalidateQueries([PROMO_CODE_QUERY_KEY])
      },
    },
  )
}

export const useCheckPromoCode = () => {
  return useMutation(
    (promoCode: string): Promise<{ checkPromoCode?: CheckPromoCodeResult }> => {
      return request(
        gql`
          mutation ($promoCode: String!) {
            checkPromoCode(promoCode: $promoCode) {
              message
              code
              promoCode {
                id
                term
                termType
              }
            }
          }
        `,
        { promoCode },
      )
    },
    {
      onError: reportGqlError,
    },
  )
}

export const useUpdatePromoCode = () => {
  return useMutation(
    (dto: {
      id: string
      promoCodeSetInput: ServerPromoCodeSetInput
    }): Promise<{ updatePromoCodeById?: { id?: string } }> => {
      return request(
        gql`
          mutation ($id: String!, $promoCodeSetInput: promoCode_set_input!) {
            updatePromoCodeById(pk_columns: { id: $id }, _set: $promoCodeSetInput) {
              id
            }
          }
        `,
        dto,
      )
    },
    {
      onError: reportGqlError,
      onSuccess: () => {
        queryClient.invalidateQueries([PROMO_CODE_QUERY_KEY])
      },
    },
  )
}
